import React from 'react'
import style from './Pagination.module.scss'
import { Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const Pagination = props => {

    const { t, i18n } = useTranslation('common')
    const pageNumbers = []

    for (let i = 1; i <= Math.ceil(props.totalServices / props.servicesPerPage); i++) {
        pageNumbers.push(i)
    }

    return (
        <Col sx={6} className={[
            style.arrows,
            i18n.language === 'en' ? style.arrowsEn : style.arrowsAr
        ].join(' ')}>
            <span
                className={props.currentPage <= 1 ? style.disabled : style.active}
                onClick={props.prevPage}
            >
                <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 1.88407C0 0.759554 1.27298 0.105692 2.18744 0.761133L10.4555 6.68932C10.6388 6.82079 10.7871 6.99512 10.8875 7.19711C10.9879 7.3991 11.0374 7.62259 11.0315 7.84809C11.0257 8.07358 10.9647 8.29421 10.854 8.49073C10.7433 8.68725 10.5861 8.85367 10.3962 8.97547L2.12821 14.279C1.20822 14.8689 0 14.2087 0 13.1158V1.88407Z" fill="#000F39" />
                </svg>
            </span>

            <span
                className={props.currentPage >= props.totalServices / props.servicesPerPage ? style.disabled : style.active}
                onClick={props.nextPage}
            >
                <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 1.88407C0 0.759554 1.27298 0.105692 2.18744 0.761133L10.4555 6.68932C10.6388 6.82079 10.7871 6.99512 10.8875 7.19711C10.9879 7.3991 11.0374 7.62259 11.0315 7.84809C11.0257 8.07358 10.9647 8.29421 10.854 8.49073C10.7433 8.68725 10.5861 8.85367 10.3962 8.97547L2.12821 14.279C1.20822 14.8689 0 14.2087 0 13.1158V1.88407Z" fill="#000F39" />
                </svg>
            </span>
        </Col>
    )
}

export default Pagination
