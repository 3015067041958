const NewRequest = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M10.7703 4.18872C13.9004 3.71515 16.487 3.46935 19.6035 3.46935C22.7223 3.46935 25.3089 3.71741 28.439 4.18872C28.9354 4.26452 29.3913 4.50659 29.7322 4.8753C30.073 5.24401 30.2786 5.71753 30.3153 6.21831C30.7821 12.5619 30.7866 17.1736 30.322 23.4947C30.2843 24.0197 30.0605 24.5141 29.691 24.889C29.3215 25.2639 28.8304 25.4947 28.306 25.54C27.1423 25.6393 25.9967 25.7182 24.8647 25.7768C25.1353 20.7976 25.0744 16.6662 24.6775 11.2945C24.6413 10.7934 24.436 10.3193 24.0951 9.95015C23.7542 9.58099 23.298 9.33859 22.8013 9.2627C19.6712 8.78913 17.0846 8.54332 13.9658 8.54332C12.1053 8.54332 10.4343 8.63127 8.72266 8.80266C8.77001 7.96827 8.82865 7.11133 8.89404 6.22057C8.9302 5.71938 9.13556 5.24534 9.47645 4.87618C9.81735 4.50701 10.2736 4.26461 10.7703 4.18872Z" fill="#8AD3FF" fillOpacity="0.08" />
            <path d="M13.9665 8.54335C10.8499 8.54335 8.26105 8.79141 5.13322 9.26273C4.63685 9.33852 4.18091 9.58059 3.84005 9.9493C3.4992 10.318 3.29362 10.7915 3.25698 11.2923C2.78792 17.6382 2.78341 22.2499 3.24796 28.5687C3.28533 29.0942 3.50924 29.5891 3.87931 29.9642C4.24938 30.3392 4.74126 30.5697 5.26627 30.614C8.25203 30.8644 11.1092 30.9906 13.9665 30.9906C16.8259 30.9906 19.6831 30.8644 22.6689 30.614C23.1933 30.5687 23.6844 30.3379 24.0539 29.963C24.4235 29.5881 24.6472 29.0937 24.685 28.5687C25.1495 22.2476 25.145 17.6359 24.6782 11.2946C24.642 10.7934 24.4367 10.3193 24.0958 9.95018C23.7549 9.58101 23.2987 9.33861 22.8019 9.26273C19.6719 8.78916 17.0853 8.54335 13.9665 8.54335Z" stroke="#8AD3FF" strokeOpacity="0.41" strokeWidth="2.42857" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.66797 16.4362H18.2667" stroke="#15479E" strokeOpacity="0.41" strokeWidth="2.42857" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.66797 22.6738H14.8547" stroke="white" strokeWidth="2.42857" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.72266 8.82296C8.77227 7.9818 8.82865 7.1181 8.8963 6.21831C8.93254 5.71739 9.13799 5.24366 9.47891 4.87487C9.81984 4.50608 10.276 4.26413 10.7725 4.18872C13.9026 3.71515 16.4892 3.46935 19.6058 3.46935C22.7246 3.46935 25.3112 3.71741 28.4413 4.18872C28.9376 4.26452 29.3936 4.50659 29.7344 4.8753C30.0753 5.24401 30.2809 5.71753 30.3175 6.21831C30.7843 12.5619 30.7888 17.1736 30.3243 23.4947C30.287 24.0198 30.0634 24.5145 29.6938 24.8895C29.3242 25.2644 28.8328 25.4951 28.3082 25.54C27.1423 25.6393 25.9945 25.7182 24.8579 25.7768" stroke="#15479E" strokeWidth="2.42857" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}


export default NewRequest