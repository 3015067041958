const Draft = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
			<path d="M30.8317 27.7517C31.1179 23.1676 31.2016 18.573 31.0824 13.9815C31.0612 13.0503 30.7554 12.1478 30.2061 11.3956C28.1048 8.53504 26.4334 6.75617 23.6755 4.62868C22.9157 4.04641 21.9894 3.7226 21.0323 3.70461C20.0216 3.6832 19.0108 3.67285 17.9999 3.67357C14.7597 3.67357 12.1451 3.76908 9.44214 3.95772C8.33038 4.03255 7.28377 4.50911 6.4974 5.29856C5.71102 6.088 5.23855 7.13647 5.16806 8.24851C4.96609 11.4951 4.86575 14.7472 4.8672 18.0001C4.8672 21.343 4.96988 24.6046 5.16806 27.7517C5.31133 30.0535 7.14035 31.8849 9.44214 32.0425C12.1451 32.2311 14.7597 32.3266 17.9999 32.3266C21.24 32.3266 23.8546 32.2311 26.5576 32.0425C27.6693 31.9677 28.7159 31.4911 29.5023 30.7017C30.2887 29.9122 30.7612 28.8637 30.8317 27.7517Z" fill="#8AD3FF" fillOpacity="0.08" />
			<path d="M30.8317 27.7517C31.1179 23.1676 31.2016 18.573 31.0824 13.9815C31.0612 13.0503 30.7554 12.1478 30.2061 11.3956C28.1048 8.53504 26.4334 6.75617 23.6755 4.62868C22.9157 4.04641 21.9894 3.7226 21.0323 3.70461C20.0216 3.6832 19.0108 3.67285 17.9999 3.67357C14.7597 3.67357 12.1451 3.76908 9.44214 3.95772C8.33038 4.03255 7.28377 4.50911 6.4974 5.29856C5.71102 6.088 5.23855 7.13647 5.16806 8.24851C4.96609 11.4951 4.86575 14.7472 4.8672 18.0001C4.8672 21.343 4.96988 24.6046 5.16806 27.7517C5.31133 30.0535 7.14035 31.8849 9.44214 32.0425C12.1451 32.2311 14.7597 32.3266 17.9999 32.3266C21.24 32.3266 23.8546 32.2311 26.5576 32.0425C27.6693 31.9677 28.7159 31.4911 29.5023 30.7017C30.2887 29.9122 30.7612 28.8637 30.8317 27.7517Z" stroke="#15479E" strokeOpacity="0.41" strokeWidth="2.57143" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M13.4492 18.0383H22.5513" stroke="#15479E" strokeOpacity="0.41" strokeWidth="2.57143" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M13.4492 24.9772H22.5227M13.4492 10.8369H18.9411" stroke="#15479E" strokeOpacity="0.41" strokeWidth="2.57143" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	)
}
export default Draft;