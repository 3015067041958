import React, { useState, useEffect } from 'react';
import style from './Notifications.module.scss';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Accordion, Card } from 'react-bootstrap';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Pagination from './Pagination';
import PopUp from './../../../PopUp/PopUp';

import { post } from '../../../../../api/base';
import { Close } from '@material-ui/icons';
// export const Close: SvgIconComponent;
// export const ClosedCaption: SvgIconComponent;
// export const ClosedCaptionOutlined: SvgIconComponent;
// export const ClosedCaptionRounded: SvgIconComponent;
// export const ClosedCaptionSharp: SvgIconComponent;
// export const ClosedCaptionTwoTone: SvgIconComponent;
// export const CloseOutlined: SvgIconComponent;
// export const CloseRounded: SvgIconComponent;
// export const CloseSharp: SvgIconComponent;
// export const CloseTwoTone: SvgIconComponent;
const Notifications = (props) => {
	const { t, i18n } = useTranslation('common');
	const [open, setOpen] = useState(null);
	const { user } = useSelector((state) => state.user);
	const [notifications, setNotifications] = useState();
	const [notificationBody, setNotificationBody] = useState();
	const [currentPage, setCurrentPage] = useState(1);
	const [notificationsPerPage, setNotificationsPerPage] = useState(10);
	const [show, setShow] = useState(false);
	const [deleteStae, setDeleteState] = useState({
		byId: null,
		readed: false,
		all: false,
	});

	useEffect(() => GetNotifications(), []);

	const GetNotifications = async () => {
		const res = await post(
			`${process.env.REACT_APP_API_URL}/SYSTEM/USERS/NOTIFICATIONS/GET_WEB_NOTIFICATIONS`,
			{
				Page_Index: 1,
				Page_Count: 1000,
			},
			{
				headers: {
					Authorization: `Bearer ${user?.access_token}`,
				},
			}
		);

		setNotifications(res.Data);
	};

	const GetNotificationsDetails = async (id) => {
		const res = await axios.post(
			`${process.env.REACT_APP_API_URL}/SYSTEM/USERS/NOTIFICATIONS/GET_WEB_NOTIFICATION_DETAILS`,
			{
				Notification_Web_Id: id,
			},
			{
				headers: {
					Authorization: `Bearer ${user?.access_token}`,
				},
			}
		);
		setOpen(open == id ? null : id);
		setNotificationBody(res.data.Data);
		GetNotifications();
		props.notificationsCheck();
	};

	const deleteNotification = async (id) => {
		const res = await axios.post(
			`${process.env.REACT_APP_API_URL}/SYSTEM/USERS/NOTIFICATIONS/DEL_WEB_NOTIFICATION`,
			{
				Notification_Web_Id: id,
			},
			{
				headers: {
					Authorization: `Bearer ${user?.access_token}`,
				},
			}
		);
		GetNotifications();
		props.notificationsCheck();
	};

	const deleteReaded = async () => {
		const res = await axios.post(
			`${process.env.REACT_APP_API_URL}/SYSTEM/USERS/NOTIFICATIONS/DEL_READED_WEB_NOTIFICATION`,
			{},
			{
				headers: {
					Authorization: `Bearer ${user?.access_token}`,
				},
			}
		);
		GetNotifications();
		props.notificationsCheck();
	};

	const deleteAll = async () => {
		const res = await axios.post(
			`${process.env.REACT_APP_API_URL}/SYSTEM/USERS/NOTIFICATIONS/DEL_ALL_WEB_NOTIFICATION`,
			{},
			{
				headers: {
					Authorization: `Bearer ${user?.access_token}`,
				},
			}
		);
		GetNotifications();
		props.notificationsCheck();
	};

	const confirmDelete = () => {
		if (deleteStae.byId !== null) {
			deleteNotification(deleteStae.byId);
		} else if (deleteStae.readed) {
			deleteReaded();
		} else if (deleteStae.all) {
			deleteAll();
		}
		setShow(false);
	};

	const onPrevPage = () => {
		if (currentPage > 1) {
			setTimeout(() => setCurrentPage(currentPage - 1), 1000);
		}
	};

	const onNextPage = () => {
		if (currentPage < notifications.length / notificationsPerPage) {
			setTimeout(() => setCurrentPage(currentPage + 1), 1000);
		}
	};

	const handleClose = () => setShow(false);

	const indexOfLastPost = currentPage * notificationsPerPage;
	const indexOfFirstPost = indexOfLastPost - notificationsPerPage;
	const currentNotifications = notifications?.slice(
		indexOfFirstPost,
		indexOfLastPost
	);
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	return (
		<Container className="my-4">
			<Row>
				<Col>
					<div className="d-flex justify-content-between align-items-center">
						<p className={style.title}>{t('notifications')}</p>
						<button onClick={() => {
							props.close()
							console.log('close')
						}} className="btn btn-outline-secondary btn-sm d-block d-md-none">
							<Close style={{ width: '20px' }} />
						</button>
					</div>
				</Col>
			</Row>

			<Row>
				{currentNotifications &&
					currentNotifications.map((n, i) => (
						<Col key={i}>
							<div className="d-flex align-items-baseline w-100">
								<Col sm={1}>
									<div className={style.iconCont}>
										<span className={style.notifIcon}>
											<svg
												width="20"
												height="21"
												viewBox="0 0 20 21"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M9.99875 3.225C9.72249 3.225 9.45754 3.33475 9.26219 3.5301C9.06942 3.72286 8.96001 3.9834 8.95715 4.2557C8.95922 4.37817 8.92506 4.49845 8.86016 4.60112C8.81321 4.67542 8.75016 4.7405 8.67311 4.79052C8.64592 4.80825 8.61727 4.82393 8.58738 4.83732C7.73803 5.24156 7.01394 5.86831 6.49208 6.6511C5.97042 7.43359 5.67041 8.34264 5.62376 9.28174V11.7667C5.62376 11.7918 5.62224 11.8168 5.61923 11.8418C5.54475 12.457 5.32687 13.0462 4.98311 13.5618C4.9652 13.5887 4.94697 13.6153 4.92844 13.6417H15.0691C15.0505 13.6153 15.0323 13.5887 15.0144 13.5618C14.6706 13.0462 14.4528 12.457 14.3783 11.8418C14.3753 11.8168 14.3738 11.7918 14.3738 11.7667V9.28174C14.3271 8.34264 14.0271 7.43359 13.5054 6.6511C12.9811 5.86466 12.2527 5.23572 11.3983 4.83168C11.1798 4.72837 11.0404 4.50835 11.0404 4.26666C11.0404 3.9904 10.9307 3.72545 10.7353 3.5301C10.54 3.33475 10.275 3.225 9.99875 3.225ZM7.7425 3.8653C7.8241 3.40659 8.04434 2.98017 8.3783 2.64621C8.80807 2.21644 9.39097 1.975 9.99875 1.975C10.6065 1.975 11.1894 2.21644 11.6192 2.64621C11.9532 2.98017 12.1734 3.40659 12.255 3.8653C13.1756 4.36544 13.9624 5.08302 14.5455 5.95773C15.1956 6.93291 15.5681 8.06663 15.6231 9.23736C15.6235 9.24712 15.6238 9.25689 15.6238 9.26666V11.727C15.6786 12.1351 15.8259 12.5255 16.0545 12.8684C16.2897 13.2212 16.6044 13.5139 16.9734 13.7228C17.2204 13.8627 17.3421 14.1514 17.2698 14.4259C17.1975 14.7004 16.9493 14.8917 16.6654 14.8917H3.33209C3.04824 14.8917 2.80004 14.7004 2.72772 14.4259C2.65539 14.1514 2.77712 13.8627 3.02412 13.7228C3.39309 13.5139 3.70784 13.2212 3.94305 12.8684C4.17164 12.5255 4.31888 12.1351 4.37376 11.727V9.26666C4.37376 9.25689 4.37398 9.24712 4.37444 9.23736C4.42939 8.06663 4.80189 6.93291 5.45202 5.95773C6.03515 5.08302 6.82194 4.36544 7.7425 3.8653Z"
													fill="#E03137"
												/>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M7.49875 13.6417C7.84393 13.6417 8.12375 13.9215 8.12375 14.2667V15.1C8.12375 15.5973 8.3213 16.0742 8.67293 16.4258C9.02456 16.7775 9.50147 16.975 9.99875 16.975C10.496 16.975 10.9729 16.7775 11.3246 16.4258C11.6762 16.0742 11.8738 15.5973 11.8738 15.1V14.2667C11.8738 13.9215 12.1536 13.6417 12.4988 13.6417C12.8439 13.6417 13.1238 13.9215 13.1238 14.2667V15.1C13.1238 15.9288 12.7945 16.7237 12.2085 17.3097C11.6224 17.8958 10.8276 18.225 9.99875 18.225C9.16995 18.225 8.3751 17.8958 7.78905 17.3097C7.20299 16.7237 6.87375 15.9288 6.87375 15.1V14.2667C6.87375 13.9215 7.15358 13.6417 7.49875 13.6417Z"
													fill="#E03137"
												/>
											</svg>
										</span>
									</div>
								</Col>
								<div

									className="mb-3 col-12"
									key={n.Notification_Web_Id}
								>
									<Accordion activeKey={open}>
										<Card
											className={[style.card, 'mx-3'].join(
												' '
											)}
										>
											<Card.Header
												className={style.accHeader}
											>
												<Accordion.Toggle
													eventKey={n.Notification_Web_Id}
													className={[
														style.accBtn,
														n.Is_Read == 0
															? style.accBtnNew
															: style.accBtnReaded,
													].join(' ')}
													onClick={() =>
														GetNotificationsDetails(
															n.Notification_Web_Id
														)
													}
												>
													<span className={style.notif}>
														{i18n.language == 'en'
															? n.Notification_Title_EN
															: n.Notification_Title_AR}
														<p className={style.time}>
															{n.Notification_Time}
														</p>
													</span>

													<span
														className={[
															style.accArrow,
															open ==
																n.Notification_Web_Id
																? style.upArrow
																: null,
														].join(' ')}
													>
														<svg
															version="1.1"
															id="Capa_1"
															xmlns="http://www.w3.org/2000/svg"
															xmlnsXlink="http://www.w3.org/1999/xlink"
															x="0px"
															y="0px"
															width="451.847px"
															height="451.847px"
															viewBox="0 0 451.847 451.847"
															xmlSpace="preserve"
														>
															<g>
																<path
																	d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751
																c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0
																c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"
																/>
															</g>
														</svg>
													</span>
												</Accordion.Toggle>
											</Card.Header>

											<Accordion.Collapse
												eventKey={n.Notification_Web_Id}
											>
												<Card.Body
													className={style.accBody}
												>
													<Row>
														<Col>
															{i18n.language == 'en'
																? notificationBody?.Notification_Body_EN
																: notificationBody?.Notification_Body_AR}
														</Col>
													</Row>

													{/* <Row>
														<Col className="d-flex justify-content-end">
															<span
																className={
																	style.deleteNotifBtn
																}
																onClick={() => {
																	setShow(true);
																	setDeleteState({
																		byId: n.Notification_Web_Id,
																		readed: false,
																		all: false,
																	});
																}}
															>
																<svg
																	version="1.0"
																	xmlns="http://www.w3.org/2000/svg"
																	width="512.000000pt"
																	height="512.000000pt"
																	viewBox="0 0 512.000000 512.000000"
																	preserveAspectRatio="xMidYMid meet"
																>
																	<g
																		transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
																		fill="#000000"
																		stroke="none"
																	>
																		<path
																			d="M805 4810 c-225 -26 -411 -187 -482 -416 -17 -56 -18 -140 -18 -1834
																	0 -1694 1 -1778 18 -1834 62 -199 204 -341 403 -403 56 -17 140 -18 1834 -18
																	1694 0 1778 1 1834 18 199 62 341 204 403 403 17 56 18 140 18 1834 0 1694 -1
																	1778 -18 1834 -61 197 -198 335 -397 402 -53 18 -129 19 -1795 20 -957 1
																	-1767 -2 -1800 -6z m1867 -680 c147 -28 233 -73 328 -169 110 -111 169 -249
																	170 -392 l0 -47 93 -16 c50 -9 104 -19 120 -22 34 -7 83 -47 102 -85 43 -81
																	-15 -191 -107 -205 -27 -4 -933 152 -1631 281 -164 30 -200 53 -214 138 -8 49
																	2 81 38 122 48 54 78 58 237 29 75 -13 137 -24 138 -24 1 0 21 39 44 88 73
																	150 209 263 373 307 74 20 190 18 309 -5z m705 -996 c15 -11 38 -36 51 -54
																	l22 -33 0 -821 c0 -769 -1 -826 -19 -888 -46 -169 -188 -309 -356 -353 -87
																	-22 -963 -22 -1050 0 -143 38 -258 134 -328 274 l-42 84 -3 855 c-2 845 -2
																	854 18 887 11 18 40 43 63 54 42 21 47 21 829 19 780 -3 787 -3 815 -24z"
																		/>
																		<path
																			d="M2385 3829 c-47 -23 -101 -73 -122 -112 -8 -16 -13 -30 -11 -31 12
																	-8 610 -108 615 -102 12 12 -16 96 -48 142 -16 24 -49 55 -73 69 -91 54 -285
																	72 -361 34z"
																		/>
																		<path
																			d="M1940 2158 c0 -477 4 -716 11 -743 15 -54 52 -101 99 -125 37 -19 60
																	-20 503 -20 l463 0 46 29 c30 19 52 44 67 73 21 44 21 49 21 766 l0 722 -605
																	0 -605 0 0 -702z m406 513 c24 -11 50 -33 62 -53 22 -35 22 -40 22 -554 0
																	-500 -1 -520 -20 -552 -58 -95 -192 -95 -250 0 -19 32 -20 52 -20 548 0 585
																	-3 563 80 607 52 28 72 28 126 4z m574 -11 c60 -43 60 -39 60 -603 l0 -514
																	-23 -34 c-59 -89 -185 -89 -244 0 l-23 34 0 522 0 522 23 34 c46 70 139 87
																	207 39z"
																		/>
																	</g>
																</svg>
															</span>
														</Col>
													</Row> */}
												</Card.Body>
											</Accordion.Collapse>
										</Card>
									</Accordion>
								</div>
							</div>
						</Col>
					))}
			</Row>

			<Row
				className={[
					style.paginationCont,
					'align-items-center my-4',
				].join(' ')}
			>
				<Pagination
					notificationsPerPage={notificationsPerPage}
					totalNotifications={notifications?.length}
					paginate={paginate}
					currentPage={currentPage}
					prevPage={onPrevPage}
					nextPage={onNextPage}
				/>
			</Row>

			<Row>
				<Col>
					<button
						className={[style.deleteAll, style.deleteBtns].join(
							' '
						)}
						onClick={() => {
							setShow(true);
							setDeleteState({
								byId: null,
								readed: false,
								all: true,
							});
						}}
					>
						{t('delete_all')}
					</button>
				</Col>

				<Col>
					<button
						className={[style.deleteReaded, style.deleteBtns].join(
							' '
						)}
						onClick={() => {
							setShow(true);
							setDeleteState({
								byId: null,
								readed: true,
								all: false,
							});
						}}
					>
						{t('delete_readed')}
					</button>
				</Col>
			</Row>

			<>
				{notifications ? (
					<PopUp
						show={show}
						handleClose={handleClose}
						title={t('confirm_delete')}
						smallModal={true}
					>
						<Container>
							<Row className="mt-4">
								<Col className="d-flex justify-content-end">
									<button
										type="button"
										className={[
											style.confirm,
											style.deleteBtns,
											'mx-2',
										].join(' ')}
										onClick={confirmDelete}
									>
										{t('delete')}
									</button>
									<button
										type="button"
										className={[
											style.cancel,
											style.deleteBtns,
										].join(' ')}
										onClick={handleClose}
									>
										{t('cancel')}
									</button>
								</Col>
							</Row>
						</Container>
					</PopUp>
				) : null}
			</>
		</Container>
	);
};

export default Notifications;
