import React, { useState, useEffect } from 'react';
import Header from './../Header/Header';
import Footer from './../Footer/Footer';
import InnerTitleCont from './../InnerTitleCont/InnerTitleCont';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Accordion } from 'react-bootstrap';
import axios from 'axios';
import style from './ToolCards.module.scss';
import pic1 from './../../../global/images/offering1.png';
import Pagination from './Pagination';
import { NavLink } from "react-router-dom";

const AllOffers = () => {
	const { t, i18n } = useTranslation('common');
	const [offersList, setOffersList] = useState([]);
	const [activeOffer, setActiveOffer] = useState(null);
	const [offerDetails, setOfferDetails] = useState({});
	const { user } = useSelector((state) => state.user);
	const [currentPage, setCurrentPage] = useState(1);
	const offersPerPage = 6;

	useEffect(() => {
		getLicensesOffers();
	}, []);

	const getLicensesOffers = async () => {
		const res = await axios.post(
			`${process.env.REACT_APP_API_URL}PORTAL/LICENSES/OFFERS/Get_Portal_Licenses_Offers_List`
		);
		setOffersList(res.data.Data);
		setCurrentPage(1);
	};

	useEffect(() => {
		if (offersList?.length > 0 && activeOffer !== null) {
			getLicenseOfferDetail();
		}
	}, [activeOffer, offersList]);

	const getLicenseOfferDetail = async () => {
		console.log(activeOffer);
		const res = await axios.post(
			`${process.env.REACT_APP_API_URL}PORTAL/LICENSES/OFFERS/Get_Portal_License_Offer_Info`,
			{
				License_Offer_Id: activeOffer,
			}
		);
		setOfferDetails(res?.data?.Data);
	};

	const indexOfLastPost = currentPage * offersPerPage;
	const indexOfFirstPost = indexOfLastPost - offersPerPage;
	const currentOffers = offersList.slice(indexOfFirstPost, indexOfLastPost);
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	return (
		<>
			<Header />
			<InnerTitleCont
				breadCrumbs={
					<>
						<NavLink to="/homePage">
							<span>
								<svg
									width="12"
									height="12"
									viewBox="0 0 12 12"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M10.6893 2.97833L7.32932 0.6275C6.41349 -0.0141667 5.00766 0.0208334 4.12682 0.703333L1.20432 2.98417C0.62099 3.43917 0.160156 4.3725 0.160156 5.1075V9.1325C0.160156 10.62 1.36766 11.8333 2.85516 11.8333H9.14349C10.631 11.8333 11.8385 10.6258 11.8385 9.13833V5.18333C11.8385 4.39583 11.331 3.4275 10.6893 2.97833ZM6.43682 9.5C6.43682 9.73917 6.23849 9.9375 5.99932 9.9375C5.76016 9.9375 5.56182 9.73917 5.56182 9.5V7.75C5.56182 7.51083 5.76016 7.3125 5.99932 7.3125C6.23849 7.3125 6.43682 7.51083 6.43682 7.75V9.5Z"
										fill="white"
									/>
								</svg>
							</span>
						</NavLink>

						<span>
							<svg
								style={{
									margin: '0 8px',
									transform:
										i18n.language == 'ar' && 'scale(-1)',
								}}
								width="6"
								height="10"
								viewBox="0 0 6 10"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M1 1L5 5L1 9"
									stroke="white"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</span>

						<span>{t('offers')}</span>
					</>
				}
				title={t('offers')}
				startBtn={false}
				leaveBtn={false}
			/>
			<Container fluid="lg">
				<Row className="my-5">
					{currentOffers?.map((offer, index) => (
						<Col xs={12} key={offer?.License_Offer_Id}>
							<Accordion
								className={style.Accordion}
								activeKey={activeOffer}
								onSelect={() =>
									setActiveOffer(
										activeOffer ===
											offer?.License_Offer_Id.toString()
											? null
											: offer?.License_Offer_Id.toString()
									)
								}
							>
								<Accordion.Toggle
									key={offer?.License_Offer_Id.toString()}
									className={style.title}
									eventKey={offer?.License_Offer_Id.toString()}
								>
									<>
										<div>
											{offer?.License_Offer_Icon_URL ? (
												<img
													// className={style.img}
													width="40px"
													alt={
														offer?.License_Offer_Name_EN
													}
													src={
														offer?.License_Offer_Icon_URL
													}
												/>
											) : (
												<svg
													width="40"
													height="40"
													viewBox="0 0 73 73"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<circle
														cx="36.5"
														cy="36.5"
														r="36.5"
														fill="#A7E0F7"
														fillOpacity="0.47"
													/>
													<g clipPath="url(#clip0_519_679)">
														<path
															d="M41.6674 26.0833H36.0008M41.6674 31.75H36.0008M41.6674 34.5833V20.4166H30.3341V34.5833L22.4503 41.3408C21.5473 42.1151 20.9032 43.1474 20.6045 44.2989C20.3058 45.4503 20.367 46.6656 20.7797 47.7812C21.1925 48.8968 21.9371 49.8592 22.9133 50.539C23.8894 51.2187 25.0504 51.5832 26.2399 51.5833H45.7616C46.9511 51.5832 48.1121 51.2187 49.0883 50.539C50.0644 49.8592 50.809 48.8968 51.2218 47.7812C51.6345 46.6656 51.6957 45.4503 51.397 44.2989C51.0983 43.1474 50.4542 42.1151 49.5512 41.3408L41.6674 34.5833Z"
															stroke="black"
															strokeWidth="2"
															strokeLinejoin="round"
														/>
														<path
															d="M20.4316 45.9166C20.4686 47.4324 21.0969 48.8736 22.1821 49.9325C23.2674 50.9913 24.7237 51.5837 26.24 51.5833H45.7602C47.2765 51.5837 48.7328 50.9913 49.818 49.9325C50.9033 48.8736 51.5316 47.4324 51.5686 45.9166H20.4316Z"
															fill="black"
														/>
													</g>
													<defs>
														<clipPath id="clip0_519_679">
															<rect
																width="34"
																height="34"
																fill="white"
																transform="translate(19 19)"
															/>
														</clipPath>
													</defs>
												</svg>
											)}

											<span className={style.offerName}>
												{i18n.language == 'ar'
													? offer?.License_Offer_Name_AR
													: offer?.License_Offer_Name_EN}
											</span>
										</div>

										<div className={style.collapseIcon}>
											{activeOffer ===
												offer?.License_Offer_Id?.toString() ? (
												<svg
													version="1.0"
													xmlns="http://www.w3.org/2000/svg"
													width="512.000000pt"
													height="512.000000pt"
													viewBox="0 0 512.000000 512.000000"
													preserveAspectRatio="xMidYMid meet"
												>
													<g
														transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
														fill="#000000"
														stroke="none"
													>
														<path
															d="M240 2856 c-95 -20 -172 -83 -212 -174 -79 -178 33 -387 225 -421 89
                                                            -15 4528 -16 4613 0 103 19 184 82 226 177 79 178 -33 387 -225 421 -94 16
                                                            -4551 14 -4627 -3z"
														/>
													</g>
												</svg>
											) : (
												<svg
													version="1.0"
													xmlns="http://www.w3.org/2000/svg"
													width="512.000000pt"
													height="512.000000pt"
													viewBox="0 0 512.000000 512.000000"
													preserveAspectRatio="xMidYMid meet"
												>
													<g
														transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
														fill="#000000"
														stroke="none"
													>
														<path
															d="M2471 4383 c-24 -9 -63 -35 -86 -58 -77 -77 -75 -50 -75 -827 l0
                                                            -688 -688 0 c-777 0 -750 2 -827 -75 -98 -98 -98 -252 0 -350 77 -77 50 -75
                                                            827 -75 l688 0 0 -688 c0 -777 -2 -750 75 -827 98 -98 252 -98 350 0 77 77 75
                                                            50 75 827 l0 688 688 0 c777 0 750 -2 827 75 98 98 98 252 0 350 -77 77 -50
                                                            75 -827 75 l-688 0 0 688 c0 777 2 750 -75 827 -69 70 -169 91 -264 58z"
														/>
													</g>
												</svg>
											)}
										</div>
									</>
								</Accordion.Toggle>

								<Accordion.Collapse
									className={style.body}
									eventKey={offer?.License_Offer_Id.toString()}
								>
									<Row>
										<Col md={7}>
											{offer?.License_Offer_Description_AR && (
												<div
													className={style.desc}
													dangerouslySetInnerHTML={{
														__html:
															i18n.language ==
																'ar'
																? offer?.License_Offer_Description_AR
																: offer?.License_Offer_Description_EN,
													}}
												/>
											)}
											{offer?.License_Offer_Location_Description_AR && (
												<div className={style.location}>
													{t('location')}:
													<div
														dangerouslySetInnerHTML={{
															__html:
																i18n.language ==
																	'ar'
																	? offer?.License_Offer_Location_Description_AR
																	: offer?.License_Offer_Location_Description_EN,
														}}
													/>
												</div>
											)}
											<div className={style.benefits}>
												{t('Investors')}:
												<span>
													{offer?.License_Offer_Investors_Count}
												</span>
											</div>
											<div className={style.benefits}>
												{t('Activites')}:
												<span>
													{offer?.License_Offer_Activities_Count}
												</span>
											</div>
											<div className={style.benefits}>
												{t('Vises')}:
												<span>
													{offer?.License_Offer_Visas_Count}
												</span>
											</div>
											{offer
												?.License_Offer_Benefits_Description_AR_List
												?.length > 0 && (
													<div className={style.benefits}>
														{i18n.language == 'ar'
															? offer?.License_Offer_Benefits_Description_AR_List.map(
																(b) => (
																	<div
																		key={b}
																	>
																		<span>
																			<svg
																				width="15"
																				height="16"
																				viewBox="0 0 15 16"
																				fill="none"
																				xmlns="http://www.w3.org/2000/svg"
																			>
																				<path
																					d="M7.5 0.5C6.01664 0.5 4.56659 0.939867 3.33323 1.76398C2.09986 2.58809 1.13856 3.75943 0.570907 5.12987C0.00324963 6.50032 -0.145275 8.00832 0.144114 9.46318C0.433503 10.918 1.14781 12.2544 2.1967 13.3033C3.2456 14.3522 4.58197 15.0665 6.03682 15.3559C7.49168 15.6453 8.99968 15.4967 10.3701 14.9291C11.7406 14.3614 12.9119 13.4001 13.736 12.1668C14.5601 10.9334 15 9.48336 15 8C14.9969 6.01181 14.2058 4.10593 12.7999 2.70007C11.3941 1.29421 9.48819 0.503052 7.5 0.5ZM11.2731 6.51154L7.92692 10.9769C7.76697 11.1932 7.55819 11.3687 7.31757 11.489C7.07696 11.6093 6.81132 11.671 6.54231 11.6692C6.08344 11.6688 5.6435 11.4862 5.31923 11.1615L3.58846 9.43077C3.4356 9.26672 3.35238 9.04974 3.35634 8.82555C3.36029 8.60135 3.45112 8.38745 3.60967 8.2289C3.76822 8.07034 3.98213 7.97952 4.20632 7.97557C4.43052 7.97161 4.64749 8.05483 4.81154 8.20769L6.31154 9.73077C6.33752 9.76221 6.37013 9.78752 6.40703 9.80489C6.44393 9.82227 6.48422 9.83128 6.525 9.83128C6.56579 9.83128 6.60607 9.82227 6.64297 9.80489C6.67987 9.78752 6.71248 9.76221 6.73846 9.73077L9.88846 5.46154C9.95741 5.37062 10.0436 5.29418 10.1421 5.23657C10.2406 5.17895 10.3494 5.14131 10.4625 5.12577C10.5755 5.11024 10.6905 5.11712 10.8009 5.14602C10.9113 5.17492 11.0149 5.22529 11.1058 5.29423C11.1967 5.36317 11.2731 5.44935 11.3307 5.54784C11.3884 5.64632 11.426 5.7552 11.4415 5.86823C11.4571 5.98127 11.4502 6.09626 11.4213 6.20664C11.3924 6.31702 11.342 6.42062 11.2731 6.51154Z"
																					fill="#00B0EB"
																				></path>
																			</svg>
																		</span>
																		<span
																			dangerouslySetInnerHTML={{
																				__html: b,
																			}}
																		/>
																	</div>
																)
															)
															: offer?.License_Offer_Benefits_Description_EN_List.map(
																(b) => (
																	<div
																		key={b}
																	>
																		<span>
																			<svg
																				width="15"
																				height="16"
																				viewBox="0 0 15 16"
																				fill="none"
																				xmlns="http://www.w3.org/2000/svg"
																			>
																				<path
																					d="M7.5 0.5C6.01664 0.5 4.56659 0.939867 3.33323 1.76398C2.09986 2.58809 1.13856 3.75943 0.570907 5.12987C0.00324963 6.50032 -0.145275 8.00832 0.144114 9.46318C0.433503 10.918 1.14781 12.2544 2.1967 13.3033C3.2456 14.3522 4.58197 15.0665 6.03682 15.3559C7.49168 15.6453 8.99968 15.4967 10.3701 14.9291C11.7406 14.3614 12.9119 13.4001 13.736 12.1668C14.5601 10.9334 15 9.48336 15 8C14.9969 6.01181 14.2058 4.10593 12.7999 2.70007C11.3941 1.29421 9.48819 0.503052 7.5 0.5ZM11.2731 6.51154L7.92692 10.9769C7.76697 11.1932 7.55819 11.3687 7.31757 11.489C7.07696 11.6093 6.81132 11.671 6.54231 11.6692C6.08344 11.6688 5.6435 11.4862 5.31923 11.1615L3.58846 9.43077C3.4356 9.26672 3.35238 9.04974 3.35634 8.82555C3.36029 8.60135 3.45112 8.38745 3.60967 8.2289C3.76822 8.07034 3.98213 7.97952 4.20632 7.97557C4.43052 7.97161 4.64749 8.05483 4.81154 8.20769L6.31154 9.73077C6.33752 9.76221 6.37013 9.78752 6.40703 9.80489C6.44393 9.82227 6.48422 9.83128 6.525 9.83128C6.56579 9.83128 6.60607 9.82227 6.64297 9.80489C6.67987 9.78752 6.71248 9.76221 6.73846 9.73077L9.88846 5.46154C9.95741 5.37062 10.0436 5.29418 10.1421 5.23657C10.2406 5.17895 10.3494 5.14131 10.4625 5.12577C10.5755 5.11024 10.6905 5.11712 10.8009 5.14602C10.9113 5.17492 11.0149 5.22529 11.1058 5.29423C11.1967 5.36317 11.2731 5.44935 11.3307 5.54784C11.3884 5.64632 11.426 5.7552 11.4415 5.86823C11.4571 5.98127 11.4502 6.09626 11.4213 6.20664C11.3924 6.31702 11.342 6.42062 11.2731 6.51154Z"
																					fill="#00B0EB"
																				></path>
																			</svg>
																		</span>
																		<span>
																			<span
																				dangerouslySetInnerHTML={{
																					__html: b,
																				}}
																			/>
																		</span>
																	</div>
																)
															)}
													</div>
												)}
										</Col>
										<Col md={5}>
											<div
												className={[
													style.offerPrice,
													i18n.language == 'ar'
														? style.offerPriceAR
														: style.offerPriceEN,
												].join(' ')}
											>
												<div className={style.price}>
													<span>
														{
															offer?.License_Offer_Price
														}
													</span>{' '}
													{t('CurrencyName')}
												</div>
												<div
													dangerouslySetInnerHTML={{
														__html:
															i18n.language ==
																'ar'
																? offer?.License_Offer_Price_Description_AR
																: offer?.License_Offer_Price_Description_EN,
													}}
												/>
											</div>
											{offer?.License_Offer_Image_URL ? (
												<img
													className={style.img}
													alt={
														offer?.License_Offer_Name_EN
													}
													src={
														offer?.License_Offer_Image_URL
													}
												/>
											) : (
												<img
													alt={
														offer?.License_Offer_Name_EN
													}
													className={style.img}
													src={pic1}
												/>
											)}
										</Col>
									</Row>
								</Accordion.Collapse>
							</Accordion>
						</Col>
					))}
				</Row>

				<Row>
					<Pagination
						offersPerPage={offersPerPage}
						totalOffers={offersList.length}
						paginate={paginate}
						currentPage={currentPage}
					/>
				</Row>
			</Container>
			<Footer />
		</>
	);
};

export default AllOffers;
