import React, { useMemo } from "react"
import { Button } from "devextreme-react/button"
import { useTranslation } from "react-i18next"
import "devextreme/dist/css/dx.common.css"
import "devextreme/dist/css/dx.light.css"
import DataGrid, {
  Column,
  Paging,
  FilterRow,
  SearchPanel,
  HeaderFilter,
  ColumnChooser,
  Scrolling
} from "devextreme-react/data-grid"
import "./Grid.scss"

const CustomGrid = ({
  children,
  sourceData,
  addEnabled = false,
  editEnabled = true,
  deleteEnabled = false,
  viewEnabled = false,
  onAdd,
  onEdit,
  onView,
  onDelete,
  SearchPanelEnable = true,
  ColumnChooserEnable = true
}) => {

  const { t, i18n } = useTranslation("common")

  const handleEdit = ({ row }) => onEdit(row)
  const handleView = ({ row }) => onView(row)
  const handleAdd = () => {
    // onAdd();
  }
  const handleDelete = ({ row }) => onDelete(row)

  const ColumnButtons = useMemo(() => {
    let buttonsList = [];
    if (viewEnabled) {
      buttonsList = buttonsList.concat([
        {
          hint: t('Display'),
          icon: `
          <svg width="18px" height="18px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 16.01C14.2091 16.01 16 14.2191 16 12.01C16 9.80087 14.2091 8.01001 12 8.01001C9.79086 8.01001 8 9.80087 8 12.01C8 14.2191 9.79086 16.01 12 16.01Z" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2 11.98C8.09 1.31996 15.91 1.32996 22 11.98" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M22 12.01C15.91 22.67 8.09 22.66 2 12.01" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          `,
          cssClass: 'display-Btn',
          visible: true,
          onClick: handleView,
        },
      ]);
    }
    if (editEnabled) {
      buttonsList = buttonsList.concat([
        {
          hint: t('edit'),
          icon: "edit",
          visible: true,
          onClick: handleEdit,
        },
      ]);
    }
    if (addEnabled) {
      buttonsList = buttonsList.concat([
        {
          hint: t('Add'),
          icon: "add",
          visible: true,
          onClick: handleAdd,
        },
      ]);
    }
    if (deleteEnabled) {
      buttonsList = buttonsList.concat([
        {
          hint: t('delete'),
          icon: "trash",
          visible: true,
          onClick: handleDelete,
        },
      ]);
    }
    return buttonsList;
  }, [addEnabled, deleteEnabled, editEnabled, viewEnabled, handleEdit, handleDelete, handleAdd, handleView])

  return (
    <>
      <div style={{ width: '100%' }}>
        {addEnabled && (
          <div className="flex justify-end items-center mx-4 text-sm">
            <Button icon="plus" className="dx-rounded-full" onClick={handleAdd} />
          </div>
        )}

        <DataGrid
          id="dataGrid"
          width={'100%'}
          dataSource={sourceData}
          showBorders={false}
          showScrollbar={false}
          hoverStateEnabled={true}
          allowColumnReordering={true}
          ShowHorizontalLines={false}
          rowAlternationEnabled={false}
          // columnMinWidth={100}
          // columnAutoWidth={true}
          showColumnLines={false}
          showRowLines={true}
          // columnHidingEnabled={true}
          allowColumnResizing={true}
          columnResizingMode={'widget'}
          // columnResizingMode='nextColumn'
          noDataText={t('There is no data')}
          rtlEnabled={i18n.language === 'ar' ? true : false}
          SearchPanelEnable={SearchPanelEnable}
          ColumnChooserEnable={ColumnChooserEnable}
        >
          <FilterRow visible={true} applyFilter={"auto"} />

          <ColumnChooser
            enabled={ColumnChooserEnable}
            allowSearch={true}
          />

          <SearchPanel
            visible={SearchPanelEnable}
            width={240}
            placeholder={t('search_for')}
          />

          <HeaderFilter visible={true} />

          {children}

          {ColumnButtons.length > 0 &&
            <Column
              cssClass="outlinedColumn"
              fixed={true}
              type="buttons"
              buttons={ColumnButtons}
              caption={t('Details')}
              alignment={"center"}
              fixedPosition={i18n.language === 'ar' ? 'left' : 'right'}
            />
          }

          {/* <Scrolling
            columnRenderingMode="virtual"
            useNative="false"
            scrollByContent="true"
          /> */}

          <Paging defaultPageSize={10} />
        </DataGrid>
      </div>
    </>
  );
};
export default CustomGrid;
