import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import style from "./InnerTitleCont.module.scss";
import { NavLink } from "react-router-dom";

const InnerTitleCont = (props) => {
  const { t, i18n } = useTranslation("common");

  return (
    <Container fluid className={style.InnerTitleCont}>
      <Container fluid="lg" className={style.panelContainer}>
        <Row>
          <Col md={8}>
            <p className={style.breadCrumbs}>{props.breadCrumbs}</p>
            <p className={style.title}>{props.title}</p>
          </Col>
          <Col sm={4} className={["justify-content-end align-items-center d-none d-md-flex", props.startBtn || props.leaveBtn ? style.show : style.hide].join(" ")}>
            {props.startBtn ? (
              <NavLink to={{ pathname: "/service_request", state: props?.permissionId }} className="mainBtn secondarymainBtn lgBtn">
                {t("start_service")}
              </NavLink>
            ) :
              // (
              //   <NavLink to="/" className="mainBtn secondarymainBtn lgBtn">
              //     {t("leave")}
              //   </NavLink>
              // )
              null}
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default InnerTitleCont;
