import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import style from './DownloadApp.module.scss'
import SharjahLogo from './../../../global/images/Sharjah_Municipality.png'
import AppStore from './../../../global/images/app_store.png'
import PlayStore from './../../../global/images/play_store.png'
import iPhone from './../../../global/images/iPhone.png'
import iPhone_1 from './../../../global/images/iPhone_1.png'

const DownloadApp = props => {

    const { t } = useTranslation('common')

    return (
        <Container className={[
            style.downloadApp,
            props.homePage ? style.hasBackground : null
        ].join(' ')} fluid>
            <Container fluid='lg'>
                <Row>
                    <Col md={6}>
                        <div className={style.sharjahLogo} >
                            <img src={SharjahLogo} />
                        </div>
                        <p className={style.title}>{t('download_app_title')}</p>
                        <p className={style.para}>{t('download_app_para1')}</p>
                        <p className={style.para}>{t('download_app_para2')}</p>
                        <div className={style.btnsContainer}>
                            <a href="#">
                                <img className={style.downloadBtn} src={AppStore} />
                            </a>
                            <a href="#">
                                <img className={style.downloadBtn} src={PlayStore} />
                            </a>
                        </div>
                    </Col>
                    <Col md={6} className='d-flex align-items-center'>
                        <div className={style.imgsContainer}>
                            <img className={style.backPhone} src={iPhone_1} />
                            <img className={style.frontPhone} src={iPhone} />
                            <img className={style.backPhone} src={iPhone_1} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default DownloadApp
