import React from "react";
import { Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import PrimaryLogo from "./../../../../global/images/Government_Of_Sharjah.svg";
import STRIPLogo from "./../../../../global/imagesTemp/STRIP.png";
import SecondaryLogo from "./../../../../global/images/GOS_Livestock_Department.svg";

const LogoHeader = () => {
	return (
		// <Row className='justify-content-between align-items-center my-4'>
		<Row className="justify-content-md-between justify-content-center align-items-center my-3">
			<Col lg={4} md={5} xs={6}>
				<NavLink
					to="/"
					style={{ display: "block", width: "100%", height: "100%" }}
				>
					<img
						style={{ maxWidth: "100%", height: "auto" }}
						src={
							process.env.REACT_APP_IMG_SRC == 2
								? STRIPLogo
								: PrimaryLogo
						}
					/>
				</NavLink>
			</Col>
			{/* <Col lg={4} md={5} xs={6} className='d-flex justify-content-end'>
                <div style={{ display: 'block', width: '100%', height: '100%' }}>
                    <img src={SecondaryLogo} />
                </div>
            </Col> */}
		</Row>
	);
};

export default LogoHeader;
