import React, { useEffect } from "react";
import InnerTitleCont from "components/UIComponents/InnerTitleCont/InnterTitleState";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import View from "./View";
import { useDispatch, useSelector } from "react-redux";
import Header from "components/UIComponents/Header/Header";
import Footer from "components/UIComponents/Footer/Footer";
import { useParams, useHistory } from "react-router-dom";
import { fetchCompletedRequestInfo, fetchUnderProcessRequestInfo, fetchRejectedRequestInfo, resetRequest } from "./ServiceViewSlice";
import { NavLink } from "react-router-dom";

const ServiceView = () => {
  const { clientProfiles } = useSelector((state) => state.user);
  const { requestStatusId, requestId } = useParams();
  const { t, i18n } = useTranslation("common");
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (requestStatusId && requestId) {
      if (requestStatusId === "3") {
        const promise = dispatch(
          fetchCompletedRequestInfo({
            Request_Id: requestId,
            Client_Profile_Id: clientProfiles.Client_Profile_Id,
          })
        );
        return () => {
          promise.abort();
          dispatch(resetRequest());
        };
      } else if (requestStatusId === "2") {
        const promise = dispatch(
          fetchUnderProcessRequestInfo({
            Request_Id: requestId,
            Client_Profile_Id: clientProfiles.Client_Profile_Id,
          })
        );
        return () => {
          promise.abort();
          dispatch(resetRequest());
        };
      } else if (requestStatusId === "0") {
        const promise = dispatch(
          fetchRejectedRequestInfo({
            Request_Id: requestId,
            Client_Profile_Id: clientProfiles.Client_Profile_Id,
          })
        );
        return () => {
          promise.abort();
          dispatch(resetRequest());
        };
      }
    } else {
      history.push(`/dashboard`);
    }
  }, []);

  const handleLeave = () => {
    history.push(`/dashboard`);
  };

  return (
    <>
      <Header />

      <InnerTitleCont
        // breadCrumbs={`${t("menu_home")} / ${t("all_services")} / اسم الطلب`}
        breadCrumbs={
          <>
            <NavLink to="/homePage">
              <span>
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.6893 2.97833L7.32932 0.6275C6.41349 -0.0141667 5.00766 0.0208334 4.12682 0.703333L1.20432 2.98417C0.62099 3.43917 0.160156 4.3725 0.160156 5.1075V9.1325C0.160156 10.62 1.36766 11.8333 2.85516 11.8333H9.14349C10.631 11.8333 11.8385 10.6258 11.8385 9.13833V5.18333C11.8385 4.39583 11.331 3.4275 10.6893 2.97833ZM6.43682 9.5C6.43682 9.73917 6.23849 9.9375 5.99932 9.9375C5.76016 9.9375 5.56182 9.73917 5.56182 9.5V7.75C5.56182 7.51083 5.76016 7.3125 5.99932 7.3125C6.23849 7.3125 6.43682 7.51083 6.43682 7.75V9.5Z"
                    fill="white"
                  />
                </svg>
              </span>
            </NavLink>

            <span>
              <svg
                style={{
                  margin: '0 8px',
                  transform:
                    i18n.language == 'ar' && 'scale(-1)',
                }}
                width="6"
                height="10"
                viewBox="0 0 6 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L5 5L1 9"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>

            <span>{t("all_services")} / اسم الطلب</span>
          </>
        }
        title={t("service_view")}
        startBtn={false}
        leaveBtn={true}
        startTrigger={() => { }}
        leaveTrigger={handleLeave}
      />
      <Container fluid="lg" style={{ position: "relative", marginTop: "-50px" }}>
        <Row className="mb-5">
          <Col xs={12}>
            <div className="gCard">
              <View />
            </div>
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
};

export default ServiceView;
