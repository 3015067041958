import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
	name: "message",
	initialState: {
		errorArabic: "",
		errorEnglish: "",
		errorList: "",
		errorCode: null,
		warning: false,
		errorColor: null,
	},
	reducers: {
		messageAdded: (message, { payload }) => ({
			errorArabic: payload.errorArabic,
			errorEnglish: payload.errorEnglish,
			errorList: payload.errorList,
			errorCode: payload?.errorCode,
			warning: payload?.warning,
			errorColor: payload?.errorColor,
		}),
		messageRemoved: (message, action) => ({
			errorArabic: "",
			errorEnglish: "",
			errorList: [],
			errorCode: null,
			warning: false,
			errorColor: null,
		}),
	},
});

export const { messageAdded, messageRemoved } = slice.actions;
export default slice.reducer;
