import React, { useState, useEffect } from 'react'
import SuggestionsGrid from './SuggestionsGrid'
import PopUp from '../../../UIComponents/PopUp/PopUp'
import { Container, Row, Col, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Formik, Form, Field } from 'formik'
import style from './../form.module.scss'
import moment from 'moment'

const Suggestions = props => {

    const { t, i18n } = useTranslation('common')
    const [show, setShow] = useState(false)
    const [modalData, setModalData] = useState()
    const [clickFix, setClickFix] = useState(false)

    const handleClose = () => setShow(false)

    const handleShow = data => {
        setModalData(data)
        setClickFix(!clickFix)
    }

    useEffect(() => {
        if (modalData?.Client_Message_Id) {
            setShow(true)
        }
    }, [clickFix])

    return (
        <>
            <SuggestionsGrid
                handleShow={handleShow}
                dataIsReady={props.dataIsReady}
            />

            {modalData ?
                <PopUp
                    show={show}
                    handleClose={handleClose}
                    title={t('complaints_suggestions')}
                >
                    <Container>
                        <Formik
                            initialValues={{
                                message_no: modalData?.Client_Message_No,
                                message_date: moment(modalData?.Client_Message_Date).lang("en").format('YYYY-MM-DD'),
                                // message_closed: modalData?.Closed ? t('closed') : t('not_closed'),
                                message_closed: modalData?.Closed,
                                message_type: i18n.language === 'en' ? modalData?.Client_Message_Type_Name_EN : modalData?.Client_Message_Type_Name_AR,
                                message: modalData?.Client_Message_Message,
                                message_reply: modalData?.Client_Message_Reply,
                                reply_date: modalData?.Client_Message_Reply_Date ? moment(modalData?.Client_Message_Reply_Date).lang("en").format('YYYY-MM-DD') : '',
                                message_code: modalData?.Client_Message_Code
                            }}
                        >
                            <Form className={style.dashboardData}>
                                <Row
                                    style={{ 'textAlign': i18n.language === 'ar' ? 'start' : null }}
                                >
                                    <Col md={4} sm={6} className='mb-3'>
                                        <label className={style.name}>{t('message_no')}</label>
                                        <Field className={style.dashboardField} name="message_no" type="text" readOnly />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={4} sm={6} className='mb-3'>
                                        <label className={style.name}>{t('message_date')}</label>
                                        <Field className={style.dashboardField} name="message_date" type='text' readOnly />
                                    </Col>

                                    <Col md={4} sm={6} className='mb-3'>
                                        <label className={style.name}>{t('reply_date')}</label>
                                        <Field className={style.dashboardField} name="reply_date" type="text" readOnly />
                                    </Col>

                                    <Col md={4} sm={6} className='mb-3'>
                                        <label className={style.name}>{t('closed')}</label>
                                        <div className={style.dashboardField}>
                                            <Field name="message_closed" type="checkbox" disabled />
                                        </div>
                                    </Col>

                                    <Col md={4} sm={6} className='mb-3'>
                                        <label className={style.name}>{t('message_type')}</label>
                                        <Field className={style.dashboardField} name="message_type" type='text' readOnly />
                                    </Col>
                                    {/* <Col md={4} sm={6} className='mb-3'>
                                            <label className={style.name}>{t('message_status')}</label> 
                                            <Field name="message_closed" type="text" readOnly />
                                        </Col> */}

                                    <Col md={8} sm={12} className='mb-3'>
                                        <label className={style.name}>{t('message_content')}</label>
                                        <Field className={style.dashboardField} name="message" type="text" as="textarea" readOnly />
                                    </Col>

                                    <Col md={4} sm={6} className='mb-3'>
                                        <label className={style.name}>{t('message_code')}</label>
                                        <Field className={style.dashboardField} name="message_code" type="text" readOnly />
                                    </Col>

                                    <Col md={8} sm={12} className='mb-3'>
                                        <label className={style.name}>{t('message_reply')}</label>
                                        <Field className={style.dashboardField} name="message_reply" type="text" as="textarea" readOnly />
                                    </Col>
                                </Row>
                            </Form>
                        </Formik>
                    </Container>
                </PopUp>
                : null}
        </>

    )
}

export default React.memo(Suggestions)
