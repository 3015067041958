import React, { useEffect } from "react";
import Header from "components/UIComponents/Header/Header";
import Footer from "components/UIComponents/Footer/Footer";
import InnerTitleCont from "components/UIComponents/InnerTitleCont/InnterTitleState";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import RequestEdit from "./RequestEdit";
import ConfirmTradeName from "./ConfirmTradeName";
import Request from "./Request";
import RequestAdd from "./RequestAdd";
import RequestAppointment from "./RequestAppointment";
import ServiceMunicipal from "components/UIComponents/Municipal/ServiceMunicipal";
import { toState } from "./serviceMachineSlice";
import {
  fetchUpdateRequestInfo,
  fetchAdditionalRequestInfo,
  fetchInspectionInfo,
  fetchConfirmTradeName,
  resetRequest
} from "./updateRequestSlice";
import { resetDepartment } from "./serviceMunicipSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import style from "./Request.module.scss";
import { NavLink } from "react-router-dom";

const TASK_SUPPORT_TYPE_ID = {
  4: "1",
  5: "2",
  15: "3",
};

const UpdateRequest = () => {
  const { serviceData, requestData, currentTaskData, inputsData, error } = useSelector((state) => state.service.updateRequest);
  const { clientProfiles } = useSelector((state) => state.user);
  const { requestId, taskTypeId, requestTaskId } = useParams();
  const history = useHistory();
  const { t, i18n } = useTranslation("common");
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(
      `requestId: ${requestId}`,
      `taskTypeId: ${taskTypeId}`,
      `requestTaskId: ${requestTaskId}`
    )
    if (requestId && taskTypeId === "4") {
      const promise = dispatch(
        fetchUpdateRequestInfo({
          Request_Id: requestId,
          Client_Profile_Id: clientProfiles?.Client_Profile_Id,
        })
      );
      return () => {
        promise.abort();
      };
    } else if (requestId && taskTypeId === "5") {
      const promise = dispatch(
        fetchAdditionalRequestInfo({
          Request_Id: requestId,
          Client_Profile_Id: clientProfiles?.Client_Profile_Id,
          Task_Id: requestTaskId
        })
      );
      return () => {
        promise.abort();
      };
    } else if (requestId && taskTypeId === "21") {
      const promise = dispatch(
        fetchAdditionalRequestInfo({
          Request_Id: requestId,
          Client_Profile_Id: clientProfiles?.Client_Profile_Id,
          Task_Id: requestTaskId
        })
      );
      return () => {
        promise.abort();
      };
    } else if (requestId && taskTypeId === "15") {
      const promise = dispatch(
        fetchInspectionInfo({
          Request_Id: requestId,
          Client_Profile_Id: clientProfiles?.Client_Profile_Id,
        })
      );
      return () => {
        promise.abort();
      };
    } else if (requestId && taskTypeId === "24") {
      const promise = dispatch(
        fetchConfirmTradeName({
          Request_Id: requestId,
          Client_Profile_Id: clientProfiles?.Client_Profile_Id,
        })
      );
      return () => {
        promise.abort();
      };
    }
  }, [dispatch, requestId, taskTypeId]);

  useEffect(() => {
    if (taskTypeId !== "4" && taskTypeId !== "5" && taskTypeId !== "15" && taskTypeId !== "21" && taskTypeId !== "24") {
      handleLeave();
    }
  }, [taskTypeId]);

  useEffect(() => {
    return () => {
      dispatch(resetDepartment());
      dispatch(resetRequest());
    };
  }, [dispatch]);

  const handleLeave = () => {
    history.push(`/dashboard`);
  };

  return (
    <>
      <Header />

      <InnerTitleCont
        breadCrumbs=
        {
          taskTypeId == 5 ?
            <>
              <NavLink to="/Dashboard">{t("Dashboard")}</NavLink> / {t("add_request_info")}
            </>
            :
            taskTypeId == 4 ?
              <>
                <NavLink to="/Dashboard">{t("Dashboard")}</NavLink> / {t("edit_request_info")}
              </>
              :
              taskTypeId == 21 ?
                <>
                  <NavLink to="/Dashboard">{t("Dashboard")}</NavLink> / {t("recommendations_for_modification")}
                </>
                :
                taskTypeId == 15 ?
                  <>
                    <NavLink to="/Dashboard">{t("Dashboard")}</NavLink> / {t("Set Inspection Visit Time")}
                  </>
                  :
                  taskTypeId == 24 ?
                    <>
                      <NavLink to="/Dashboard">{t("Dashboard")}</NavLink> / {t("confirm_trade_name")}
                    </>
                    : false
        }
        title={i18n.language === "en" ? serviceData?.Service_Name_EN ?? "" : serviceData?.Service_Name_AR ?? ""}
        startBtn={false}
        leaveBtn={true}
        startTrigger={() => dispatch(toState("Request"))}
        leaveTrigger={handleLeave}
      />

      <Container fluid="lg" style={{ position: "relative", marginTop: "-50px" }}>
        <Row className="mb-5">
          <Col md={8}>
            <div className={["gCard", style.gCard].join(' ')}>
              <>
                {taskTypeId === "5" && <RequestAdd />}
                {/* {taskTypeId === "4" && <Request isUpdate={true} />} */}
                {taskTypeId === "4" && <RequestEdit />}
                {taskTypeId === "21" && <RequestAdd />}
                {taskTypeId === "15" && <RequestAppointment />}
                {taskTypeId === "24" && <ConfirmTradeName />}
              </>
            </div>
          </Col>
          <Col md={4}>
            <div className="gCard">
              <ServiceMunicipal
                municipalDetails={serviceData}
                requestNo={requestData?.Request_No}
                inputsData={currentTaskData?.Task_Type_Id === "5" ? currentTaskData : inputsData}
                supportTypeId={currentTaskData?.Task_Type_Id ? TASK_SUPPORT_TYPE_ID[currentTaskData.Task_Type_Id] : ""}
                isSupportEnabled={true}
                error={error}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default UpdateRequest;
