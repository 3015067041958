import React, { useState, useEffect } from "react"
import Grid from "components/UIComponents/Grid"
import { Column } from "devextreme-react/data-grid"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import axios from "axios"
import { useHistory } from "react-router-dom"
import style from "./../Dashboard.module.scss"
import { Row } from "react-bootstrap"

const ActiveLicensesGrid = props => {
    const { t, i18n } = useTranslation("common")
    const [data, setData] = useState([])
    const { user, clientProfiles } = useSelector((state) => state.user)

    useEffect(() => {
        const getActiveLicensesList = async () => {
            const res = await axios.post(
                `${process.env.REACT_APP_API_URL}/PORTAL/CLIENTS/DASHBOARD/GET_CLIENT_DASHBOARD_ACTIVE_LICENSES_LIST`,
                {
                    Client_Profile_Id: clientProfiles?.Client_Profile_Id,
                    PageIndex: "1",
                    PageCount: "10",
                },
                {
                    headers: {
                        Authorization: `Bearer ${user?.access_token}`,
                    },
                }
            ).then(data => {
                setData(data.data.Data.Data)
                props.dataIsReady(false)
            })
        };
        getActiveLicensesList();
    }, []);

    let LicenseName = "License_Name_EN",
        LicenseLegalFormName = "License_Legal_Form_Name_EN",
        LicenseSegment = "License_Segment_Name_EN",
        RequestDesc = "Request_Desc_EN";

    if (i18n.language === "ar") {
        LicenseName = "License_Name_AR";
        LicenseLegalFormName = "License_Legal_Form_Name_AR";
        LicenseSegment = "License_Segment_Name_AR";
        RequestDesc = "Request_Desc_AR";
    }

    return (
        <>
            <Row className='justify-content-center'>
                <p className={style.gridTitle}>{t("activeLicenses")}</p>
            </Row>

            <Row>
                <Grid
                    sourceData={data}
                    addEnabled={false}
                    editEnabled={false}
                    deleteEnabled={false}
                    viewEnabled={true}
                    onView={({ data }) => {
                        props.getRequestId(data)
                    }}>
                    <Column caption={t("License_No")} alignment="center" dataField="License_No" dataType="string" width='auto' />
                    <Column caption={t("License_Name")} alignment="center" dataField={LicenseName} dataType="string" width='auto' />
                    <Column caption={t("License_Legal_Form")} alignment="center" dataField={LicenseLegalFormName} dataType="string" width='auto' />
                    <Column caption={t("License_Segment_Name")} alignment="center" dataField={LicenseSegment} dataType="string" width='auto' />
                    <Column caption={t("License_Expire_Date")} alignment="center" dataField="License_Expire_Date" dataType="date" format="yyyy-MM-dd" width='auto' />
                    <Column caption={t("requestDesc")} alignment="center" dataField={RequestDesc} dataType="string" width='auto' />
                </Grid>
            </Row>
        </>
    )
}

export default React.memo(ActiveLicensesGrid)