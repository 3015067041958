

const PaymentRequest = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
      <path d="M30.7267 17.2025C30.6089 16.2641 30.4551 15.3306 30.2658 14.404C29.8265 12.0736 27.828 10.1992 25.316 9.84342C25.2866 9.83945 25.2571 9.83547 25.2277 9.83148C25.2107 9.7218 25.1948 9.61196 25.1799 9.50197C27.7802 8.33197 27.1068 4.69542 24.5734 4.16772C22.4985 3.73793 17.6848 3.57079 14.8075 3.57079C12.0759 3.57079 10.2493 3.95283 8.29611 4.35874C8.19105 4.38262 8.08599 4.4065 7.97854 4.4256C5.88926 4.86256 4.22499 7.15481 3.85728 10.0129L3.83579 10.1753C3.47308 12.7098 3.29989 15.2678 3.31764 17.8281C3.31764 18.4369 3.32481 18.9981 3.33675 19.521C3.32481 19.9126 3.31764 20.3328 3.31764 20.7865C3.31764 23.7354 3.61134 25.2922 3.94085 27.0376L3.96473 27.1714C4.40646 29.5018 6.4074 31.3738 8.91693 31.732L9.29897 31.7845C11.6437 32.1188 13.8357 32.4292 17.1165 32.4292C20.3973 32.4292 22.5892 32.1188 24.934 31.7845L25.316 31.7296C27.828 31.3738 29.8289 29.5018 30.2658 27.169L30.2921 27.0376C30.4693 26.155 30.6143 25.2661 30.7267 24.3729" stroke="#9AB1D5" strokeWidth="2.57143" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.7695 9.80998C13.4075 9.68581 16.1129 9.39212 18.9256 9.39212C21.8387 9.39212 23.2164 9.54016 25.1266 9.80759" stroke="#9AB1D5" strokeWidth="2.57143" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M30.7531 24.4947H27.0162C26.0464 24.4947 25.1162 24.1094 24.4304 23.4236C23.7446 22.7378 23.3594 21.8077 23.3594 20.8378C23.3594 19.868 23.7446 18.9378 24.4304 18.252C25.1162 17.5662 26.0464 17.181 27.0162 17.181H30.7554C31.069 17.181 31.3795 17.2427 31.6692 17.3627C31.9589 17.4827 32.2221 17.6586 32.4438 17.8803C32.6656 18.102 32.8414 18.3653 32.9614 18.655C33.0814 18.9447 33.1432 19.2552 33.1432 19.5687V22.1069C33.1432 22.7402 32.8916 23.3475 32.4438 23.7953C31.9961 24.2431 31.3863 24.4947 30.7531 24.4947Z" fill="white" />
      <path d="M30.7531 24.4947H27.0162C26.0464 24.4947 25.1162 24.1094 24.4304 23.4236C23.7446 22.7378 23.3594 21.8077 23.3594 20.8378C23.3594 19.868 23.7446 18.9378 24.4304 18.252C25.1162 17.5662 26.0464 17.181 27.0162 17.181H30.7554C31.069 17.181 31.3795 17.2427 31.6692 17.3627C31.9589 17.4827 32.2221 17.6586 32.4438 17.8803C32.6656 18.102 32.8414 18.3653 32.9614 18.655C33.0814 18.9447 33.1432 19.2552 33.1432 19.5687V22.1069C33.1432 22.7402 32.8916 23.3475 32.4438 23.7953C31.9961 24.2431 31.3863 24.4947 30.7531 24.4947Z" stroke="#9AB1D5" strokeWidth="2.57143" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}


export default PaymentRequest;
