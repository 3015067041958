import React from "react";
import { Form } from "react-bootstrap";

const InputsLookup = {
  1: (props) => <Form.Control {...props} type="text" />,
  2: ({ children, ...props }) => (
    <Form.Control {...props} as="select">
      {children}
    </Form.Control>
  ),
  10: (props) => <Form.Control {...props} type="text" />,
  3: (props) => <Form.Control {...props} type="file" />,
  4: (props) => <Form.Control {...props} as="textarea" rows={3} />,
  5: (props) => <Form.Control {...props} type="number" />,
  6: (props) => <Form.Control {...props} type="date" />,
  7: (props) => <Form.Check {...props} type="checkbox" />,
  8: (props) => <Form.Control {...props} type="phone" />,
  9: (props) => <Form.Control {...props} type="email" />,
};

export default InputsLookup;
