// Session
export const loadSession = (itemName) => {
  try {
    const serializedState = sessionStorage.getItem(itemName);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

export const saveSession = (state, itemName) => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem(itemName, serializedState);
  } catch (error) {
    console.error(error);
  }
};

export const removeSession = (itemName) => {
  try {
    sessionStorage.removeItem(itemName);
  } catch (error) {
    console.error(error);
  }
};


// Conditional state Local or Session based on savePassword value
// and if Session already contain a user  
export const loadState = (itemName) => {
  try {
    let serializedState
    // console.log('localStorage', localStorage.getItem(itemName))
    // console.log('sessionStorage', sessionStorage.getItem(itemName))
    // if (localStorage?.getItem('savePassword') == 'true') {
    //   serializedState = localStorage.getItem(itemName);
    // } else {
    //   serializedState = sessionStorage.getItem(itemName);
    // }
    if (sessionStorage.getItem(itemName) !== null) {
      serializedState = sessionStorage.getItem(itemName);
    } else {
      serializedState = localStorage.getItem(itemName);
    }
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

export const saveState = (state, itemName) => {
  setTimeout(() => {
    let savePassword = localStorage?.getItem('savePassword')
    try {
      const serializedState = JSON.stringify(state);
      // console.log(savePassword)
      if (savePassword == 'true') {
        // console.log('Local')
        localStorage.setItem(itemName, serializedState);
      }
      if (savePassword == 'false') {
        // console.log('Session')
        sessionStorage.setItem(itemName, serializedState);
      }
    } catch (error) {
      console.error(error);
    }
  }, 500);
};

export const removeState = (itemName) => {
  try {
    // if (localStorage?.getItem('savePassword') == 'true') {
    //   localStorage.removeItem(itemName);
    // } else {
    //   sessionStorage.removeItem(itemName);
    // }
    let serializedState
    if (sessionStorage.getItem(itemName) !== null) {
      serializedState = sessionStorage.removeItem(itemName);
    } else {
      serializedState = localStorage.removeItem(itemName);
    }
  } catch (error) {
    console.error(error);
  }
};

///////////////////////////////////

export const handleNonDecimalEvent = (event) => {
  if (event.which < 48 || event.which > 57) {
    event.preventDefault();
  }
};

export const isTokenExpired = (expirationDate) => {
  const now = new Date();
  const expireDate = new Date(expirationDate);
  return now.getTime() > expireDate.getTime();
};

export const getTitme = (date) => {
  const time = new Date(date);
  return time;
};

export const removeProperty = (key, { [key]: _, ...rest }) => rest;

export function isArabicLetter(str) {
  if (str.length >= 1) {
    return str.replace(/[^[\u0600-\u06ff ][^0-9- ]+|[\u0750-\u077f]|[\ufb50-\ufc3f]|[\ufe70-\ufefc]]/g, "");
  }
  return "";
}
