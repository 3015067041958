import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import style from "./Request.module.scss";
import { useTranslation } from "react-i18next";
import { Row, Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash.debounce";
import {
  submitRequestInspection,
  rejectRequestInspection,
  saveAdditionalRequestInputNotes,
  saveAdditionalRequestAttachmentData,
  deleteAdditionalRequestAttachment,
  downloadAdditionalRequestAttachment,
  requestAdditionDataInspectionVisitLocation,
  downloadAttachment,
} from "api/serviceAPI";
import { setError, saveAdditionalAttachment } from "./updateRequestSlice";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt,
  faDownload,
  faUpload,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import {
  Attachment,
  Csv,
  Doc,
  Excel,
  File,
  Jpg,
  Mp3,
  Mp4,
  Pdf,
  Png,
  Text,
  Zip,
} from "components/UIComponents/FileIcons";
import LoadingOverlay from "components/UIComponents/LoadingOverlay";
import {
  GoogleMap,
  LoadScript,
  Marker,
  useLoadScript,
  useJsApiLoader,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import ProgressBar from "../../components/UIComponents/ProgressBar/ProgressBar";

const RequestAppointment = (props) => {
  const { t, i18n } = useTranslation("common");
  const {
    serviceData,
    requestData,
    requestDate,
    inputsDataValue,
    attachmentsDataValue,
    requestId,
    inputsData,
    attachmentsData,
    requiredChangesAttachments,
    requiredChangesNotes,
    currentTaskData,
    loading,
  } = useSelector((state) => state.service.updateRequest);
  const { clientProfiles } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [highlightTerms, setHighlightTerms] = useState(false);
  const termsRef = useRef();
  const [searchBox, setSearchBox] = useState();
  const [isEllipsis, setIsEllipsis] = useState(true);
  let zoom = 12;
  const [mapState, setMapState] = useState({
    position: {
      lat: 0,
      lng: 0,
    },
    center: {
      lat: 0,
      lng: 0,
    },
  });
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyCBx-BN5uLwmFezDw0NeQs9xp_UcplHIzs",
    libraries: ["places"],
  });
  useEffect(() => {
    if (isNaN(parseFloat(currentTaskData?.Inspection_latitudes))) {
      return;
    }
    if (
      parseFloat(currentTaskData?.Inspection_latitudes) == 0 &&
      parseFloat(currentTaskData?.Inspection_longitudes) == 0
    ) {
      navigator.geolocation.getCurrentPosition((position) => {
        let pos = {
          lat: parseFloat(position.coords.latitude),
          lng: parseFloat(position.coords.longitude),
        };
        setMapState({
          center: {
            lat: parseFloat(pos.lat),
            lng: parseFloat(pos.lng),
          },
          position: {
            lat: parseFloat(pos.lat),
            lng: parseFloat(pos.lng),
          },
        });
      });
    } else {
      let insplat = parseFloat(currentTaskData?.Inspection_latitudes);
      let insplng = parseFloat(currentTaskData?.Inspection_longitudes);
      setMapState({
        position: {
          lat: insplat,
          lng: insplng,
        },
        center: {
          lat: insplat,
          lng: insplng,
        },
      });
    }
  }, [currentTaskData]);

  useEffect(() => {
    if (
      parseFloat(mapState.position.lng) &&
      parseFloat(mapState.position.lat)
    ) {
      saveRequestAdditionDataInspectionVisitLocation();
    }
  }, [mapState]);

  // useEffect(() => {
  //   if (termsRef && highlightTerms && !acceptedTerms) {
  //     termsRef.current.style.transform = "scale(1.01)";
  //     termsRef.current.style.textDecoration = "underline";
  //   } else {
  //     termsRef.current.style.transform = "";
  //     termsRef.current.style.textDecoration = "";
  //   }
  // }, [termsRef, highlightTerms, acceptedTerms]);

  const handleTerms = () => {
    setAcceptedTerms(Number(!acceptedTerms));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    setIsSubmiting(true);
    submitRequestInspection(
      {
        Task_Id: currentTaskData?.Task_Id,
        Client_Profile_Id: clientProfiles.Client_Profile_Id,
        Request_Id: requestId,
      },
      source
    )
      .then((response) => {
        if (response.Status) {
          history.push(`/dashboard`);
        }
      })
      .catch((err) => {
        dispatch(setError(err));
      })
      .finally(() => {
        setIsSubmiting(false);
      });
    return () => {
      source.cancel();
    };
  };

  const handleChangingRequest = (e) => {
    // e.preventDefault();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    setIsSubmiting(true);
    rejectRequestInspection(
      {
        Task_Id: currentTaskData?.Task_Id,
        Client_Profile_Id: clientProfiles.Client_Profile_Id,
        Request_Id: requestId,
      },
      source
    )
      .then((response) => {
        if (response.Status) {
          history.push(`/dashboard`);
        }
      })
      .catch((err) => {
        dispatch(setError(err));
      })
      .finally(() => {
        setIsSubmiting(false);
      });
    return () => {
      source.cancel();
    };
  };

  const handleSave = () => {
    history.push(`/dashboard`);
  };

  const saveRequestAdditionDataInspectionVisitLocation = () => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    setIsSubmiting(true);
    requestAdditionDataInspectionVisitLocation(
      {
        Task_Id: currentTaskData?.Task_Id,
        Client_Profile_Id: clientProfiles.Client_Profile_Id,
        Request_Id: requestId,
        Inspection_longitudes: mapState.position.lng,
        Inspection_latitudes: mapState.position.lat,
      },
      source
    )
      // .then((response) => {
      //     if (response.Status) {
      //     history.push(`/dashboard`);
      //     }
      // })
      .catch((err) => {
        dispatch(setError(err));
      })
      .finally(() => {
        setIsSubmiting(false);
      });
    return () => {
      source.cancel();
    };
  };

  const stylesArr = [
    {
      featureType: "landscape",
      elementType: "all",
      stylers: [{ visibility: "simplified" }, { color: "#fdfdfd" }],
    },
    {
      featureType: "landscape.man_made",
      elementType: "geometry",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "landscape.natural.landcover",
      elementType: "geometry",
      stylers: [{ visibility: "simplified" }],
    },
    {
      featureType: "landscape.natural.landcover",
      elementType: "geometry.fill",
      stylers: [{ visibility: "simplified" }],
    },
    {
      featureType: "landscape.natural.terrain",
      elementType: "geometry",
      stylers: [{ visibility: "simplified" }],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [{ visibility: "simplified" }],
    },
    {
      featureType: "poi",
      elementType: "labels.icon",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "poi.attraction",
      elementType: "geometry",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "poi.business",
      elementType: "geometry",
      stylers: [{ visibility: "simplified" }],
    },
    {
      featureType: "poi.business",
      elementType: "geometry.fill",
      stylers: [{ hue: "#fcff00" }, { saturation: "0" }, { lightness: "40" }],
    },
    {
      featureType: "poi.government",
      elementType: "geometry",
      stylers: [{ visibility: "on" }, { color: "#28a745" }],
    },
    {
      featureType: "poi.medical",
      elementType: "geometry",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [{ visibility: "simplified" }],
    },
    {
      featureType: "poi.place_of_worship",
      elementType: "geometry",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "poi.school",
      elementType: "geometry",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "poi.sports_complex",
      elementType: "geometry",
      stylers: [{ visibility: "simplified" }],
    },
    {
      featureType: "road",
      elementType: "all",
      stylers: [{ color: "#000000" }],
    },
    {
      featureType: "road",
      elementType: "geometry.fill",
      stylers: [{ color: "#ffffff" }],
    },
    {
      featureType: "road",
      elementType: "geometry.stroke",
      stylers: [{ gamma: 7.18 }],
    },
    {
      featureType: "road",
      elementType: "labels.text",
      stylers: [
        { visibility: "on" },
        { saturation: "-35" },
        { lightness: "-95" },
        { gamma: "0.00" },
        { weight: "0.01" },
      ],
    },
    {
      featureType: "road",
      elementType: "labels.icon",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "road.highway",
      elementType: "all",
      stylers: [{ color: "#baa167" }, { lightness: "40" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [{ color: "#ffffff" }],
    },
    {
      featureType: "road.arterial",
      elementType: "geometry",
      stylers: [
        { visibility: "on" },
        { color: "#baa167" },
        { lightness: "80" },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "geometry.stroke",
      stylers: [{ color: "#ffffff" }],
    },
    {
      featureType: "road.local",
      elementType: "geometry",
      stylers: [{ visibility: "simplified" }],
    },
    {
      featureType: "road.local",
      elementType: "labels.text",
      stylers: [{ visibility: "on" }],
    },
    {
      featureType: "transit",
      elementType: "all",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "transit.line",
      elementType: "geometry",
      stylers: [{ gamma: 0.48 }, { visibility: "off" }],
    },
    {
      featureType: "transit.station",
      elementType: "all",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "transit.station",
      elementType: "labels.icon",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "transit.station.rail",
      elementType: "all",
      stylers: [{ visibility: "off" }, { color: "#ff0000" }],
    },
    {
      featureType: "water",
      elementType: "all",
      stylers: [{ color: "#4d4946" }],
    },
    {
      featureType: "water",
      elementType: "geometry.fill",
      stylers: [
        { visibility: "on" },
        { color: "#151713" },
        { lightness: "70" },
        { saturation: "0" },
      ],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [{ color: "#ffffff" }],
    },
  ];

  // const svgMarker = {
  //   path: "M30.5,11.4c-1.4-4.6-5.1-8.3-9.7-9.7C16,0.2,10.9,1.1,7,3.9c-3.9,2.9-6.2,7.5-6.2,12.3c0,3.3,1.1,6.5,3,9.1 L16,41l12.2-15.6C31.2,21.4,32,16.3,30.5,11.4z M16,24.4c-4.5,0-8.2-3.7-8.2-8.2S11.5,8,16,8s8.2,3.7,8.2,8.2S20.5,24.4,16,24.4z, M16,10.4c-3.2,0-5.9,2.6-5.9,5.8s2.6,5.9,5.9,5.9s5.9-2.6,5.9-5.9C21.9,13,19.2,10.4,16,10.4z",
  //   fillColor: "#28A745",
  //   fillOpacity: 1,
  //   strokeWeight: 2,
  //   strokeColor: "#ffffff",
  //   rotation: 0,
  //   scale: 1.1,
  // };

  const containerStyle = {
    width: "100%",
    height: "400px",
    borderRadius: "12px",
  };

  const onClick = (e) => {
    setMapState({
      position: {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      },
    });
  };

  const onLoadSearch = (ref) => setSearchBox(ref);

  const onPlacesChanged = () => {
    setMapState({
      center: {
        lat: searchBox.getPlaces()[0].geometry.location.lat(),
        lng: searchBox.getPlaces()[0].geometry.location.lng(),
      },
      position: {
        lat: searchBox.getPlaces()[0].geometry.location.lat(),
        lng: searchBox.getPlaces()[0].geometry.location.lng(),
      },
    });
  };

  const onLoad = () => {
    // setMapState({
    // 	position: props.position,
    // 	center: props.position,
    // 	styles: props.styles
    // })
  };

  const onDragEnd = (marker) => {
    setMapState({
      position: {
        lat: marker.latLng.lat(),
        lng: marker.latLng.lng(),
      },
    });
  };

  const getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      let pos = {
        lat: parseFloat(position.coords.latitude),
        lng: parseFloat(position.coords.longitude),
      };
      setMapState({
        center: {
          lat: parseFloat(pos.lat),
          lng: parseFloat(pos.lng),
        },
        position: {
          lat: parseFloat(pos.lat),
          lng: parseFloat(pos.lng),
        },
      });
    });
  };

  const download = async (f) => {
    await downloadAttachment({
      File_Code: f.File_Code,
    })
      .then(function (response) {
        const blob = new Blob([response]);

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", f.File_Name);
        // document.body.appendChild(link);
        link.click();
      })
      .catch((ex) => {
        console.error(ex);
      });
  };

  const handleToggleEllipsis = () => {
    setIsEllipsis(!isEllipsis);
  };

  return (
    <>
      <Row className="align-items-center position-relative">
        <Col xs={6}>
          <p className={style.title}>{t("Service Information")}</p>
        </Col>

        <Col xs={6} className="d-flex justify-content-end mb-2">
          <button className="mainBtn primarymainBtn smBtn" onClick={handleSave}>
            {t("save")}
          </button>
        </Col>
      </Row>

      <Row className="align-items-center position-relative">
        <Col xs={12} className={style.detailsContent}>
          <Row>
            <p className={style.details}>{t("Request Number")}</p>
            <p className={style.details}>{requestData?.Request_No}</p>
          </Row>
          <Row>
            <p className={style.details}>{t("Request Date")}</p>
            <p className={style.details}>
              {requestData?.Request_Date &&
                moment(requestData.Request_Date)
                  .lang("en")
                  .format("YYYY-MM-DD")}
            </p>
          </Row>
          {/* <svg ></svg> */}
          <Row className="justify-content-between">
            <Col xs={6}>
              <Row>
                <p className={style.details}>{t("service_name")}:</p>
                <p className={style.details}>
                  {i18n.language === "ar"
                    ? serviceData?.Service_Name_AR
                    : serviceData?.Service_Name_EN}
                </p>
              </Row>
            </Col>

            <Col xs={6}>
              <Row>
                <p className={style.details}>{t("service_code")}:</p>
                <p className={style.details}>{serviceData?.Service_Code}</p>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col xs={6}>
              <Row>
                <p className={style.details}>{t("Service Version")}</p>
                <p className={style.details}>
                  {serviceData?.Department_Service_Version_Name}
                </p>
              </Row>
            </Col>

            <Col xs={6}>
              <Row>
                <p className={style.details}>{t("Service Category")}</p>
                <p className={style.details}>
                  {i18n.language === "ar"
                    ? serviceData?.Service_Category_Name_AR
                    : serviceData?.Service_Category_Name_EN}
                </p>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col xs={6}>
              <Row>
                <p className={style.details}>{t("department_name")}:</p>
                <p className={style.details}>
                  {i18n.language === "ar"
                    ? serviceData?.Department_Name_AR
                    : serviceData?.Department_Name_EN}
                </p>
              </Row>
            </Col>

            <Col xs={6}>
              <Row>
                <p className={style.details}>{t("Section Name")}</p>
                <p className={style.details}>
                  {i18n.language === "ar"
                    ? serviceData?.Department_Section_Name_AR
                    : serviceData?.Department_Section_Name_EN}
                </p>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col xl={12}>
          <p className={style.title}>{t("steps")}</p>
        </Col>

        <Col xl={12} className={style.step}>
          <div className={style.stepHeader}>
            <span className={style.number}>1</span>
            <p className={style.stepTitle}>
              {t("Please confirm the date of the inspection visit")}
            </p>
          </div>

          <div className={style.stepContent}>
            {loading !== "pending" ? (
              <Row>
                <Col md={6} xs={12}>
                  <Form.Label className={style.label}>
                    {t("Date of the inspection visit")}
                  </Form.Label>
                  <Form.Control
                    defaultValue={moment(requestData?.Inspection_Time)
                      .lang("en")
                      .format("YYYY/MM/DD")}
                    disabled
                  />
                </Col>

                <Col md={6} xs={12}>
                  <Form.Label className={style.label}>
                    {t("Inspection visit time")}
                  </Form.Label>
                  <Form.Control
                    defaultValue={moment(requestData?.Inspection_Time)
                      .lang("en")
                      .format("HH:mm a")}
                    disabled
                  />
                </Col>
                {console.log(requestData)}

                <Col md={6} xs={12}>
                  <Form.Label className={style.label}>
                    {t("inspector_name")}
                  </Form.Label>
                  <Form.Control
                    defaultValue={
                      i18n.language === "ar"
                        ? requestData?.Inspection_User_Name_AR
                        : requestData?.Inspection_User_Name_EN
                    }
                    disabled
                  />
                </Col>
              </Row>
            ) : (
              <div>{t("Loading")}</div>
            )}
          </div>
        </Col>

        <Col xl={12} className={style.step}>
          <div className={style.stepHeader}>
            <span className={style.number}>2</span>
            <p className={style.stepTitle}>
              {t("Please specify the location of the inspection visit")}
            </p>
          </div>

          <div className={style.mapContent}>
            <Row>
              <Col md={12}>
                {isLoaded ? (
                  <>
                    <button
                      className={[
                        style.mapBtn,
                        "mainBtn secondarymainBtn outlined mb-2",
                      ].join(" ")}
                      onClick={() => getCurrentLocation()}
                    >
                      {t("showMyLocationOnMap")}
                    </button>

                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      center={mapState?.center}
                      zoom={zoom}
                      onClick={(e) => onClick(e)}
                      defaultOptions={{ styles: stylesArr }}
                      options={{ styles: stylesArr }}
                    >
                      <StandaloneSearchBox
                        onLoad={onLoadSearch}
                        onPlacesChanged={onPlacesChanged}
                      >
                        <input
                          type="text"
                          placeholder={t("search")}
                          style={{
                            boxSizing: `border-box`,
                            marginTop: "10px",
                            border: `1px solid #fff`,
                            borderRadius: "4px",
                            width: `240px`,
                            height: `40px`,
                            padding: `0 12px`,
                            backgroundColor: "rgba(255,255,255,0.9)",
                            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                            fontSize: `14px`,
                            outline: `none`,
                            textOverflow: `ellipses`,
                            position: "absolute",
                            left: "50%",
                            marginLeft: "-120px",
                          }}
                        />
                      </StandaloneSearchBox>

                      <Marker
                        draggable
                        onLoad={onLoad}
                        onDragEnd={onDragEnd}
                        position={mapState?.position}
                      // icon={svgMarker}
                      />
                    </GoogleMap>
                  </>
                ) : (
                  false
                )}
              </Col>
            </Row>
          </div>
        </Col>

        <Col xl={12} className={style.step}>
          <div className={style.stepHeader}>
            <span className={style.number}>3</span>
            <p className={style.stepTitle}>
              {t("Modifications and Required Data")}
            </p>
          </div>

          <div className={style.stepContent}>
            {loading !== "pending" ? (
              <Row>
                <Col xs={12}>
                  <Form.Label className={style.label}>{t("notes")}</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    defaultValue={requiredChangesNotes}
                    disabled
                  />
                </Col>

                <Col xs={12}>
                  <Form.Label className={style.label}>
                    {t("attachedFilesFromEmployee")}
                  </Form.Label>
                  <div className={style.fileContainer}>
                    {/* <div className={style.fileLabel}>
                      <FontAwesomeIcon color={"#d3d3d3"} icon={faDownload} />
                    </div> */}

                    {requiredChangesAttachments?.length > 0 && (
                      <div className={`${style.files} mt-2`}>
                        {requiredChangesAttachments.map((uploadedFile) => (
                          <div
                            key={uploadedFile.File_Code}
                            className="d-flex justify-content-between mb-1 p-1"
                          >
                            <div className="d-flex">
                              {getFileIcon(uploadedFile.File_Content)}
                              <span
                                onClick={handleToggleEllipsis}
                                className={`${style.fileContent} ${isEllipsis ? style.clientRequestInfo : ""
                                  } mx-1`}
                              >
                                {uploadedFile.File_Name}
                              </span>
                              <span className={`${style.fileContent} mx-1`}>
                                {uploadedFile.File_Size}
                              </span>
                            </div>

                            <div className="d-flex">
                              <FontAwesomeIcon
                                className="mx-1"
                                size="1x"
                                color={"#1e90ff"}
                                icon={faDownload}
                                onClick={() => download(uploadedFile)}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            ) : (
              <div>{t("Loading")}</div>
            )}
          </div>
        </Col>
      </Row>

      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xl={12} className={style.step}>
            <div className={style.stepHeader}>
              <span className={style.number}>4</span>
              <p className={style.stepTitle}>البيانات المضافة للطلب</p>
            </div>

            <div className={style.stepContent}>
              {loading !== "pending" ? (
                <Row>
                  <Col xs={12}>
                    <Form.Label className={style.label}>
                      {t("notes")}
                    </Form.Label>
                    <Textarea defaultValue={currentTaskData?.Task_Notes} />
                  </Col>
                  <AddedFiles
                    defaultValue={currentTaskData?.Task_Attachments}
                  />
                </Row>
              ) : (
                <div>{t("Loading")}</div>
              )}
            </div>
          </Col>

          <Col xl={12} className={style.step}>
            <div className={[style.terms, 'flex-row'].join(' ')}>
              <input
                className={style.checkBox}
                type="checkbox"
                defaultChecked={acceptedTerms}
                onChange={handleTerms}
              />
              {/* uncomment out useEffect */}
              {/* <p ref={termsRef}>{t("terms and conditions")}</p>
            </div> */}
              <div
                className={style.terms}
                dangerouslySetInnerHTML={{
                  __html:
                    i18n.language === "ar"
                      ? serviceData?.Service_Rules_AR
                      : serviceData?.Service_Rules_EN,
                }}
              ></div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={6} className="d-flex justify-content-center">
            <button
              type="submit"
              className="mainBtn primarymainBtn lgBtn my-1"
              disabled={!acceptedTerms}
              onPointerEnter={() => setHighlightTerms(true)}
              onPointerLeave={() => setHighlightTerms(false)}
              style={{ cursor: acceptedTerms ? "pointer" : "not-allowed" }}
            >
              {t("Confirm the appointment")}
            </button>
          </Col>

          <Col md={6} className="d-flex justify-content-center">
            <button
              type="button"
              className="mainBtn primarymainBtn lgBtn my-1"
              disabled={!acceptedTerms}
              onPointerEnter={() => setHighlightTerms(true)}
              onPointerLeave={() => setHighlightTerms(false)}
              style={{ cursor: acceptedTerms ? "pointer" : "not-allowed" }}
              onClick={() => handleChangingRequest()}
            >
              {t("Request changing the appointment")}
            </button>
          </Col>
        </Row>
      </Form>

      {isSubmiting && <LoadingOverlay />}
    </>
  );
};

export default RequestAppointment;

const Textarea = ({ defaultValue }) => {
  const { clientProfiles } = useSelector((state) => state.user);
  const { requestId, currentTaskData } = useSelector(
    (state) => state.service.updateRequest
  );
  const dispatch = useDispatch();
  const [value, setValue] = useState(defaultValue ? defaultValue : "");
  const { t, i18n } = useTranslation("common");
  const [validationError, setValidationError] = useState(false);

  const onKeyPress = (e) => {
    var ew = e.which;
    if (ew != 8) {
      if ((ew < 48 || ew > 57) && (ew < 0x0600 || ew > 0x06ff) && ew != 32) {
        setValidationError(true);
        e.preventDefault();
        return false;
      }
    }
    if (validationError) {
      setValidationError(false);
    }
    return true;
  };

  const handleChangeWithDebounce = debounce(async (e) => {
    if (e.target.value) {
      await saveAdditionalRequestInputNotes({
        Client_Profile_Id: clientProfiles?.Client_Profile_Id,
        Request_Id: requestId,
        Task_Notes: e.target.value,
        Task_Id: currentTaskData?.Task_Id,
      }).catch((err) => {
        dispatch(setError(err));
      });
      setValue(e.target.value);
    }
  }, 500);

  return (
    <>
      <Form.Control
        name={"notes"}
        defaultValue={value}
        onKeyPress={onKeyPress}
        onChange={handleChangeWithDebounce}
        // required={true}
        as="textarea"
        rows={3}
      />
      {validationError && (
        <Form.Text className={style.onError}>
          {t("Write Arabic only")}
        </Form.Text>
      )}
    </>
  );
};

const AddedFiles = ({ defaultValue }) => {
  const { t } = useTranslation("common");
  const { requestId, currentTaskData } = useSelector(
    (state) => state.service.updateRequest
  );
  const [uploadedFiles, setUploadedFiles] = useState(
    defaultValue ? defaultValue : []
  );
  const { clientProfiles } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [isUploading, setIsUploading] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  const [isEllipsis, setIsEllipsis] = useState(true);

  const onChange = async (e) => {
    var files = e.target.files[0];
    if (files) {
      const data = new FormData();
      data.append(
        "PARAMETERS",
        JSON.stringify({
          Client_Profile_Id: clientProfiles?.Client_Profile_Id,
          Request_Id: requestId,
          Task_Id: currentTaskData?.Task_Id,
        })
      );
      setIsUploading(true);
      data.append("Attached_File", e.target.files[0]);
      const response = await saveAdditionalRequestAttachmentData(
        data,
        handleProgress
      )
        .catch((err) => {
          dispatch(setError(err));
        })
        .finally(() => {
          setIsUploading(false);
          setProgressValue(0);
        });
      if (response?.Status) {
        setUploadedFiles((prev) => [...prev, response?.Data]);
      }
      e.target.value = null;
    }
  };

  const removeFile = async (f) => {
    const response = await deleteAdditionalRequestAttachment({
      Client_Profile_Id: clientProfiles?.Client_Profile_Id,
      Request_Id: requestId,
      Task_Id: currentTaskData?.Task_Id,
      Service_Detail_Id: f.Service_Detail_Id,
      File_Code: f.File_Code,
    });
    if (response?.Status) {
      setUploadedFiles((prev) => prev.filter((x) => x !== f));
    }
  };

  const download = async (f) => {
    await downloadAdditionalRequestAttachment({
      Client_Profile_Id: clientProfiles?.Client_Profile_Id,
      Request_Id: requestId,
      Task_Id: currentTaskData?.Task_Id,
      Service_Detail_Id: f.Service_Detail_Id,
      File_Code: f.File_Code,
    })
      .then(function (response) {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", f.File_Name);
        document.body.appendChild(link);
        link.click();
      })
      .catch((ex) => {
        console.error(ex);
      });
  };

  const handleProgress = (progress) => {
    var progressValue = (progress.loaded / progress.total) * 100;
    setProgressValue(Math.trunc(progressValue));
  };

  const handleToggleEllipsis = () => {
    setIsEllipsis(!isEllipsis);
  };

  return (
    <>
      <Col xs={12}>
        <Form.Label className={style.label}>{t("attachments")}</Form.Label>
        {isUploading && <ProgressBar value={progressValue} color="#58743A" />}
        <div className={style.fileContainer}>
          <div className={style.fileLabel}>
            <FontAwesomeIcon color={"#d3d3d3"} icon={faUpload} />
          </div>

          <Form.Control
            className={style.file}
            type="file"
            onChange={onChange}
          // required={!uploadedFiles?.length}
          />
          {uploadedFiles?.length > 0 && (
            <div className={style.files}>
              {uploadedFiles.map((uploadedFile) => (
                <div
                  key={uploadedFile.File_Code}
                  className="d-flex justify-content-between mb-1"
                >
                  <div className="d-flex">
                    {getFileIcon(uploadedFile.File_Content)}
                    <span
                      onClick={handleToggleEllipsis}
                      className={`${style.fileContent} ${isEllipsis ? style.clientRequestInfo : ""
                        } mx-1`}
                    >
                      {uploadedFile.File_Name}
                    </span>
                    <span className={`${style.fileContent} mx-1`}>
                      {uploadedFile.File_Size}
                    </span>
                  </div>

                  <div className="d-flex">
                    <FontAwesomeIcon
                      className="mx-1"
                      size="1x"
                      color={"#1e90ff"}
                      icon={faDownload}
                      onClick={() => download(uploadedFile)}
                    />
                    <FontAwesomeIcon
                      className="mx-1"
                      size="1x"
                      color={"#ff6347"}
                      icon={faTrashAlt}
                      onClick={() => removeFile(uploadedFile)}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </Col>

      {/* {isUploading && <LoadingOverlay />} */}
    </>
  );
};

const getFileIcon = (fileType) => {
  return (
    {
      "image/jpeg": <Jpg />,
      "image/png": <Png />,
      "video/mp4": <Mp4 />,
      "application/pdf": <Pdf />,
      "application/vnd.ms-powerpoint": <Doc />,
      "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        <Doc />,
      "application/msword": <Doc />,
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        <Doc />,
      "audio/mpeg": <Mp3 />,
      "application/zip": <Zip />,
      "text/csv": <Csv />,
      "text/plain": <Text />,
    }[fileType] ?? <Attachment />
  );
};
