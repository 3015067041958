import React, { useState, useEffect } from "react";
import style from "./ServicesCard.module.scss";
import { Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Pagination from "./Pagination/Pagination";
import ServicesList from "./ServicesList/ServicesList";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const ServicesCard = (props) => {
  const { t, i18n } = useTranslation("common");
  const history = useHistory();
  const [dealers, setDealers] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  // after search
  const [newServiceList, setNewServiceList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [servicesPerPage, setServicesPerPage] = useState(4);
  const [searchVal, setSearchVal] = useState("");
  const [activeTabId, setActiveTabId] = useState();
  let { municipalId } = useParams();
  const { user, clientProfiles } = useSelector((state) => state.user);

  useEffect(() => {
    const fetchDealersTypes = async () => {
      setLoading(true);
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/PORTAL/SERVICES/GET_DEALERS_TYPES_LIST`);
      setDealers(res.data.Data);
      setLoading(false);
    };
    fetchDealersTypes();
  }, []);

  useEffect(() => {
    if (clientProfiles?.Dealing_Type_Id) {
      if (dealers.length > 0) {
        if (props.homePage) {
          history.push(`/homePage/${clientProfiles?.Dealing_Type_Id}`);
        } else {
          history.push(`/municipal/${municipalId}/${clientProfiles?.Dealing_Type_Id}`);
        }
      }
      getServiceList(dealers.length > 0 && clientProfiles?.Dealing_Type_Id);
    } else {
      if (dealers.length > 0) {
        if (props.homePage) {
          history.push(`/homePage/${dealers[0].Dealing_Type_Id}`)
        } else {
          history.push(`/municipal/${municipalId}/${dealers[0].Dealing_Type_Id}`)
        }
      }
      getServiceList(dealers.length > 0 && dealers[0].Dealing_Type_Id)
    }
  }, [dealers]);

  // useEffect(() => {
  //   props.handleDimensions()
  //   props.handleAnimation()
  // }, [currentPage])

  useEffect(() => {
    if (window.innerWidth <= 767) {
      setServicesPerPage(3);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('activeDealerId', activeTabId)
  }, [activeTabId])

  const getServiceList = (id) => {
    if (id === false) return;
    const fetchserviceList = async () => {
      setLoading(true);
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/PORTAL/SERVICES/GET_SERVICES_LIST`, {
        Dealing_Type_Id: id,
        Department_Id: municipalId ? municipalId : 1,
      },
        user?.access_token ? {
          headers: {
            Authorization: `Bearer ${user?.access_token}`
          }
        } : false);
      setServiceList(res.data.Data);
      setNewServiceList(res.data.Data);
      setLoading(false);
    };
    fetchserviceList();
    setActiveTabId(id);
  };

  const onPrevPage = () => {
    if (currentPage > 1) {
      setTimeout(() => setCurrentPage(currentPage - 1), 1000);
    }
  };

  const onNextPage = () => {
    // if (currentPage < serviceList.length / servicesPerPage) {
    if (currentPage < newServiceList.length / servicesPerPage) {
      setTimeout(() => setCurrentPage(currentPage + 1), 1000);
    }
  };

  const indexOfLastPost = currentPage * servicesPerPage;
  const indexOfFirstPost = indexOfLastPost - servicesPerPage;
  const currentServices = newServiceList.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleServiceList = val => {
    let newServices = serviceList.filter(service => {
      if (i18n.language === "en") {
        if (service.Service_Name_EN.includes(val)) {
          return true;
        }
      } else {
        if (service.Service_Name_AR.includes(val)) {
          return true;
        }
      }
    })
    setNewServiceList(newServices)
    setCurrentPage(1)
  }

  return (
    <Row className={style.servicesCard} id='servicesCard'>
      <Col sm={4} className={[style.tabsContainer, i18n.language === "en" ? style.tabsContainerEn : style.tabsContainerAr].join(' ')}>
        <ul>
          {dealers.map((dealer) => (
            <li key={dealer.Dealing_Type_Id}>
              <NavLink
                to={`${clientProfiles?.Dealing_Type_Id ? clientProfiles?.Dealing_Type_Id : dealer.Dealing_Type_Id}`}
                onClick={() => getServiceList(!clientProfiles?.Dealing_Type_Id && dealer.Dealing_Type_Id)}
                activeClassName={
                  clientProfiles?.Dealing_Type_Id ?
                    clientProfiles?.Dealing_Type_Id === dealer.Dealing_Type_Id ? style.activeTab : style.disabledTab
                    :
                    style.activeTab
                }
              >
                <span className={i18n.language === "en" ? style.spanEn : style.spanAr}>
                  <img className={style.dealerImg} src={dealer.Dealing_Type_Logo_URL} />
                </span>
                <p>{i18n.language === "en" ? dealer.Dealing_Type_Name_EN : dealer.Dealing_Type_Name_AR}</p>
              </NavLink>
            </li>
          ))}
        </ul>
      </Col>

      <Col sm={8} className={[style.servicesListContainer, i18n.language === "en" ? style.servicesListContainerEn : style.servicesListContainerAr].join(' ')}>
        <Row>
          <Col xs={12} className={[style.searchInput, i18n.language === "en" ? style.searchInputEn : style.searchInputAr].join(" ")}>
            <Form>
              <Form.Control
                type="text"
                placeholder={t("search_for_anything")}
                onChange={(e) => {
                  setSearchVal(e.target.value)
                  handleServiceList(e.target.value)
                }}
                value={searchVal}
              />
              <span className={[style.searchIcon, i18n.language === "en" ? style.searchIconEn : style.searchIconAr].join(" ")}>
                <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                  <title>search-line</title>
                  <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="0" transform="translate(-584.000000, -222.000000)">
                      <g id="search-line" transform="translate(584.000000, 222.000000)">
                        <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                        <path
                          d="M18.031,16.617 L22.314,20.899 L20.899,22.314 L16.617,18.031 C15.0237382,19.3082211 13.0420011,20.0029052 11,20 C6.032,20 2,15.968 2,11 C2,6.032 6.032,2 11,2 C15.968,2 20,6.032 20,11 C20.0029052,13.0420011 19.3082211,15.0237382 18.031,16.617 Z M16.025,15.875 C17.2941129,14.5698857 18.002867,12.8204293 18,11 C18,7.132 14.867,4 11,4 C7.132,4 4,7.132 4,11 C4,14.867 7.132,18 11,18 C12.8204293,18.002867 14.5698857,17.2941129 15.875,16.025 L16.025,15.875 L16.025,15.875 Z"
                          id="Shape"
                          fill="#000000"
                          fillRule="nonzero"></path>
                      </g>
                    </g>
                  </g>
                </svg>
              </span>
            </Form>
          </Col>
        </Row>

        <div ref={props.servicesRef} className={[style.servicesCont].join(' ')}>
          <Row className='justify-content-start align-items-baseline px-2'>
            <ServicesList
              serviceList={currentServices}
              loading={loading}
              activeTabId={activeTabId}
              municipalId={municipalId}
            />
          </Row>
        </div>

        <Row className={[style.paginationCont, "align-items-center my-4"].join(' ')}>
          <Pagination servicesPerPage={servicesPerPage} totalServices={newServiceList.length} paginate={paginate} currentPage={currentPage} prevPage={onPrevPage} nextPage={onNextPage} />
        </Row>
      </Col>
    </Row>
  );
};

export default ServicesCard;
