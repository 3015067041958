import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getUpdateRequestInfo,
  getAdditionalRequestInfo,
  getInspectionInfo,
  getTradeName,
  submitUpdateRequest,
  requestAdditionDataInspectionVisitLocation,
} from "api/serviceAPI";
import axios from "axios";
import moment from "moment";

export const fetchUpdateRequestInfo = createAsyncThunk(
  "updateRequest/fetchUpdateRequestInfo",
  async (param, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await getUpdateRequestInfo(param, {
      cancelToken: source.token,
    });
    console.log(response)
    return response;
  }
);

export const fetchAdditionalRequestInfo = createAsyncThunk(
  "updateRequest/fetchAdditionalRequestInfo",
  async (param, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await getAdditionalRequestInfo(param, {
      cancelToken: source.token,
    });
    return response;
  }
);

export const fetchInspectionInfo = createAsyncThunk(
  "updateRequest/fetchInspectionInfo",
  async (param, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await getInspectionInfo(param, {
      cancelToken: source.token,
    });
    return response;
  }
);

export const fetchConfirmTradeName = createAsyncThunk(
  "updateRequest/fetchConfirmTradeName",
  async (param, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await getTradeName(param, {
      cancelToken: source.token,
    });
    return response;
  }
);

export const saveRequestAdditionDataInspectionVisitLocation = createAsyncThunk(
  "updateRequest/saveRequestAdditionDataInspectionVisitLocation",
  async (param, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await requestAdditionDataInspectionVisitLocation(param, {
      cancelToken: source.token,
    });
    return response;
  }
);

export const SubmitUpdateRequest = createAsyncThunk(
  "updateRequest/submitUpdateRequest",
  async (param, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await submitUpdateRequest(param, {
      cancelToken: source.token,
    });
    return response;
  }
);

export const SubmitAdditionalRequest = createAsyncThunk(
  "updateRequest/submitAdditionalRequest",
  async (param, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await submitUpdateRequest(param, {
      cancelToken: source.token,
    });
    return response;
  }
);

const slice = createSlice({
  name: "updateRequest",
  initialState: {
    loading: "idle",
    inputsData: null,
    inputsDataValue: [],
    attachmentsData: null,
    attachmentsDataValue: [],
    requiredChangesAttachments: null,
    requiredChangesNotes: null,
    oldRequiredChangesList: null,
    currentTaskData: null,
    taskId: null,
    serviceData: null,
    requestDate: null,
    requestId: null,
    error: null,
  },
  reducers: {
    taskSelected: (state, { payload }) => {
      state.taskId = payload;
    },
    setError: (state, { payload }) => {
      state.error = payload;
    },
    resetTask: (state) => {
      state.taskId = null;
    },
    resetRequest: (state) => {
      state.loading = "idle";
      state.inputsData = null;
      state.attachmentsData = null;
      state.serviceData = null;
      state.requestDate = null;
      state.requestData = null;
      state.requestId = null;
      state.taskId = null;
      state.requiredChangesAttachments = null;
      state.requiredChangesNotes = null;
      state.oldRequiredChangesList = null;
      state.currentTaskData = null;
      state.inputsDataValue = [];
      state.attachmentsDataValue = [];
      state.error = null;
    },
  },
  extraReducers: {
    [fetchUpdateRequestInfo.pending]: (state) => {
      if (state.loading === "idle") {
        state.loading = "pending";
      }
    },
    [fetchUpdateRequestInfo.fulfilled]: (state, { payload }) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        if (payload?.Status) {
          state.serviceData = payload?.Data?.Service_Data;
          state.inputsData = payload?.Data?.Inputs_Data;
          state.attachmentsData = payload?.Data?.Attachments_Data;
          state.requestDate = moment(
            payload?.Data?.Request_Data?.Request_Date
          ).format("YYYY-MM-DD");
          state.requestId = payload?.Data?.Request_Data?.Request_Id;
          state.requestData = {
            ...payload?.Data?.Request_Data,
            Inspection_User_Name_AR:
              payload?.Data?.Required_Changes_Data?.Inspection_User_Name_AR,
            Inspection_User_Name_EN:
              payload?.Data?.Required_Changes_Data?.Inspection_User_Name_EN,
          };
          state.requiredChangesAttachments =
            payload?.Data?.Required_Changes_Data?.Required_Changes_Attachments;
          state.requiredChangesNotes =
            payload?.Data?.Required_Changes_Data?.Required_Changes_Notes;
          state.currentTaskData = payload?.Data?.Current_Task_Data;
          state.inputsDataValue = payload?.Data?.Inputs_Data_Values;
          state.attachmentsDataValue = payload?.Data?.Attachments_Data_Values;
          state.formsData = payload?.Data?.Forms_Data;
        }
      }
    },
    [fetchUpdateRequestInfo.rejected]: (state, action) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        state.error = action.error;
      }
    },

    [fetchAdditionalRequestInfo.pending]: (state) => {
      if (state.loading === "idle") {
        state.loading = "pending";
      }
    },
    [fetchAdditionalRequestInfo.fulfilled]: (state, { payload }) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        if (payload?.Status) {
          state.serviceData = payload?.Data?.Service_Data;
          state.inputsData = payload?.Data?.Inputs_Data;
          state.attachmentsData = payload?.Data?.Attachments_Data;
          state.requestDate = moment(
            payload?.Data?.Request_Data?.Request_Date
          ).format("YYYY-MM-DD");
          state.requestId = payload?.Data?.Request_Data?.Request_Id;
          state.requestData = {
            ...payload?.Data?.Request_Data,
            Inspection_User_Name_AR:
              payload?.Data?.Required_Changes_Data?.Inspection_User_Name_AR,
            Inspection_User_Name_EN:
              payload?.Data?.Required_Changes_Data?.Inspection_User_Name_EN,
          };
          state.requiredChangesAttachments =
            payload?.Data?.Required_Changes_Data?.Required_Changes_Attachments;
          state.requiredChangesNotes =
            payload?.Data?.Required_Changes_Data?.Required_Changes_Notes;
          state.oldRequiredChangesList =
            payload?.Data?.Required_Changes_Data?.Old_Required_Changes_List;
          state.currentTaskData = payload?.Data?.Current_Task_Data;
          state.inputsDataValue = payload?.Data?.Inputs_Data_Values;
          state.attachmentsDataValue = payload?.Data?.Attachments_Data_Values;
        }
      }
    },
    [fetchAdditionalRequestInfo.rejected]: (state, action) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        state.error = action.error;
      }
    },

    [fetchInspectionInfo.pending]: (state) => {
      if (state.loading === "idle") {
        state.loading = "pending";
      }
    },
    [fetchInspectionInfo.fulfilled]: (state, { payload }) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        if (payload?.Status) {
          state.serviceData = payload?.Data?.Service_Data;
          state.inputsData = payload?.Data?.Inputs_Data;
          state.attachmentsData = payload?.Data?.Attachments_Data;
          state.requestDate = moment(
            payload?.Data?.Request_Data?.Request_Date
          ).format("YYYY-MM-DD");
          state.requestId = payload?.Data?.Request_Data?.Request_Id;
          state.requestData = {
            ...payload?.Data?.Request_Data,
            Inspection_User_Name_AR:
              payload?.Data?.Required_Changes_Data?.Inspection_User_Name_AR,
            Inspection_User_Name_EN:
              payload?.Data?.Required_Changes_Data?.Inspection_User_Name_EN,
          };
          state.requiredChangesAttachments =
            payload?.Data?.Required_Changes_Data?.Required_Changes_Attachments;
          state.requiredChangesNotes =
            payload?.Data?.Required_Changes_Data?.Required_Changes_Notes;
          state.currentTaskData = payload?.Data?.Current_Task_Data;
          state.inputsDataValue = payload?.Data?.Inputs_Data_Values;
          state.attachmentsDataValue = payload?.Data?.Attachments_Data_Values;
        }
      }
    },
    [fetchInspectionInfo.rejected]: (state, action) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        state.error = action.error;
      }
    },

    [fetchConfirmTradeName.pending]: (state) => {
      if (state.loading === "idle") {
        state.loading = "pending";
      }
    },
    [fetchConfirmTradeName.fulfilled]: (state, { payload }) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        if (payload?.Status) {
          state.serviceData = payload?.Data?.Service_Data;
          state.inputsData = payload?.Data?.Inputs_Data;
          state.attachmentsData = payload?.Data?.Attachments_Data;
          state.requestDate = moment(
            payload?.Data?.Request_Data?.Request_Date
          ).format("YYYY-MM-DD");
          state.requestId = payload?.Data?.Request_Data?.Request_Id;
          state.requestData = {
            ...payload?.Data?.Request_Data,
            Inspection_User_Name_AR:
              payload?.Data?.Required_Changes_Data?.Inspection_User_Name_AR,
            Inspection_User_Name_EN:
              payload?.Data?.Required_Changes_Data?.Inspection_User_Name_EN,
          };
          state.requiredChangesAttachments =
            payload?.Data?.Required_Changes_Data?.Required_Changes_Attachments;
          state.requiredChangesNotes =
            payload?.Data?.Required_Changes_Data?.Required_Changes_Notes;
          state.currentTaskData = payload?.Data?.Current_Task_Data;
          state.inputsDataValue = payload?.Data?.Inputs_Data_Values;
          state.attachmentsDataValue = payload?.Data?.Attachments_Data_Values;
        }
      }
    },
    [fetchConfirmTradeName.rejected]: (state, action) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        state.error = action.error;
      }
    },

    [saveRequestAdditionDataInspectionVisitLocation.pending]: (state) => {
      if (state.loading === "idle") {
        state.loading = "pending";
      }
    },
    [saveRequestAdditionDataInspectionVisitLocation.fulfilled]: (
      state,
      { payload }
    ) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        if (payload?.Status) {
          state.serviceData = payload?.Data?.Service_Data;
          state.inputsData = payload?.Data?.Inputs_Data;
          state.attachmentsData = payload?.Data?.Attachments_Data;
          state.requestDate = moment(
            payload?.Data?.Request_Data?.Request_Date
          ).format("YYYY-MM-DD");
          state.requestId = payload?.Data?.Request_Data?.Request_Id;
          state.requestData = {
            ...payload?.Data?.Request_Data,
            Inspection_User_Name_AR:
              payload?.Data?.Required_Changes_Data?.Inspection_User_Name_AR,
            Inspection_User_Name_EN:
              payload?.Data?.Required_Changes_Data?.Inspection_User_Name_EN,
          };
          state.requiredChangesAttachments =
            payload?.Data?.Required_Changes_Data?.Required_Changes_Attachments;
          state.requiredChangesNotes =
            payload?.Data?.Required_Changes_Data?.Required_Changes_Notes;
          state.currentTaskData = payload?.Data?.Current_Task_Data;
          state.inputsDataValue = payload?.Data?.Inputs_Data_Values;
          state.attachmentsDataValue = payload?.Data?.Attachments_Data_Values;
        }
      }
    },
    [saveRequestAdditionDataInspectionVisitLocation.rejected]: (
      state,
      action
    ) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        state.error = action.error;
      }
    },

    [SubmitUpdateRequest.pending]: (state) => {
      if (state.loading === "idle") {
        state.loading = "pending";
      }
    },
    [SubmitUpdateRequest.fulfilled]: (state, { payload }) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        if (payload?.Status) {
          state.serviceData = payload?.Data?.Service_Data;
          state.inputsData = payload?.Data?.Inputs_Data;
          state.attachmentsData = payload?.Data?.Attachments_Data;
          state.requestDate = moment(
            payload?.Data?.Request_Data.Request_Draft_Date
          )
            .lang("en")
            .format("YYYY-MM-DD");
          state.requestId = payload?.Data?.Request_Data.Request_Draft_Id;
        }
      }
    },
    [SubmitUpdateRequest.rejected]: (state, action) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        state.error = action.error;
      }
    },
  },
});
export default slice.reducer;

export const { taskSelected, setError, resetTask, resetRequest } =
  slice.actions;
