import React, { useState, useEffect } from 'react'
import { Container, Modal } from 'react-bootstrap'
import style from './PrivacyPolicyModal.module.scss'
import { useTranslation } from 'react-i18next'
import img from './../../../global/images/policyModal.png'
import { NavLink } from "react-router-dom";

const PrivacyPolicyModal = () => {

    const { t, i18n } = useTranslation('common')
    let state = sessionStorage.getItem('PolicyModal')
    const [isShowing, setIsShowing] = useState()

    useEffect(() => {
        if (state == 'false') {
            setIsShowing(false)
        } else if (state == null || undefined || true) {
            setIsShowing(true)
        }
    }, [])

    return (
        <Modal
            show={isShowing}
            onHide={() => {
                setIsShowing(false)
                sessionStorage.setItem('PolicyModal', false)
            }}
            className={style.modalContainer}
            style={{ zIndex: '999999999999' }}
        >
            <Modal.Header closeButton className={style.modalData}>
                <div className={style.contentCont}>
                    <div className={style.imageContainer}></div>

                    <div className={style.textContainer}>
                        <div>
                            <p>{t('PrivacyPolicyPara1')}</p>
                            <p>{t('PrivacyPolicyPara2')}</p>
                            <p>{t('PrivacyPolicyPara3')}</p>
                        </div>

                        <NavLink
                            exact
                            to="/privacy_policy"
                            className={[
                                style.launchUrBusinessBtn,
                                i18n.language === 'ar' ? style.launchUrBusinessBtnAr : null
                            ].join(' ')}
                            onClick={() => {
                                setIsShowing(false)
                                sessionStorage.setItem('PolicyModal', false)
                            }}
                        >
                            {t('privacyPolicy')}
                        </NavLink>
                    </div>
                </div>
            </Modal.Header>
        </Modal >
    )
}

export default PrivacyPolicyModal