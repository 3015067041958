import React, { useState, useEffect } from "react";
import InnerTitleCont from "./../../UIComponents/InnerTitleCont/InnerTitleCont";
import Header from "./../../UIComponents/Header/Header";
import Footer from "./../../UIComponents/Footer/Footer";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Tabs from "./Tabs";
import DecisionsList from "./DecisionsList";
import Pagination from "./Pagination";
import { NavLink } from "react-router-dom";

const DecisionsGroup = () => {
  const { t, i18n } = useTranslation("common");
  const [decisionsList, setDecisionsList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [noPerPage, setNoPerPage] = useState(20);
  const [searchVal, setSearchVal] = useState("");
  const [activeTab, setActiveTab] = useState("");

  useEffect(() => {
    getCategoriesList();
    getDecisionsList();
  }, [searchVal]);

  const getCategoriesList = async () => {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/PORTAL/DECISIONCATEGORIES/GET_DECISION_CATEGORIES_LIST`
    );
    setCategoriesList(res.data.Data);
  };

  const getDecisionsList = async (cat) => {
    let id = cat?.Decision_category_Id;
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/PORTAL/DECISIONCATEGORIES/GET_DECISIONS_ALL_LIST`,
      {
        Decision_Category_Id: id ? id : "",
        PageIndex: 1,
        PageCount: 100,
        Search_Text: searchVal ? searchVal.Search_Text : "",
        Decision_Code: searchVal ? searchVal.Decision_Code : "",
        from_date: searchVal ? searchVal.from_date : "",
        to_date: searchVal ? searchVal.to_date : "",
      }
    );
    setDecisionsList(res.data.Data.Data);
    setActiveTab(cat);
  };

  const getSearchVal = (values) => setSearchVal(values);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const indexOfLastPost = currentPage * noPerPage;
  const indexOfFirstPost = indexOfLastPost - noPerPage;
  const currentDecisions = decisionsList.slice(
    indexOfFirstPost,
    indexOfLastPost
  );

  return (
    <>
      <Header />

      <InnerTitleCont
        breadCrumbs={<NavLink to="/homePage">{t("menu_home")}</NavLink>}
        title={t("Resolutions and circulars")}
        startBtn={false}
        leaveBtn={false}
      />

      <Container fluid="lg" className="pageContainer">
        <Row className="mb-5">
          <Col md={4}>
            <div className="gCard">
              <Tabs
                categoriesList={categoriesList}
                getDecisionsList={getDecisionsList}
                activeTab={activeTab}
              />
            </div>
          </Col>

          <Col md={8}>
            <div className="gCard">
              <DecisionsList
                decisionsList={decisionsList}
                activeTab={activeTab}
                getSearchVal={getSearchVal}
              />
            </div>

            <Row className="mt-5">
              <Pagination
                noPerPage={noPerPage}
                totalDecision={decisionsList.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            </Row>
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
};

export default DecisionsGroup;
