import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import LoadingOverlay from "components/UIComponents/LoadingOverlay";
import BlankIMG1 from "./../../../global/images/Links-BlankIMGs-01.svg";
import BlankIMG2 from "./../../../global/images/Links-BlankIMGs-02.svg";
import BlankIMG3 from "./../../../global/images/Links-BlankIMGs-03.svg";
import BlankIMG4 from "./../../../global/images/Links-BlankIMGs-04.svg";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import style from "./ShortLinks.module.scss";
import Header from "./../../UIComponents/Header/Header";
import Footer from "./../../UIComponents/Footer/Footer";
import { Container, Row, Col } from "react-bootstrap";
import InnerTitleCont from "./../../UIComponents/InnerTitleCont/InnerTitleCont";
import { NavLink } from "react-router-dom";

const ShortLinks = () => {
    const { t, i18n } = useTranslation('common');
    const { user } = useSelector((state) => state.user);
    const { linkCode } = useParams();
    const history = useHistory();
    const [data, setData] = useState();
    const [activeImg, setActiveImg] = useState();

    useEffect(() => {
        getShortLinks()
    }, [])

    useEffect(() => {
        let activeImg
        if (data?.Link_Status_Id == 0) {
            activeImg = BlankIMG4
        } else if (data?.Link_Status_Id == 1) {
            activeImg = BlankIMG2
        } else if (data?.Link_Status_Id == 3) {
            activeImg = BlankIMG3
        } else if (data?.Link_Status_Id == 4) {
            activeImg = BlankIMG1
        }
        setActiveImg(activeImg)

        if (data?.Link_URL) {
            setTimeout(() => {
                window.location.href = data?.Link_URL
            }, 1000)
        }
    }, [data])

    const getShortLinks = async () => {
        const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/SYSTEM/GENERAL/Get_Short_Link`,
            {
                Link_Code: linkCode
            },
            {
                headers: {
                    Authorization: `Bearer ${user?.access_token}`,
                }
            }
        );
        setData(res.data.Data);
    };

    return (
        <>
            <Header />

            <InnerTitleCont
                // breadCrumbs={
                //     <>
                //         <NavLink to="/homePage">
                //             <span>
                //                 <svg
                //                     width="12"
                //                     height="12"
                //                     viewBox="0 0 12 12"
                //                     fill="none"
                //                     xmlns="http://www.w3.org/2000/svg"
                //                 >
                //                     <path
                //                         d="M10.6893 2.97833L7.32932 0.6275C6.41349 -0.0141667 5.00766 0.0208334 4.12682 0.703333L1.20432 2.98417C0.62099 3.43917 0.160156 4.3725 0.160156 5.1075V9.1325C0.160156 10.62 1.36766 11.8333 2.85516 11.8333H9.14349C10.631 11.8333 11.8385 10.6258 11.8385 9.13833V5.18333C11.8385 4.39583 11.331 3.4275 10.6893 2.97833ZM6.43682 9.5C6.43682 9.73917 6.23849 9.9375 5.99932 9.9375C5.76016 9.9375 5.56182 9.73917 5.56182 9.5V7.75C5.56182 7.51083 5.76016 7.3125 5.99932 7.3125C6.23849 7.3125 6.43682 7.51083 6.43682 7.75V9.5Z"
                //                         fill="white"
                //                     />
                //                 </svg>
                //             </span>
                //         </NavLink>

                //         <span>
                //             <svg
                //                 style={{
                //                     margin: "0 8px",
                //                     transform:
                //                         i18n.language == "ar" && "scale(-1)",
                //                 }}
                //                 width="6"
                //                 height="10"
                //                 viewBox="0 0 6 10"
                //                 fill="none"
                //                 xmlns="http://www.w3.org/2000/svg"
                //             >
                //                 <path
                //                     d="M1 1L5 5L1 9"
                //                     stroke="white"
                //                     strokeWidth="1.5"
                //                     strokeLinecap="round"
                //                     strokeLinejoin="round"
                //                 />
                //             </svg>
                //         </span>
                //         <span>{t("common:Client Porfile")}</span>
                //     </>
                // }
                // title={t("common:Client Porfile")}
                startBtn={false}
                leaveBtn={false}
            />

            <Container fluid="lg" className={style.shortLinksContainer}>
                {!data ?
                    <>
                        <p className={style.pendingMsg}>{t('The requested link is being verified')}</p>
                        <LoadingOverlay />
                    </>
                    : <div className={style.dataBox}>
                        <img className={style.statusImage} src={activeImg} />
                        <p className={style.statusMsg}>{i18n.language == 'ar' ? data?.Link_Message_AR : data?.Link_Message_EN}</p>
                        {data?.Link_URL && <>
                            <p className={style.browserHint}>{t('short links browser hint')}</p>
                            <a className={style.link} href={data?.Link_URL}>{t('click here')}</a>
                        </>}
                    </div>
                }
            </Container>

            <Footer />
        </>
    )
}

export default ShortLinks