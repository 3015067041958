import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import style from "./Request.module.scss";
import { useSelector } from "react-redux";
import Header from "components/UIComponents/Header/Header";
import InnerTitleCont from "components/UIComponents/InnerTitleCont/InnterTitleState";
import Footer from "components/UIComponents/Footer/Footer";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ServiceMunicipal from "components/UIComponents/Municipal/ServiceMunicipal";
import moment from "moment";
import { Accordion, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { downloadAttachment } from "api/serviceAPI";
import {
  Attachment,
  Csv,
  Doc,
  Jpg,
  Mp3,
  Mp4,
  Pdf,
  Png,
  Text,
  Zip,
} from "components/UIComponents/FileIcons";
import Grid from "./../../components/UIComponents/Grid/index";
import { Column } from "devextreme-react/data-grid";
import { useHistory } from "react-router-dom";
import {
  GoogleMap,
  LoadScript,
  Marker,
  useLoadScript,
  useJsApiLoader,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import styleNew from "./NewRequest.module.scss";
import { NavLink } from "react-router-dom";

const ClientRequestInfo = () => {
  const { t, i18n } = useTranslation("common");
  const { user, clientProfiles } = useSelector((state) => state.user);
  const { requestId } = useParams();
  const history = useHistory();
  const [data, setData] = useState();
  const [stepsObj, setStepsObj] = useState({});
  const [activeAcc, setActiveAcc] = useState();
  const [showForm, setShowForm] = useState();
  const [inputsDataList, setInputsDataList] = useState([])
  const [isTextExpanded, setIsTextExpanded] = useState({})

  const stepsNo = [1, 2, 3, 4, 5];
  let steps = [];
  let status = "Invoice_Status_Name_EN";
  if (i18n.language === "ar") {
    status = "Invoice_Status_Name_AR";
  }
  const [mapState, setMapState] = useState({
    position: {
      lat: 0,
      lng: 0,
    },
    center: {
      lat: 0,
      lng: 0,
    },
  });
  let zoom = 12;
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCBx-BN5uLwmFezDw0NeQs9xp_UcplHIzs",
    libraries: ["places"],
  });
  // const TASK_SUPPORT_TYPE_ID = {
  //     4: "1",
  //     5: "2",
  //     15: "3",
  // };

  useEffect(() => {
    getClientRequestInfo();
  }, []);

  //Request_Status_Id == 3 completed
  //Request_Status_Id == 2 The Request Is Being Processed
  //Request_Status_Id == 1 New Request
  //Request_Status_Id == 0 Rejected
  useEffect(() => {
    setShowForm(
      data?.Request_Info?.Request_Status_Id == 0 || data?.Request_Info?.Request_Status_Id == 1 ||
        data?.Request_Info?.Request_Status_Id == 2 || data?.Request_Info?.Request_Status_Id == 3 ?
        true : false
    )
    if (data?.Requet_Inputs_Info?.length > 0) steps.push("notes");
    if (data?.Requet_Attachments_Info?.length > 0) steps.push("attachments");
    if (data?.Requet_Invoices_Info?.length > 0) steps.push("invoices");
    if (data?.Requet_Results_Info?.length > 0) steps.push("results");
    if (data?.Visit_Data_Info !== null) steps.push("inspectionData");
    if (data?.Requet_Forms_Info !== null) steps.push("formsInfo");
    if (data?.Requet_Inputs_Info !== null) steps.push("inputsInfo");
    setStepsObj(steps.reduce((obj, steps, index) => ({ ...obj, [steps]: stepsNo[index] }), {}))
  }, [data]);

  useEffect(() => {
    let inputsArray = [];
    let inputsIsExpandedObject = {};

    data?.Requet_Inputs_Info?.map((ele) => {
      let typeId = ele?.Input_Type;
      let title = i18n.language == "ar" ? ele?.Input_Title_AR : ele?.Input_Title_EN;
      let stepNum = ele?.Department_Service_Detail_Form_Id;
      let inputNo = ele?.Input_No;
      let value
      if (typeId != 3) {
        // value = ele?.Input_Value;
        value = i18n.language == "ar" ? ele?.Input_Value_AR : ele?.Input_Value_EN;
      } else {
        value = ele?.Att_Files;
      }

      inputsIsExpandedObject[title] = false;
      inputsArray.push({ inputNo: inputNo, stepNum: stepNum, title: title, typeId: typeId, value: value });
      inputsArray.sort(({ inputNo: a }, { inputNo: b }) => a - b);
      return inputsArray
    });

    setIsTextExpanded({ ...inputsIsExpandedObject });
    setInputsDataList(inputsArray);
  }, [data]);

  const getClientRequestInfo = async () => {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/PORTAL/CLIENTS/REQUESTS/GET_CLIENT_REQUEST_INFO`,
      {
        Client_Profile_Id: clientProfiles?.Client_Profile_Id,
        Request_Id: requestId,
        Info_Type: "0",
      },
      {
        headers: {
          Authorization: `Bearer ${user?.access_token}`,
        },
      }
    );
    setData(res.data.Data);
  };

  const download = async (f) => {
    await downloadAttachment({
      File_Code: f.File_Code,
    })
      .then(function (response) {
        console.log(response)
        const blob = new Blob([response]);
        console.log(blob)

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", f.File_Name);
        // document.body.appendChild(link);
        link.click();
      })
      .catch((ex) => {
        console.error(ex);
      });
  };

  const handleExpandLabel = (title) => {
    setIsTextExpanded((prev) => ({ ...prev, [title]: !prev[title] }));
  };

  const mapUploadedFiles = (files = [], title) => {
    return files.map((uploadedFile) => (
      <div
        key={uploadedFile.File_Code}
        className={[style.fileDetails, "d-flex justify-content-between my-1"].join(' ')}
      >
        <div className="d-flex flex-wrap justify-content-between  w-100">
          <div style={{ width: 'calc(100% - 50px)' }}>
            {getFileIcon(uploadedFile.File_Type)}
            <span
              onClick={() => handleExpandLabel(title)}
              style={{ color: "black", width: '90%' }}
              className={`${styleNew.fileContent}  ${!isTextExpanded[title] ? styleNew.clientRequestInfo : ""
                } mx-1`}
            >
              {uploadedFile.File_Name}
            </span>
          </div>
          <div className="mx-1">
            <span onClick={() => download(uploadedFile)}>
              <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.738281 13.1786V14.3725C0.738281 15.0057 0.989848 15.613 1.43764 16.0608C1.88543 16.5087 2.49277 16.7602 3.12604 16.7602H13.8709C14.5042 16.7602 15.1115 16.5087 15.5593 16.0608C16.0071 15.613 16.2587 15.0057 16.2587 14.3725V13.1786" stroke="#1B2124" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M5.51562 9L8.50032 11.9847L11.485 9" stroke="#1B2124" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8.5 11.9847V1.23981" stroke="#1B2124" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </span>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <>
      <Header />

      {data ? (
        <>
          <InnerTitleCont
            breadCrumbs={
              <>
                <NavLink to="/homePage">
                  <span>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.6893 2.97833L7.32932 0.6275C6.41349 -0.0141667 5.00766 0.0208334 4.12682 0.703333L1.20432 2.98417C0.62099 3.43917 0.160156 4.3725 0.160156 5.1075V9.1325C0.160156 10.62 1.36766 11.8333 2.85516 11.8333H9.14349C10.631 11.8333 11.8385 10.6258 11.8385 9.13833V5.18333C11.8385 4.39583 11.331 3.4275 10.6893 2.97833ZM6.43682 9.5C6.43682 9.73917 6.23849 9.9375 5.99932 9.9375C5.76016 9.9375 5.56182 9.73917 5.56182 9.5V7.75C5.56182 7.51083 5.76016 7.3125 5.99932 7.3125C6.23849 7.3125 6.43682 7.51083 6.43682 7.75V9.5Z" fill="white" />
                    </svg>
                  </span>
                </NavLink>

                <span>
                  <svg
                    style={{
                      margin: '0 8px',
                      transform: i18n.language == 'ar' && 'scale(-1)'
                    }}
                    width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L5 5L1 9" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </span>

                <span>
                  {t("request_service_data")}
                </span>
              </>
            }
            title={`
              ${data?.Request_Info?.Service_Code}/${i18n.language === "en"
                ? data?.Request_Info?.Service_Name_EN
                : data?.Request_Info?.Service_Name_AR}/${data?.Request_Info?.Department_Service_Version_Name}
              <br/>
              ${data?.Request_Info?.Request_No}
            `}
            permissionId={{ dealingTypeId: clientProfiles?.Dealing_Type_Id }}
            startBtn={false}
            leaveBtn={true}
            // startTrigger={isAuthenticated ? handleRequestStart : modalShow}
            leaveTrigger={() => window.history.back()}
          />

          <Container fluid="lg" className="pageContainer">
            <Row className="mb-5">
              <Col md={8}>
                <div className="gCard">
                  <Row className="align-items-center position-relative p-md-0 p-2">
                    <Col xs={6}>
                      <p className={style.title}>{t("request_service_info")}</p>
                    </Col>

                    <Col xs={6} className="d-flex justify-content-end mb-2">
                      <button
                        className="mainBtn primarymainBtn smBtn"
                        onClick={() => window.history.back()}
                      >
                        {t("close")}
                      </button>
                    </Col>
                  </Row>

                  <Row className="align-items-center position-relative">
                    <Col xs={12} className={style.detailsContent}>
                      <Row>
                        <Col md={6} xs={12}>
                          <span className={style.details}>
                            {t("Request Number")}
                          </span>
                          <span className={[style.details, 'mx-2 mb-2'].join(' ')}>
                            {data?.Request_Info?.Request_No}
                          </span>
                        </Col>

                        <Col md={6} xs={12}>
                          <span className={style.details}>
                            {t("Request Date")}:
                          </span>
                          <span className={[style.details, 'mx-2 mb-2'].join(' ')}>
                            {data?.Request_Info?.Request_Date &&
                              moment(data?.Request_Info?.Request_Date)
                                .lang("en")
                                .format("YYYY-MM-DD")}
                          </span>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={6} xs={12}>
                          <span className={style.details}>
                            {t("service_name")}:
                          </span>
                          <span className={[style.details, 'mx-2 mb-2'].join(' ')}>
                            {i18n.language === "ar"
                              ? data?.Request_Info?.Service_Name_AR
                              : data?.Request_Info?.Service_Name_EN}
                          </span>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={6} xs={12}>
                          <span className={style.details}>
                            {t("department_name")}:
                          </span>
                          <span className={[style.details, 'mx-2 mb-2'].join(' ')}>
                            {i18n.language === "ar"
                              ? data?.Request_Info?.Department_Name_AR
                              : data?.Request_Info?.Department_Name_EN}
                          </span>
                        </Col>

                        <Col md={6} xs={12}>
                          <span className={style.details}>
                            {t("dealing_type")}:
                          </span>
                          <span className={[style.details, 'mx-2 mb-2'].join(' ')}>
                            {i18n.language === "ar"
                              ? data?.Request_Info?.Dealing_Type_Name_AR
                              : data?.Request_Info?.Dealing_Type_Name_EN}
                          </span>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={6} xs={12}>
                          <span className={style.details}>
                            {t("Service Version")}
                          </span>
                          <span className={[style.details, 'mx-2 mb-2'].join(' ')}>
                            {
                              data?.Request_Info
                                ?.Department_Service_Version_Name
                            }
                          </span>
                        </Col>

                        <Col md={6} xs={12}>
                          <span className={style.details}>
                            {t("service_code")}:
                          </span>
                          <span className={[style.details, 'mx-2 mb-2'].join(' ')}>
                            {data?.Request_Info?.Service_Code}
                          </span>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={6} xs={12}>
                          <span className={style.details}>
                            {t("request_status")}:
                          </span>
                          <span className={[style.details, 'mx-2 mb-2'].join(' ')}>
                            {i18n.language === "ar"
                              ? data?.Request_Info?.Request_Status_Name_AR
                              : data?.Request_Info?.Request_Status_Name_EN}
                          </span>
                        </Col>

                        <Col md={6} xs={12}>
                          <span className={style.details}>
                            {t("Service Category")}:
                          </span>
                          <span className={[style.details, 'mx-2 mb-2'].join(' ')}>
                            {i18n.language === "ar"
                              ? data?.Request_Info?.Service_Category_Name_AR
                              : data?.Request_Info?.Service_Category_Name_EN}
                          </span>
                        </Col>
                      </Row>

                      <Row>
                        {data?.Request_Info?.Department_Service_Step_Name_AR ||
                          data?.Request_Info?.Department_Service_Step_Name_EN ? (
                          <Col md={6} xs={12}>
                            <span className={style.details}>
                              {t("current_request_status")}:
                            </span>
                            <span className={[style.details, 'mx-2 mb-2'].join(' ')}>
                              {i18n.language === "ar"
                                ? data?.Request_Info
                                  ?.Department_Service_Step_Name_AR
                                : data?.Request_Info
                                  ?.Department_Service_Step_Name_EN}
                            </span>
                          </Col>
                        ) : null}

                        {data?.Request_Info?.End_Request_Date ? (
                          <Col md={6} xs={12}>
                            <span className={style.details}>
                              {t("end_request_date")}:
                            </span>
                            <span className={[style.details, 'mx-2 mb-2'].join(' ')}>
                              {data?.Request_Info?.End_Request_Date &&
                                moment(data?.Request_Info?.End_Request_Date)
                                  .lang("en")
                                  .format("YYYY-MM-DD")}
                            </span>
                          </Col>
                        ) : null}

                        {data?.Request_Info?.Inspection_Time ? (
                          <Col md={6} xs={12}>
                            <span className={style.details}>
                              {t("Inspection_date")}:
                            </span>
                            <span className={[style.details, 'mx-2 mb-2'].join(' ')}>
                              {data?.Request_Info?.Inspection_Time &&
                                moment(data?.Request_Info?.Inspection_Time)
                                  .lang("en")
                                  .format("YYYY-MM-DD")}
                            </span>
                          </Col>
                        ) : null}

                        {data?.Request_Info?.Request_Reject_Reasons ? (
                          <Col md={6} xs={12}>
                            <span className={style.details}>
                              {t("request_reject_reasons")}:
                            </span>
                            <span className={[style.details, 'mx-2 mb-2'].join(' ')}>
                              {data?.Request_Info?.Request_Reject_Reasons}
                            </span>
                          </Col>
                        ) : null}
                      </Row>
                    </Col>
                  </Row>

                  {showForm ?
                    <>
                      {
                        stepsObj["formsInfo"] ? (
                          <>
                            {data?.Requet_Forms_Info.map((form, index) => (
                              <Row>
                                <Col>
                                  {inputsDataList.filter(input => input.stepNum == form.Department_Service_Detail_Form_Id).length != 0 ? (
                                    <Accordion activeKey={activeAcc}>
                                      <Card className={style.accCard}>
                                        <Card.Header className={style.accHeader}>
                                          <Accordion.Toggle
                                            eventKey={activeAcc}
                                            className={style.accBtn}
                                            onClick={() => {
                                              if (activeAcc == index + 1) {
                                                setActiveAcc(0)
                                              } else {
                                                setActiveAcc(index + 1)
                                              }
                                            }}
                                          >
                                            <Row className="align-items-center position-relative">
                                              <Col
                                                xs={6}
                                                className={[style.step, "mb-0"].join(" ")}
                                              >
                                                <div className={style.stepHeader}>
                                                  <span className={style.number}>-
                                                    {/* {index + 1} */}
                                                  </span>
                                                  <p className={style.stepTitle}>
                                                    {i18n.language == 'ar' ? form.Department_Service_Detail_Form_Title_AR : form.Department_Service_Detail_Form_Title_EN}
                                                  </p>
                                                </div>
                                              </Col>

                                              <Col
                                                xs={6}
                                                className="d-flex justify-content-end mb-2"
                                              >
                                                <span
                                                  className={[
                                                    style.accArrow,
                                                    activeAcc == index + 1
                                                      ? style.upArrow
                                                      : null,
                                                  ].join(" ")}
                                                >
                                                  <svg
                                                    version="1.1"
                                                    id="Capa_1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    x="0px"
                                                    y="0px"
                                                    width="451.847px"
                                                    height="451.847px"
                                                    viewBox="0 0 451.847 451.847"
                                                    xmlSpace="preserve"
                                                  >
                                                    <g>
                                                      <path
                                                        d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751
                                                                                        c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0
                                                                                        c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"
                                                      />
                                                    </g>
                                                  </svg>
                                                </span>
                                              </Col>
                                            </Row>
                                          </Accordion.Toggle>
                                        </Card.Header>

                                        <Accordion.Collapse eventKey={activeAcc}>
                                          <Row className="align-items-center position-relative">
                                            <Card.Body className="pt-0">
                                              <Col xs={12}>
                                                {activeAcc == index + 1 ? (
                                                  <Row className={[style.detailsContent, 'pt-3 px-0'].join(' ')}>

                                                    {/* File Uploader Data */}
                                                    {inputsDataList.length > 0 && inputsDataList.filter(input => input.stepNum == form.Department_Service_Detail_Form_Id)
                                                      .slice(
                                                        inputsDataList.length % 2 !== 0
                                                          ? inputsDataList.length / 2 + 1
                                                          : inputsDataList.length / 2,
                                                        inputsDataList.length
                                                      )
                                                      .map((ele) => {
                                                        if (ele.typeId != 3) {
                                                          return (
                                                            <Col md={6} className="mb-4 d-flex flex-column">
                                                              <div
                                                                onClick={() =>
                                                                  handleExpandLabel(ele.title)
                                                                }
                                                                className={
                                                                  isTextExpanded[ele.title]
                                                                    ? styleNew.textLabel
                                                                    : styleNew.textExpandedLabel
                                                                }
                                                                style={{ fontWeight: 'bold', fontSize: '14px', color: '#00000066' }}
                                                              >
                                                                {ele.title}
                                                              </div>
                                                              <div
                                                                onClick={() =>
                                                                  handleExpandLabel(ele.title)
                                                                }
                                                                className={
                                                                  isTextExpanded[ele.title]
                                                                    ? styleNew.textLabel
                                                                    : styleNew.textExpandedLabel
                                                                }
                                                                style={{ border: '1px solid #bbbfc2', borderRadius: '5px', padding: '3px 5px', marginTop: '2px', minHeight: '30px' }}                                                              >
                                                                {ele.value}
                                                              </div>
                                                            </Col>
                                                          )
                                                        } else {
                                                          return (
                                                            <Col md={6} className="mb-4 d-flex flex-column">
                                                              <div
                                                                className={
                                                                  isTextExpanded[ele.title]
                                                                    ? styleNew.textLabel
                                                                    : styleNew.textExpandedLabel
                                                                }
                                                                style={{ fontWeight: 'bold', fontSize: '14px', color: '#00000066' }}
                                                              >
                                                                {ele.title}
                                                              </div>
                                                              <div className="flex-column">
                                                                <>
                                                                  {mapUploadedFiles(
                                                                    ele.value,
                                                                    ele.title
                                                                  )}
                                                                </>
                                                              </div>
                                                            </Col>
                                                          )
                                                        }
                                                      })
                                                    }

                                                    {/* Inputs Data */}
                                                    {inputsDataList.length > 0 && inputsDataList.filter(input => input.stepNum == form.Department_Service_Detail_Form_Id)
                                                      .slice(
                                                        0,
                                                        inputsDataList.length % 2 !== 0
                                                          ? inputsDataList.length / 2 + 1
                                                          : inputsDataList.length / 2
                                                      )
                                                      .map((ele) => {
                                                        if (ele.typeId != 3) {
                                                          return (
                                                            <Col md={6} className="mb-4 d-flex flex-column">
                                                              <div
                                                                style={{ fontWeight: 'bold', fontSize: '14px', color: '#00000066' }}
                                                                onClick={() =>
                                                                  handleExpandLabel(ele.title)
                                                                }
                                                                className={
                                                                  isTextExpanded[ele.title]
                                                                    ? styleNew.textLabel
                                                                    : styleNew.textExpandedLabel
                                                                }
                                                              >
                                                                {ele.title}
                                                              </div>
                                                              <div
                                                                onClick={() =>
                                                                  handleExpandLabel(ele.title)
                                                                }
                                                                className={
                                                                  isTextExpanded[ele.title]
                                                                    ? styleNew.textLabel
                                                                    : styleNew.textExpandedLabel
                                                                }
                                                                style={{ border: '1px solid #bbbfc2', borderRadius: '5px', padding: '3px 5px', marginTop: '2px', minHeight: '30px' }}
                                                              >
                                                                {ele.value}
                                                              </div>
                                                            </Col>
                                                          )
                                                        } else {
                                                          return (
                                                            <Col md={6} className="mb-4 d-flex flex-column">
                                                              <div
                                                                className={
                                                                  isTextExpanded[ele.title]
                                                                    ? styleNew.textLabel
                                                                    : styleNew.textExpandedLabel
                                                                }
                                                                style={{ fontWeight: 'bold', fontSize: '14px', color: '#00000066' }}
                                                              >
                                                                {ele.title}
                                                              </div>
                                                              <div className="flex-column">
                                                                {mapUploadedFiles(
                                                                  ele.value,
                                                                  ele.title
                                                                )}
                                                              </div>
                                                            </Col>
                                                          )
                                                        }
                                                      })
                                                    }
                                                  </Row>
                                                ) : null}
                                              </Col>
                                            </Card.Body>
                                          </Row>
                                        </Accordion.Collapse>
                                      </Card>
                                    </Accordion>
                                  ) : null}
                                </Col>
                              </Row>
                            ))}
                          </>
                        ) : null
                      }
                    </>
                    : null
                  }

                  {stepsObj["invoices"] ? (
                    <Row>
                      <Col>
                        <Accordion activeKey={activeAcc}>
                          <Card className={style.accCard}>
                            <Card.Header className={style.accHeader}>
                              <Accordion.Toggle
                                eventKey={activeAcc}
                                className={style.accBtn}
                                onClick={() => {
                                  if (activeAcc == 100) {
                                    setActiveAcc(0)
                                  } else {
                                    setActiveAcc(100)
                                  }
                                }}
                              >
                                <Row className="align-items-center position-relative">
                                  <Col
                                    xs={6}
                                    className={[style.step, "mb-0"].join(" ")}
                                  >
                                    <div className={style.stepHeader}>
                                      <span className={style.number}>-</span>
                                      <p className={style.stepTitle}>
                                        {t("service_request_invoices")}
                                      </p>
                                    </div>
                                  </Col>

                                  <Col
                                    xs={6}
                                    className="d-flex justify-content-end mb-2"
                                  >
                                    <span
                                      className={[
                                        style.accArrow,
                                        activeAcc == 100
                                          ? style.upArrow
                                          : null,
                                      ].join(" ")}
                                    >
                                      <svg
                                        version="1.1"
                                        id="Capa_1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        x="0px"
                                        y="0px"
                                        width="451.847px"
                                        height="451.847px"
                                        viewBox="0 0 451.847 451.847"
                                        xmlSpace="preserve"
                                      >
                                        <g>
                                          <path
                                            d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751
                                                                                      c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0
                                                                                      c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"
                                          />
                                        </g>
                                      </svg>
                                    </span>
                                  </Col>
                                </Row>
                              </Accordion.Toggle>
                            </Card.Header>

                            <Accordion.Collapse eventKey={activeAcc}>
                              <Row className="align-items-center position-relative">
                                <Card.Body className="p-0">
                                  <Col xs={8} sm={12}>
                                    {activeAcc == 100 && (
                                      <div>
                                        <Grid
                                          // columnHidingEnabled={true}
                                          sourceData={data?.Requet_Invoices_Info}
                                          deleteEnabled={false}
                                          viewEnabled={true}
                                          editEnabled={false}
                                          onView={({ data }) => {
                                            history.push({
                                              pathname: `/payments-view/${data?.Invoice_Id}`,
                                              state: { data: data },
                                            });
                                          }}

                                        >
                                          <Column
                                            caption={t("payment_no")}
                                            alignment="center"
                                            dataField="Payment_No"
                                            dataType="number"
                                            width={180}
                                          />
                                          <Column
                                            caption={t("date")}
                                            alignment="center"
                                            dataField="Invoice_Date"
                                            dataType="date"
                                            format="yyyy-MM-dd"
                                            width={100}
                                          />
                                          <Column
                                            caption={t("request_status")}
                                            alignment="center"
                                            dataField={status}
                                            dataType="string"
                                            width={100}
                                          />
                                          <Column
                                            caption={t(
                                              "invoice_detail_total_value"
                                            )}
                                            alignment="center"
                                            dataField="Invoice_Value"
                                            dataType="string"
                                          />
                                          <Column
                                            caption={t("invoice_payer")}
                                            alignment="center"
                                            dataField="Invoice_Payer_Name"
                                            dataType="string"
                                          />
                                        </Grid>
                                      </div>
                                    )}
                                  </Col>
                                </Card.Body>
                              </Row>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      </Col>
                    </Row>
                  ) : null}

                  {stepsObj["results"] &&
                    data.Request_Info.Request_Status_Id !== 0 ? (
                    <Row>
                      <Col>
                        <Accordion activeKey={activeAcc}>
                          <Card className={style.accCard}>
                            <Card.Header className={style.accHeader}>
                              <Accordion.Toggle
                                eventKey={activeAcc}
                                className={style.accBtn}
                                onClick={() => {
                                  if (activeAcc == 200) {
                                    setActiveAcc(0)
                                  } else {
                                    setActiveAcc(200)
                                  }
                                }}
                              >
                                <Row className="align-items-center position-relative">
                                  <Col
                                    xs={6}
                                    className={[style.step, "mb-0"].join(" ")}
                                  >
                                    <div className={style.stepHeader}>
                                      <span className={style.number}>-</span>
                                      <p className={style.stepTitle}>
                                        {t("service_request_results")}
                                      </p>
                                    </div>
                                  </Col>

                                  <Col
                                    xs={6}
                                    className="d-flex justify-content-end mb-2"
                                  >
                                    <span
                                      className={[
                                        style.accArrow,
                                        activeAcc == 200
                                          ? style.upArrow
                                          : null,
                                      ].join(" ")}
                                    >
                                      <svg
                                        version="1.1"
                                        id="Capa_1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        x="0px"
                                        y="0px"
                                        width="451.847px"
                                        height="451.847px"
                                        viewBox="0 0 451.847 451.847"
                                        xmlSpace="preserve"
                                      >
                                        <g>
                                          <path
                                            d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751
                                                                                      c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0
                                                                                      c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"
                                          />
                                        </g>
                                      </svg>
                                    </span>
                                  </Col>
                                </Row>
                              </Accordion.Toggle>
                            </Card.Header>

                            <Accordion.Collapse eventKey={activeAcc}>
                              <Row className="align-items-center position-relative">
                                <Card.Body className="pt-0">
                                  {activeAcc == 200 && (
                                    <Col xs={12} className={style.detailsContent}>
                                      <Row>
                                        {data?.Requet_Results_Info?.map(
                                          (file, index) => (
                                            <Col
                                              xs={12}
                                              key={index}
                                              className="my-2"
                                            >
                                              <Row className="justify-content-between">
                                                <div
                                                  className="my-2 d-flex"
                                                  key={index}
                                                >
                                                  <div className="d-flex">
                                                    {getFileIcon(file.File_Type)}
                                                    <span
                                                      className={[
                                                        style.fileContent,
                                                        style.clientRequestInfo,
                                                        "mx-1",
                                                      ].join(" ")}
                                                    >
                                                      {file.File_Name}
                                                    </span>
                                                    <span
                                                      className={[
                                                        style.fileContent,
                                                        "mx-1",
                                                      ].join(" ")}
                                                    >
                                                      {file.File_Size}
                                                    </span>
                                                  </div>

                                                  <div className="d-flex">
                                                    <FontAwesomeIcon
                                                      className="mx-1"
                                                      size="1x"
                                                      color={"#1e90ff"}
                                                      icon={faDownload}
                                                      onClick={() =>
                                                        download(file)
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </Row>
                                            </Col>
                                          )
                                        )}
                                      </Row>
                                    </Col>
                                  )}
                                </Card.Body>
                              </Row>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      </Col>
                    </Row>
                  ) : null}

                  {stepsObj["inspectionData"] ? (
                    <Row>
                      <Col>
                        <Accordion activeKey={activeAcc}>
                          <Card className={style.accCard}>
                            <Card.Header className={style.accHeader}>
                              <Accordion.Toggle
                                eventKey={activeAcc}
                                className={style.accBtn}
                                onClick={() => {
                                  if (activeAcc == 300) {
                                    setActiveAcc(0)
                                  } else {
                                    setActiveAcc(300)
                                  }
                                }}
                              >
                                <Row className="align-items-center position-relative">
                                  <Col
                                    xs={6}
                                    className={[style.step, "mb-0"].join(" ")}
                                  >
                                    <div className={style.stepHeader}>
                                      <span className={style.number}>-</span>
                                      <p className={style.stepTitle}>
                                        {t("inspectionData")}
                                      </p>
                                    </div>
                                  </Col>

                                  <Col
                                    xs={6}
                                    className="d-flex justify-content-end mb-2"
                                  >
                                    <span
                                      className={[
                                        style.accArrow,
                                        activeAcc == 300
                                          ? style.upArrow
                                          : null,
                                      ].join(" ")}
                                    >
                                      <svg
                                        version="1.1"
                                        id="Capa_1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        x="0px"
                                        y="0px"
                                        width="451.847px"
                                        height="451.847px"
                                        viewBox="0 0 451.847 451.847"
                                        xmlSpace="preserve"
                                      >
                                        <g>
                                          <path
                                            d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751
                                                                                      c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0
                                                                                      c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"
                                          />
                                        </g>
                                      </svg>
                                    </span>
                                  </Col>
                                </Row>
                              </Accordion.Toggle>
                            </Card.Header>

                            <Accordion.Collapse eventKey={activeAcc}>
                              <Row className="align-items-center position-relative">
                                <Card.Body className="pt-0">
                                  <Col xs={12}>
                                    {activeAcc == 300 ? (
                                      <Row>
                                        <Col
                                          xs={12}
                                          className={style.detailsContent}
                                        >
                                          <Row>
                                            <Col xs={12} md={6}>
                                              <Form.Label
                                                className={
                                                  style.clientRequestLabel
                                                }
                                              >
                                                {t("inspectionTime")}
                                              </Form.Label>
                                              <Form.Control
                                                className="my-1"
                                                as={"input"}
                                                rows={3}
                                                defaultValue={
                                                  data.Visit_Data_Info
                                                    .Inspection_Time &&
                                                  moment(
                                                    data.Visit_Data_Info
                                                      .Inspection_Time
                                                  )
                                                    .lang("en")
                                                    .format("YYYY-MM-DD")
                                                  // .format(
                                                  //   i18n.language === "ar"
                                                  //     ? "HH:mm:ss YYYY-MM-DD"
                                                  //     : "YYYY-MM-DD HH:mm:ss"
                                                  // )
                                                }
                                                disabled
                                              />
                                            </Col>
                                            <Col xs={12} md={6}>
                                              <Form.Label
                                                className={
                                                  style.clientRequestLabel
                                                }
                                              >
                                                {t("inspectionUsername")}
                                              </Form.Label>
                                              <Form.Control
                                                className="my-1"
                                                as={"input"}
                                                rows={3}
                                                defaultValue={
                                                  i18n.language === "ar"
                                                    ? data.Visit_Data_Info
                                                      .Inspection_User_Name_AR
                                                    : data.Visit_Data_Info
                                                      .Inspection_User_Name_EN
                                                }
                                                disabled
                                              />
                                            </Col>
                                            <Col xs={12} md={6}>
                                              <Form.Label
                                                className={
                                                  style.clientRequestLabel
                                                }
                                              >
                                                {t("inspectionStartTime")}
                                              </Form.Label>
                                              <Form.Control
                                                className="my-1"
                                                as={"input"}
                                                rows={3}
                                                defaultValue={
                                                  data.Visit_Data_Info
                                                    .Inspection_Start_Time &&
                                                  moment(
                                                    data.Visit_Data_Info
                                                      .Inspection_Start_Time
                                                  )
                                                    .lang("en")
                                                    .format(
                                                      i18n.language === "ar"
                                                        ? "HH:mm:ss YYYY-MM-DD"
                                                        : "YYYY-MM-DD HH:mm:ss"
                                                    )
                                                }
                                                disabled
                                              />
                                            </Col>
                                            <Col xs={12} md={6}>
                                              <Form.Label
                                                className={
                                                  style.clientRequestLabel
                                                }
                                              >
                                                {t("inspectionEndTime")}
                                              </Form.Label>
                                              <Form.Control
                                                className="my-1"
                                                as={"input"}
                                                rows={3}
                                                defaultValue={
                                                  data.Visit_Data_Info
                                                    .Inspection_End_Time &&
                                                  moment(
                                                    data.Visit_Data_Info
                                                      .Inspection_End_Time
                                                  )
                                                    .lang("en")
                                                    .format(
                                                      i18n.language === "ar"
                                                        ? "HH:mm:ss YYYY-MM-DD"
                                                        : "YYYY-MM-DD HH:mm:ss"
                                                    )
                                                }
                                                disabled
                                              />
                                            </Col>
                                            <Col xs={12}>
                                              <Form.Label
                                                className={
                                                  style.clientRequestLabel
                                                }
                                              >
                                                {t("map")}
                                              </Form.Label>
                                              {isLoaded ? (
                                                <GoogleMap
                                                  mapContainerStyle={
                                                    containerStyle
                                                  }
                                                  center={{
                                                    lat: parseFloat(
                                                      data.Visit_Data_Info
                                                        .Inspection_latitudes
                                                    ),
                                                    lng: parseFloat(
                                                      data.Visit_Data_Info
                                                        .Inspection_longitudes
                                                    ),
                                                  }}
                                                  zoom={zoom}
                                                  defaultOptions={{
                                                    styles: stylesArr,
                                                  }}
                                                  options={{
                                                    styles: stylesArr,
                                                  }}
                                                >
                                                  <Marker
                                                    position={{
                                                      lat: parseFloat(
                                                        data.Visit_Data_Info
                                                          .Inspection_latitudes
                                                      ),
                                                      lng: parseFloat(
                                                        data.Visit_Data_Info
                                                          .Inspection_longitudes
                                                      ),
                                                    }}
                                                  />
                                                </GoogleMap>
                                              ) : (
                                                false
                                              )}
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                    ) : null}
                                  </Col>
                                </Card.Body>
                              </Row>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      </Col>
                    </Row>
                  ) : null}

                  <Row className="mt-5 justify-content-end">
                    <Col className="d-flex justify-content-end mb-2">
                      <button
                        className="mainBtn primarymainBtn lgBtn"
                        onClick={() => window.history.back()}
                      >
                        {t("close")}
                      </button>
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col md={4}>
                <div className="gCard">
                  <div className="text-center">
                    <ServiceMunicipal
                      municipalDetails={data?.Request_Info}
                      requestNo={data?.Request_Info?.Request_No}
                    // inputsData={currentTaskData?.Task_Type_Id === "5" ? currentTaskData : inputsData}
                    // supportTypeId={currentTaskData?.Task_Type_Id ? TASK_SUPPORT_TYPE_ID[currentTaskData.Task_Type_Id] : ""}
                    // isSupportEnabled={true}
                    // error={error}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </>
      ) : null
      }

      <Footer />
    </>
  );
};

export default ClientRequestInfo;

const getFileIcon = (fileType) => {
  return (
    {
      "image/jpeg": <Jpg />,
      "image/png": <Png />,
      "video/mp4": <Mp4 />,
      "application/pdf": <Pdf />,
      "application/vnd.ms-powerpoint": <Doc />,
      "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        <Doc />,
      "application/msword": <Doc />,
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        <Doc />,
      "audio/mpeg": <Mp3 />,
      "application/zip": <Zip />,
      "text/csv": <Csv />,
      "text/plain": <Text />,
    }[fileType] ?? <Attachment />
  );
};

const containerStyle = {
  width: "100%",
  height: "400px",
  borderRadius: "12px",
};

const stylesArr = [
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [{ visibility: "simplified" }, { color: "#fdfdfd" }],
  },
  {
    featureType: "landscape.man_made",
    elementType: "geometry",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "landscape.natural.landcover",
    elementType: "geometry",
    stylers: [{ visibility: "simplified" }],
  },
  {
    featureType: "landscape.natural.landcover",
    elementType: "geometry.fill",
    stylers: [{ visibility: "simplified" }],
  },
  {
    featureType: "landscape.natural.terrain",
    elementType: "geometry",
    stylers: [{ visibility: "simplified" }],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [{ visibility: "simplified" }],
  },
  {
    featureType: "poi",
    elementType: "labels.icon",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "poi.attraction",
    elementType: "geometry",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "poi.business",
    elementType: "geometry",
    stylers: [{ visibility: "simplified" }],
  },
  {
    featureType: "poi.business",
    elementType: "geometry.fill",
    stylers: [{ hue: "#fcff00" }, { saturation: "0" }, { lightness: "40" }],
  },
  {
    featureType: "poi.government",
    elementType: "geometry",
    stylers: [{ visibility: "on" }, { color: "#28a745" }],
  },
  {
    featureType: "poi.medical",
    elementType: "geometry",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [{ visibility: "simplified" }],
  },
  {
    featureType: "poi.place_of_worship",
    elementType: "geometry",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "poi.school",
    elementType: "geometry",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "poi.sports_complex",
    elementType: "geometry",
    stylers: [{ visibility: "simplified" }],
  },
  {
    featureType: "road",
    elementType: "all",
    stylers: [{ color: "#000000" }],
  },
  {
    featureType: "road",
    elementType: "geometry.fill",
    stylers: [{ color: "#ffffff" }],
  },
  {
    featureType: "road",
    elementType: "geometry.stroke",
    stylers: [{ gamma: 7.18 }],
  },
  {
    featureType: "road",
    elementType: "labels.text",
    stylers: [
      { visibility: "on" },
      { saturation: "-35" },
      { lightness: "-95" },
      { gamma: "0.00" },
      { weight: "0.01" },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.icon",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "road.highway",
    elementType: "all",
    stylers: [{ color: "#baa167" }, { lightness: "40" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [{ color: "#ffffff" }],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [{ visibility: "on" }, { color: "#baa167" }, { lightness: "80" }],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry.stroke",
    stylers: [{ color: "#ffffff" }],
  },
  {
    featureType: "road.local",
    elementType: "geometry",
    stylers: [{ visibility: "simplified" }],
  },
  {
    featureType: "road.local",
    elementType: "labels.text",
    stylers: [{ visibility: "on" }],
  },
  {
    featureType: "transit",
    elementType: "all",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [{ gamma: 0.48 }, { visibility: "off" }],
  },
  {
    featureType: "transit.station",
    elementType: "all",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "transit.station",
    elementType: "labels.icon",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "transit.station.rail",
    elementType: "all",
    stylers: [{ visibility: "off" }, { color: "#ff0000" }],
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [{ color: "#4d4946" }],
  },
  {
    featureType: "water",
    elementType: "geometry.fill",
    stylers: [
      { visibility: "on" },
      { color: "#151713" },
      { lightness: "70" },
      { saturation: "0" },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [{ color: "#ffffff" }],
  },
];

{/* {stepsObj["notes"] ? (
  <Row>
    <Col>
      <Accordion activeKey={notesState}>
        <Card className={style.accCard}>
          <Card.Header className={style.accHeader}>
            <Accordion.Toggle
              eventKey={notesState}
              className={style.accBtn}
              onClick={() =>
                setNotesState(notesState == 0 ? 1 : 0)
              }
            >
              <Row className="align-items-center position-relative">
                <Col
                  xs={6}
                  className={[style.step, "mb-0"].join(" ")}
                >
                  <div className={style.stepHeader}>
                    <span className={style.number}>
                      {stepsObj["notes"]}
                    </span>
                    <p className={style.stepTitle}>
                      {t("service_request_notes")}
                    </p>
                  </div>
                </Col>

                <Col
                  xs={6}
                  className="d-flex justify-content-end mb-2"
                >
                  <span
                    className={[
                      style.accArrow,
                      notesState == 1 ? style.upArrow : null,
                    ].join(" ")}
                  >
                    <svg
                      version="1.1"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      width="451.847px"
                      height="451.847px"
                      viewBox="0 0 451.847 451.847"
                      xmlSpace="preserve"
                    >
                      <g>
                        <path
                          d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751
                                                                      c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0
                                                                      c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"
                        />
                      </g>
                    </svg>
                  </span>
                </Col>
              </Row>
            </Accordion.Toggle>
          </Card.Header>

          <Accordion.Collapse eventKey={notesState}>
            <Row className="align-items-center position-relative">
              <Card.Body className="pt-0">
                <Col xs={12} className={style.detailsContent}>
                  <Row>
                    {data?.Requet_Inputs_Info.map(
                      (input, index) => (
                        <Col
                          xs={
                            input.Input_Type == 1
                              ? 6
                              : input.Input_Type == 2
                                ? 12
                                : false
                          }
                          key={index}
                        >
                          <Form.Label
                            className={
                              style.clientRequestLabel
                            }
                          >
                            {i18n.language == "en"
                              ? input.Input_Title_EN
                              : input.Input_Title_AR}
                          </Form.Label>
                          <Form.Control
                            className="my-1"
                            as={
                              input.Input_Type == 2
                                ? "textarea"
                                : "input"
                            }
                            rows={3}
                            defaultValue={input.Input_Value}
                            disabled
                          />
                        </Col>
                      )
                    )}
                  </Row>
                </Col>
              </Card.Body>
            </Row>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </Col>
  </Row>
) : null} */}

{/* {stepsObj["attachments"] ? (
  <Row>
    <Col>
      <Accordion activeKey={attachmentState}>
        <Card className={style.accCard}>
          <Card.Header className={style.accHeader}>
            <Accordion.Toggle
              eventKey={attachmentState}
              className={style.accBtn}
              onClick={() =>
                setAttachmentState(
                  attachmentState == 0 ? 1 : 0
                )
              }
            >
              <Row className="align-items-center position-relative">
                <Col
                  xs={6}
                  className={[style.step, "mb-0"].join(" ")}
                >
                  <div className={style.stepHeader}>
                    <span className={style.number}>
                      {stepsObj["attachments"]}
                    </span>
                    <p className={style.stepTitle}>
                      {t("service_request_attachments")}
                    </p>
                  </div>
                </Col>

                <Col
                  xs={6}
                  className="d-flex justify-content-end mb-2"
                >
                  <span
                    className={[
                      style.accArrow,
                      attachmentState == 1
                        ? style.upArrow
                        : null,
                    ].join(" ")}
                  >
                    <svg
                      version="1.1"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      width="451.847px"
                      height="451.847px"
                      viewBox="0 0 451.847 451.847"
                      xmlSpace="preserve"
                    >
                      <g>
                        <path
                          d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751
                                                                      c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0
                                                                      c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"
                        />
                      </g>
                    </svg>
                  </span>
                </Col>
              </Row>
            </Accordion.Toggle>
          </Card.Header>

          <Accordion.Collapse eventKey={attachmentState}>
            <Row className="align-items-center position-relative">
              <Card.Body className="pt-0">
                <Col xs={12} className={style.detailsContent}>
                  <Row>
                    {data?.Requet_Attachments_Info?.map(
                      (input, index) => (
                        <Col
                          xs={12}
                          key={index}
                          className="my-2"
                        >
                          <Row>
                            <Form.Label
                              className={
                                style.clientRequestLabel
                              }
                            >
                              {i18n.language == "en"
                                ? input.Att_Title_EN
                                : input.Att_Title_AR}
                            </Form.Label>
                          </Row>

                          <Row className="justify-content-between">
                            {input.Att_Files.map(
                              (file, index) => (
                                <div
                                  className="my-2 d-flex"
                                  key={index}
                                >
                                  <div className="d-flex">
                                    {getFileIcon(
                                      file.File_Type
                                    )}
                                    <span
                                      className={[
                                        style.fileContent,
                                        style.clientRequestInfo,
                                        "mx-1",
                                      ].join(" ")}
                                    >
                                      {file.File_Name}
                                    </span>
                                    <span
                                      className={[
                                        style.fileContent,
                                        "mx-1",
                                      ].join(" ")}
                                    >
                                      {file.File_Size}
                                    </span>
                                  </div>

                                  <div className="d-flex">
                                    <FontAwesomeIcon
                                      className="mx-1"
                                      size="1x"
                                      color={"#1e90ff"}
                                      icon={faDownload}
                                      onClick={() =>
                                        download(file)
                                      }
                                    />
                                  </div>
                                </div>
                              )
                            )}
                          </Row>
                        </Col>
                      )
                    )}
                  </Row>
                </Col>
              </Card.Body>
            </Row>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </Col>
  </Row>
) : null} */}

