import React, { useState, useEffect, useRef } from 'react';
import style from './Media.module.scss';
import { Container, Carousel, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import axios from 'axios';
import { Link } from 'react-router-dom'

const Media = () => {
	const { t, i18n } = useTranslation('common');
	const [media, setMedia] = useState([]);
	const [isSlider, setIsSlider] = useState();
	const [loading, setLoading] = useState(false);
	const [activeIndex, setActiveIndex] = useState(0);

	const fetchMedia = async () => {
		setLoading(true);
		const res = await axios.post(
			`${process.env.REACT_APP_API_URL}/PORTAL/Sliders/GET_TOP_Sliders`,
			{
				Sliders_Count: 5,
			}
		);
		setMedia(res.data.Data);
		setLoading(false);
	};

	useEffect(() => {
		fetchMedia();
	}, []);

	useEffect(() => {
		// console.log(media)
		if (media[0] && media[0].Portal_Slider_Type == 1) {
			setIsSlider(true);
		} else if (media[0] && media[0].Portal_Slider_Type == 2) {
			setIsSlider(false);
		}
	}, [media]);

	const handleNextSlide = () => {
		if (activeIndex < 4) {
			setActiveIndex(activeIndex + 1);
		} else if (activeIndex == 4) {
			setActiveIndex(4);
		}
	};

	const handlePrevSlide = () => {
		if (activeIndex == 0) {
			setActiveIndex(0);
		} else if (activeIndex <= 4) {
			setActiveIndex(activeIndex - 1);
		}
	};

	return (
		<Container fluid className={[style.sliderContainer, 'p-0'].join(' ')}>
			<div className={style.info}>
				<Container>
					<Row>
						<Col>
							<p className={style.subTitle}>
								{i18n.language == 'en'
									? media[activeIndex]?.Portal_Slider_Title_EN
									: media[activeIndex]
										?.Portal_Slider_Title_AR}
							</p>
							<p
								className={[
									style.title,
									i18n.language === 'en' && style.titlEn,
								].join(' ')}
							>
								{i18n.language == 'en'
									? media[activeIndex]
										?.Portal_Slider_Content_EN
									: media[activeIndex]
										?.Portal_Slider_Content_AR}
							</p>
							{media[activeIndex]?.Portal_Slider_Action && (
								<Link
									className={[
										style.carouselBtn,
										'mainBtn primarymainBtn',
										i18n.language === 'en' ? style.carouselBtnEn : style.carouselBtnAr
									].join(' ')}
									to={{
										pathname: media[activeIndex]?.Portal_Slider_Action_Url,
										aboutProps: {
											sliderTitle: i18n.language === 'en' ? media[activeIndex]?.Portal_Slider_Title_EN : media[activeIndex]?.Portal_Slider_Title_AR,
											sliderContent: i18n.language === 'en' ? media[activeIndex]?.Portal_Slider_Content_EN : media[activeIndex]?.Portal_Slider_Content_AR
										}
									}}
									rel="noopener noreferrer"
									target="_blank"
								>
									{i18n.language === 'en' ? media[activeIndex]?.Portal_Slider_Action_Title_EN : media[activeIndex]?.Portal_Slider_Action_Title_AR}
									<span>
										<svg id="arrow-left-s-line" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
											<path id="Path" d="M0,0H36V36H0Z" fill="none" />
											<path id="Path-2" data-name="Path" d="M4.242,9.546l7.425,7.425L9.546,19.092,0,9.546,9.546,0l2.121,2.121Z" transform="translate(12 8.454)" fill="#fff" />
										</svg>
									</span>
								</Link>

							)}
							{/* <p className={style.price}>FOR AED 5,499</p> */}

							<div className={style.infoFooter}>
								{/* <div>
                                        <div>
                                            <p className={style.header}>Location</p>
                                            <p className={style.body}>HQ – Innovation <br /> lounge, 2nd floor</p>
                                        </div>

                                        <div>
                                            <span>
                                                <p className={style.header}>4.8 on</p>
                                                <p className={style.body}>Text</p>
                                            </span>
                                            <span>
                                                <p className={style.header}>4.7 on</p>
                                                <p className={style.body}>Store</p>
                                            </span>
                                            <span>
                                                <p className={style.header}>4.7 on</p>
                                                <p className={style.body}>Trustpilot</p>
                                            </span>
                                        </div>
                                    </div> */}
								{isSlider && (
									<div
										className={[
											style.arrows,
											i18n.language === 'en'
												? style.arrowsEn
												: style.arrowsAr,
										].join(' ')}
									>
										<span
											className={
												media.length > 0 &&
													activeIndex != 0
													? style.active
													: style.disabled
											}
											onClick={() => handlePrevSlide()}
										>
											<svg
												width="12"
												height="15"
												viewBox="0 0 12 15"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M0 1.88407C0 0.759554 1.27298 0.105692 2.18744 0.761133L10.4555 6.68932C10.6388 6.82079 10.7871 6.99512 10.8875 7.19711C10.9879 7.3991 11.0374 7.62259 11.0315 7.84809C11.0257 8.07358 10.9647 8.29421 10.854 8.49073C10.7433 8.68725 10.5861 8.85367 10.3962 8.97547L2.12821 14.279C1.20822 14.8689 0 14.2087 0 13.1158V1.88407Z"
													fill="#000F39"
												/>
											</svg>
										</span>

										<span
											className={
												media.length > 0 &&
													media.length != activeIndex + 1
													? style.active
													: style.disabled
											}
											onClick={() => handleNextSlide()}
										>
											<svg
												width="12"
												height="15"
												viewBox="0 0 12 15"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M0 1.88407C0 0.759554 1.27298 0.105692 2.18744 0.761133L10.4555 6.68932C10.6388 6.82079 10.7871 6.99512 10.8875 7.19711C10.9879 7.3991 11.0374 7.62259 11.0315 7.84809C11.0257 8.07358 10.9647 8.29421 10.854 8.49073C10.7433 8.68725 10.5861 8.85367 10.3962 8.97547L2.12821 14.279C1.20822 14.8689 0 14.2087 0 13.1158V1.88407Z"
													fill="#000F39"
												/>
											</svg>
										</span>
									</div>
								)}
							</div>
						</Col>
					</Row>

					{/* <p
                        className={[
                            style.verticalPara,
                            i18n.language == 'en' ? style.verticalParaEN : style.verticalParaAR
                        ].join(' ')}
                    >
                        Everything your business needs in one place
                    </p> */}

					{/* <div className={[
                        style.chartBtn,
                        i18n.language == 'en' ? style.chartBtnEN : style.chartBtnAR
                    ].join(' ')}>
                        <span>
                            <svg width="30" height="30" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M38.5 38.5H3.5C2.7825 38.5 2.1875 37.905 2.1875 37.1875C2.1875 36.47 2.7825 35.875 3.5 35.875H38.5C39.2175 35.875 39.8125 36.47 39.8125 37.1875C39.8125 37.905 39.2175 38.5 38.5 38.5Z" fill="white" />
                                <path d="M17.0625 7V38.5H24.9375V7C24.9375 5.075 24.15 3.5 21.7875 3.5H20.2125C17.85 3.5 17.0625 5.075 17.0625 7Z" fill="white" />
                                <path d="M5.25 17.5V38.5H12.25V17.5C12.25 15.575 11.55 14 9.45 14H8.05C5.95 14 5.25 15.575 5.25 17.5Z" fill="white" />
                                <path d="M29.75 26.25V38.5H36.75V26.25C36.75 24.325 36.05 22.75 33.95 22.75H32.55C30.45 22.75 29.75 24.325 29.75 26.25Z" fill="white" />
                            </svg>
                        </span>
                    </div> */}
				</Container>
			</div>

			{isSlider ? (
				<Carousel
					className={style.carousel}
					onSlide={(e) => setActiveIndex(e)}
				>
					{media.length > 0 &&
						media.map((img) => (
							<Carousel.Item
								className={style.item}
								key={img.Portal_Slider_Id}
							>
								<img
									className="d-block w-100"
									src={img.Portal_Slider_Media_Url}
									alt="First slide"
								/>
							</Carousel.Item>
						))}
				</Carousel>
			) : (
				<ReactPlayer
					url={media[0] && media[0].Portal_Slider_Media_Url}
					loop={true}
					playing={true}
					muted={true}
					width="100%"
					height="100%"
					className={style.video}
					playsinline={true}
				/>
			)}
		</Container>
	);
};

export default Media;
