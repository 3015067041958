import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Alert } from "react-bootstrap";
import { useDispatch } from "react-redux";
import style from "./ErrorToast.module.scss";
import { messageRemoved } from "features/service/errorMessageSlice";
import { useTranslation } from "react-i18next";

function ErrorToast() {
	const {
		errorArabic,
		errorEnglish,
		errorList,
		errorCode,
		errorColor,
		warning,
	} = useSelector((state) => state.error);
	const [show, setShow] = useState(true);
	const { i18n } = useTranslation();
	const dispatch = useDispatch();

	useEffect(() => {
		if (i18n.language === "en") {
			if (errorEnglish?.length > 0) {
				setShow(true);
			} else {
				setShow(false);
			}
		} else {
			if (errorArabic?.length > 0) {
				setShow(true);
			} else {
				setShow(false);
			}
		}
	}, [errorArabic, errorEnglish, i18n]);

	useEffect(() => {
		if (show && errorCode && errorCode === 401) {
			setTimeout(() => {
				window.location.replace("/login");
			}, 5000);
		}
	}, [show]);

	let handleClose = () => {
		dispatch(messageRemoved());
		setShow(false);
		document.getElementById('theme').style.marginTop = '0px';
		document.getElementById('header').style.top = '0';
	};

	return (
		show && (
			<Alert
				className={`${style.errorAlert} ${warning ? style.fullWidth : ""
					} ${errorColor ? style[errorColor] : ""} ${i18n.language === "ar"
						? style.arabicError
						: style.englishError
					}`}
				variant="danger"
				onClose={handleClose}
				dismissible
				dir="rtl"
			>
				<div className={style.header}>
					{i18n.language === "en" ? errorEnglish : errorArabic}
				</div>
				<p>
					{Array.isArray(errorList) &&
						typeof errorList !== "string" &&
						errorList?.length > 0 &&
						(i18n.language === "en"
							? errorList?.map((ele) => (
								<div>
									<div className={style.listKey}>
										: {ele?.Parameter_EN}
									</div>
									<div>
										{ele?.Errors_EN.map((ele2) => (
											<span>{ele2}</span>
										))}
									</div>
								</div>
							))
							: errorList.map((ele) => (
								<div>
									<div className={style.listKey}>
										{ele?.Parameter_AR} :{" "}
									</div>
									<div className={style.listValue}>
										{ele?.Errors_AR.map((ele2) => (
											<span>{ele2}</span>
										))}
									</div>
								</div>
							)))}
				</p>

				{/* <div>
					<a href="/login">
						{i18n.language === "ar" ? "تسجيل الدخول" : "Login"}
					</a>
				</div> */}
			</Alert>
		)
	);
}

export default ErrorToast;
