import { post } from "./base";
import qs from "qs";
import { loadState } from "utils";

export async function getServiceCard(param, cancelToken) {
  const url = `${"PORTAL/SERVICES/GET_SERVICE_CARD"}`;
  const response = await post(url, qs.stringify(param), cancelToken);
  return response;
}

export async function getDepartmentInfo(param, cancelToken) {
  const url = `${"PORTAL/DEPARTMENTS/GET_DEPARTMENT_INFO"}`;
  const response = await post(url, qs.stringify(param), cancelToken);
  return response;
}

export async function getServiceRequestInfo(param, cancelToken) {
  const url = `${"PORTAL/CLIENTS/REQUESTS/GET_SERVICE_REQUEST_INFO"}`;
  const token = loadState("user")?.access_token;
  console.log('token', token)
  const options = {
    headers: { Authorization: `Bearer ${token}` },
    cancelToken: cancelToken.cancelToken,
  };
  const response = await post(url, qs.stringify(param), options);
  return response;
}

export async function getServiceRequestDraft(param, cancelToken) {
  const url = `${"PORTAL/CLIENTS/REQUESTS/GET_SERVICE_REQUEST_DRAFT_DATA"}`;
  const token = loadState("user")?.access_token;
  const options = {
    headers: { Authorization: `Bearer ${token}` },
    cancelToken: cancelToken.cancelToken,
  };
  const response = await post(url, qs.stringify(param), options);
  return response;
}

export async function deleteDraft(param) {
  const url = `${"PORTAL/CLIENTS/REQUESTS/DELETE_DRAFT"}`;
  const token = loadState("user")?.access_token;
  const options = { headers: { Authorization: `Bearer ${token}` } };
  const response = await post(url, qs.stringify(param), options);
  return response;
}

export async function servieRequestSubmit(param, cancelToken) {
  const url = `${"PORTAL/CLIENTS/REQUESTS/SERVICE_REQUEST_SUBMIT"}`;
  const token = loadState("user")?.access_token;
  const options = {
    headers: { Authorization: `Bearer ${token}` },
    cancelToken: cancelToken.cancelToken,
  };
  const response = await post(url, qs.stringify(param), options);
  return response;
}

export async function saveDraftInputsData(param) {
  const url = `${"PORTAL/CLIENTS/REQUESTS/SAVE_DRAFT_INPUTS_DATA"}`;
  const token = loadState("user")?.access_token;
  const options = { headers: { Authorization: `Bearer ${token}` } };
  const response = await post(url, qs.stringify(param), options);
  return response;
}

export async function saveDraftAttachmentData(param, handleProgress) {
  const url = `${"PORTAL/CLIENTS/REQUESTS/SAVE_DRAFT_ATTACHMENT_DATA"}`;
  const token = loadState("user")?.access_token;
  const options = {
    headers: { Authorization: `Bearer ${token}` },
    onUploadProgress: (progress) => handleProgress(progress),
  };
  const response = await post(url, param, options);
  return response;
}

export async function saveDraftFileData(param, handleProgress) {
  const url = `${"PORTAL/CLIENTS/REQUESTS/SAVE_DRAFT_FILE_DATA"}`;
  const token = loadState("user")?.access_token;
  const options = {
    headers: { Authorization: `Bearer ${token}` },
    onUploadProgress: (progress) => handleProgress(progress),
  };
  const response = await post(url, param, options);
  return response;
}

export async function downloadDraftAttachment(param) {
  const url = `${"PORTAL/CLIENTS/REQUESTS/DOWNLOAD_DRAFT_ATTACHMENT_DATA"}`;
  const token = loadState("user")?.access_token;
  const options = {
    headers: { Authorization: `Bearer ${token}` },
    responseType: "arraybuffer",
  };
  const response = await post(url, qs.stringify(param), options);
  return response;
}

export async function downloadDraftFileData(param) {
  const url = `${"PORTAL/CLIENTS/REQUESTS/DOWNLOAD_DRAFT_FILE_DATA"}`;
  const token = loadState("user")?.access_token;
  const options = {
    headers: { Authorization: `Bearer ${token}` },
    responseType: "arraybuffer",
  };
  const response = await post(url, qs.stringify(param), options);
  return response;
}

export async function deleteDraftAttachment(param) {
  const url = `${"PORTAL/CLIENTS/REQUESTS/DELETE_DRAFT_ATTACHMENT_DATA"}`;
  const token = loadState("user")?.access_token;
  const options = { headers: { Authorization: `Bearer ${token}` } };
  const response = await post(url, qs.stringify(param), options);
  return response;
}

export async function deleteDraftFileData(param) {
  const url = `${"PORTAL/CLIENTS/REQUESTS/DELETE_DRAFT_FILE_DATA"}`;
  const token = loadState("user")?.access_token;
  const options = { headers: { Authorization: `Bearer ${token}` } };
  const response = await post(url, qs.stringify(param), options);
  return response;
}

export async function getUpdateRequestInfo(param, cancelToken) {
  const url = `${"PORTAL/CLIENTS/REQUESTS/GET_UPDATE_REQUEST_DATA_INFO"}`;
  const token = loadState("user")?.access_token;
  const options = {
    headers: { Authorization: `Bearer ${token}` },
    cancelToken: cancelToken.cancelToken,
  };
  const response = await post(url, qs.stringify(param), options);
  return response;
}

export async function saveUpdatRequestInputsData(param) {
  const url = `${"PORTAL/CLIENTS/REQUESTS/SAVE_REQUEST_INPUTS_DATA"}`;
  const token = loadState("user")?.access_token;
  const options = { headers: { Authorization: `Bearer ${token}` } };
  const response = await post(url, qs.stringify(param), options);
  return response;
}

export async function saveUpdateRequestAttachmentData(param, handleProgress) {
  const url = `${"PORTAL/CLIENTS/REQUESTS/SAVE_REQUEST_ATTACHMENT_DATA"}`;
  const token = loadState("user")?.access_token;
  const options = {
    headers: { 
      // "Content-Type": "application/octet-stream",
      Authorization: `Bearer ${token}`
    },
    // responseType: "arraybuffer",
    onUploadProgress: (progress) => handleProgress(progress),
  };
  const response = await post(url, param, options);
  return response;
}

export async function downloadUpdateRequestAttachment(param) {
  const url = `${"PORTAL/CLIENTS/REQUESTS/DOWNLOAD_REQUEST_ATTACHMENT_DATA"}`;
  const token = loadState("user")?.access_token;
  const options = {
    headers: { Authorization: `Bearer ${token}` },
    responseType: "arraybuffer",
  };
  const response = await post(url, qs.stringify(param), options);
  return response;
}

export async function deleteUpdateRequestAttachment(param) {
  const url = `${"PORTAL/CLIENTS/REQUESTS/DELETE_REQUEST_ATTACHMENT_DATA"}`;
  const token = loadState("user")?.access_token;
  const options = { headers: { Authorization: `Bearer ${token}` } };
  const response = await post(url, qs.stringify(param), options);
  return response;
}

export async function submitUpdateRequest(param, cancelToken) {
  const url = `${"PORTAL/CLIENTS/REQUESTS/SUBMIT_REQUEST_UPDATE_DATA"}`;
  const token = loadState("user")?.access_token;
  const options = {
    headers: { Authorization: `Bearer ${token}` },
    cancelToken: cancelToken.cancelToken,
  };
  const response = await post(url, qs.stringify(param), options);
  return response;
}

export async function submitRequestLicenseName(param, cancelToken) {
  const url = `${"PORTAL/CLIENTS/REQUESTS/CONFIRM_REQUEST_LICENSE_NAME"}`;
  const token = loadState("user")?.access_token;
  const options = {
    headers: { Authorization: `Bearer ${token}` },
    cancelToken: cancelToken.cancelToken,
  };
  const response = await post(url, qs.stringify(param), options);
  return response;
}

export async function suggestRequestNewLicenseName(param, cancelToken) {
  const url = `${"PORTAL/CLIENTS/REQUESTS/SUGGEST_REQUEST_NEW_LICENSE_NAMES"}`;
  const token = loadState("user")?.access_token;
  const options = {
    headers: { Authorization: `Bearer ${token}` },
    cancelToken: cancelToken.cancelToken,
  };
  const response = await post(url, qs.stringify(param), options);
  return response;
}

export async function getAdditionalRequestInfo(param, cancelToken) {
  const url = `${"PORTAL/CLIENTS/REQUESTS/GET_REQUEST_ADDITIONAL_DATA_INFO"}`;
  const token = loadState("user")?.access_token;
  const options = {
    headers: { Authorization: `Bearer ${token}` },
    cancelToken: cancelToken.cancelToken,
  };
  const response = await post(url, qs.stringify(param), options);
  return response;
}

export async function requestAdditionDataInspectionVisitLocation(
  param,
  cancelToken
) {
  const url = `${"PORTAL/CLIENTS/REQUESTS/SAVE_REQUEST_ADDITION_DATA_INSPECTION_VISIT_LOCATION"}`;
  const token = loadState("user")?.access_token;
  const options = { headers: { Authorization: `Bearer ${token}` } };
  const response = await post(url, qs.stringify(param), options);
  return response;
}

export async function getInspectionInfo(param, cancelToken) {
  const url = `${"PORTAL/CLIENTS/REQUESTS/GET_REQUEST_INSPECTION_TIME_INFO"}`;
  const token = loadState("user")?.access_token;
  const options = {
    headers: { Authorization: `Bearer ${token}` },
    cancelToken: cancelToken.cancelToken,
  };
  const response = await post(url, qs.stringify(param), options);
  return response;
}

export async function getTradeName(param, cancelToken) {
  const url = `${"PORTAL/CLIENTS/REQUESTS/GET_REQUEST_LICENSE_NAME_CONFIRMATION_INFO"}`;
  const token = loadState("user")?.access_token;
  const options = {
    headers: { Authorization: `Bearer ${token}` },
    cancelToken: cancelToken.cancelToken,
  };
  const response = await post(url, qs.stringify(param), options);
  return response;
}

export async function saveAdditionalRequestInputNotes(param) {
  const url = `${"PORTAL/CLIENTS/REQUESTS/SAVE_REQUEST_ADDITION_DATA_NOTES"}`;
  const token = loadState("user")?.access_token;
  const options = { headers: { Authorization: `Bearer ${token}` } };
  const response = await post(url, qs.stringify(param), options);
  return response;
}

export async function saveDraftNotes(param) {
  const url = `${"PORTAL/CLIENTS/REQUESTS/SAVE_DRAFT_NOTES"}`;
  const token = loadState("user")?.access_token;
  const options = { headers: { Authorization: `Bearer ${token}` } };
  const response = await post(url, qs.stringify(param), options);
  return response;
}

export async function saveAdditionalRequestAttachmentData(
  param,
  handleProgress
) {
  const url = `${"PORTAL/CLIENTS/REQUESTS/SAVE_REQUEST_ADDITION_DATA_ATTACHMENT"}`;
  const token = loadState("user")?.access_token;
  const options = {
    headers: { Authorization: `Bearer ${token}` },
    onUploadProgress: (progress) => handleProgress(progress),
  };
  const response = await post(url, param, options);
  return response;
}

export async function downloadAdditionalRequestAttachment(param) {
  const url = `${"PORTAL/CLIENTS/REQUESTS/DOWNLOAD_REQUEST_ADDITION_DATA_ATTACHMENT"}`;
  const token = loadState("user")?.access_token;
  const options = {
    headers: { Authorization: `Bearer ${token}` },
    responseType: "arraybuffer",
  };
  const response = await post(url, qs.stringify(param), options);
  return response;
}

export async function deleteAdditionalRequestAttachment(param) {
  const url = `${"PORTAL/CLIENTS/REQUESTS/DELETE_REQUEST_ADDITION_DATA_ATTACHMENT"}`;
  const token = loadState("user")?.access_token;
  const options = { headers: { Authorization: `Bearer ${token}` } };
  const response = await post(url, qs.stringify(param), options);
  return response;
}

export async function submitAdditionalRequest(param, cancelToken) {
  const url = `${"PORTAL/CLIENTS/REQUESTS/SUBMIT_REQUEST_ADDITIONAL_DATA"}`;
  const token = loadState("user")?.access_token;
  const options = {
    headers: { Authorization: `Bearer ${token}` },
    cancelToken: cancelToken.cancelToken,
  };
  const response = await post(url, qs.stringify(param), options);
  return response;
}

export async function submitRequestInspection(param, cancelToken) {
  const url = `${"PORTAL/CLIENTS/REQUESTS/CONFIRM_REQUEST_INSPECTION_TIME"}`;
  const token = loadState("user")?.access_token;
  const options = {
    headers: { Authorization: `Bearer ${token}` },
    cancelToken: cancelToken.cancelToken,
  };
  const response = await post(url, qs.stringify(param), options);
  return response;
}

export async function rejectRequestInspection(param, cancelToken) {
  const url = `${"PORTAL/CLIENTS/REQUESTS/REJECT_REQUEST_INSPECTION_TIME"}`;
  const token = loadState("user")?.access_token;
  const options = {
    headers: { Authorization: `Bearer ${token}` },
    cancelToken: cancelToken.cancelToken,
  };
  const response = await post(url, qs.stringify(param), options);
  return response;
}

//complete

export async function getSearchClientsRequestsList(param, cancelToken) {
  const url = `${"PORTAL/CLIENTS/REQUESTS/GET_SEARCH_CLIENTS_REQUESTS_LIST"}`;
  const token = loadState("user")?.access_token;
  const options = {
    headers: { Authorization: `Bearer ${token}` },
    cancelToken: cancelToken.cancelToken,
  };
  const response = await post(url, qs.stringify(param), options);
  return response;
}

export async function getClientsRequestInfoList(param, cancelToken) {
  const url = `${"PORTAL/CLIENTS/REQUESTS/GET_CLIENTS_REQUEST_INFO_LIST"}`;
  const token = loadState("user")?.access_token;
  const options = {
    headers: { Authorization: `Bearer ${token}` },
    cancelToken: cancelToken.cancelToken,
  };
  const response = await post(url, qs.stringify(param), options);
  return response;
}

export async function getClientCompeletedRequestInfo(param, cancelToken) {
  const url = `${"PORTAL/CLIENTS/REQUESTS/GET_CLIENT_COMPELETED_REQUEST_INFO"}`;
  const token = loadState("user")?.access_token;
  const options = {
    headers: { Authorization: `Bearer ${token}` },
    cancelToken: cancelToken.cancelToken,
  };
  const response = await post(url, qs.stringify(param), options);
  return response;
}

export async function getClientUnderProcessRequestInfo(param, cancelToken) {
  const url = `${"PORTAL/CLIENTS/REQUESTS/GET_CLIENT_UNDER_PROCESS_REQUEST_INFO"}`;
  const token = loadState("user")?.access_token;
  const options = {
    headers: { Authorization: `Bearer ${token}` },
    cancelToken: cancelToken.cancelToken,
  };
  const response = await post(url, qs.stringify(param), options);
  return response;
}

export async function getClientRejectedRequestInfo(param, cancelToken) {
  const url = `${"PORTAL/CLIENTS/REQUESTS/GET_CLIENT_REJECTED_REQUEST_INFO"}`;
  const token = loadState("user")?.access_token;
  const options = {
    headers: { Authorization: `Bearer ${token}` },
    cancelToken: cancelToken.cancelToken,
  };
  const response = await post(url, qs.stringify(param), options);
  return response;
}

export async function getClientReturnRequestInfo(param, cancelToken) {
  const url = `${"PORTAL/CLIENTS/REQUESTS/GET_CLIENT_RETURN_REQUEST_INFO"}`;
  const token = loadState("user")?.access_token;
  const options = {
    headers: { Authorization: `Bearer ${token}` },
    cancelToken: cancelToken.cancelToken,
  };
  const response = await post(url, qs.stringify(param), options);
  return response;
}

//GENERAL
export async function downloadAttachment(param) {
  const url = `${"SYSTEM/GENERAL/DOWNLOAD_ATTACHMENT"}`;
  const token = loadState("user")?.access_token;
  const options = {
    headers: { Authorization: `Bearer ${token}` },
    responseType: "arraybuffer",
  };
  const response = await post(url, qs.stringify(param), options);
  return response;
}

export async function textTranslation(param) {
  const url = `${"SYSTEM/GENERAL/TEXT_TRANSLATION"}`;
  const token = loadState("user")?.access_token;
  const options = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const response = await post(url, qs.stringify(param), options);
  return response;
}
