import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import style from "./applyService.module.scss";
import img from "./../../global/images/Illustration.png";
import greyWave from "./iconsFolder/greyWave.svg";
import { useHistory } from "react-router-dom";

const SubmitModal = ({ isShow, handleClick }) => {
  const { t } = useTranslation("common");
  const history = useHistory();

  return (
    <Modal
      show={isShow}
      // onHide={handleClick}
      backdrop="static"
      keyboard={false}
      className={style.modal}
    >
      <div className={style.modalBody}>
        <div className={style.imageContainer}>
          <img src={img} alt="" />
        </div>
        <p className={style.thankYou}>{t("thankyou")}</p>
        <div>{t("requestSuccessMsg")}</div>

        <div className={`${style.buttonsContainer} mt-4`}>
          <button
            onClick={() => history.push(`/`)}
            className={`mainBtn ${style.modalButton} ${style.homeBtn}`}
          >
            {t("menu_home")}
          </button>
          <button
            onClick={() => history.push(`/dashboard`)}
            className={`mainBtn ${style.modalButton} ${style.dashBtn}`}
          >
            {t("Dashboard")}
          </button>
        </div>
      </div>

      {/* <img className={style.greyWave} src={greyWave} alt="" /> */}
    </Modal>
  );
};

export default SubmitModal;
