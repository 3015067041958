import React, { useEffect, useState } from "react";
import Header from "./../../UIComponents/Header/Header";
import Media from "./../../UIComponents/Media/Media";
import AllServices from "./../../UIComponents/AllServices/AllServices";
import Housing from "./../../UIComponents/Housing/Housing";
import DownloadApp from "./../../UIComponents/DownloadApp/DownloadApp";
import ContactUs from "./../../UIComponents/ContactUs/ContactUs";
import Footer from "./../../UIComponents/Footer/Footer";
import ToolCards from "./../../UIComponents/ToolCards/ToolCards";
import InnovationEcosystem from "./../../UIComponents/InnovationEcosystem/InnovationEcosystem";
import ViolationsSuspensions from "./../../UIComponents/ViolationsSuspensions/ViolationsSuspensions";
import PrivacyPolicyModal from "./../../UIComponents/PrivacyPolicyModal";
import axios from "axios";
import { messageAdded } from "features/service/errorMessageSlice";
import { useSelector, useDispatch } from "react-redux";

const Home = () => {
	const [data, setData] = useState({});
	const { user } = useSelector((state) => state.user);
	const dispatch = useDispatch();

	useEffect(() => {
		const fetchDepartmentData = async () => {
			await axios
				.post(
					`${process.env.REACT_APP_API_URL}/PORTAL/DEPARTMENTS/GET_DEPARTMENT_INFO`,
					{
						Department_Id: 1,
					}
				)
				.then((res) => setData(res?.data?.Data));
		};

		fetchDepartmentData();
	}, []);

	useEffect(() => {
		if (user && user?.Client_Profiles[0]?.Profile_Active_Status_Id !== 1) {
			dispatch(
				messageAdded({
					errorEnglish: `Account Status: ${user?.Client_Profiles[0]?.Profile_Active_Status_Name_EN}, Some services will not be available untill the account is activated`,
					errorArabic: `حالة الحساب: ${user?.Client_Profiles[0]?.Profile_Active_Status_Name_AR}, بعض الخدمات غير متاحة حتى يتم تعديل حالة الحساب الى فعال`,
					warning: true,
					errorColor: "warning",
				})
			);
			document.getElementById('theme').style.marginTop = '73px';
			document.getElementById('header').style.top = '73px';
		}
	}, []);

	return (
		<>
			<Header />
			<Media />
			<AllServices homePage={true} />
			<ToolCards />
			{/* <InnovationEcosystem /> */}
			{/* <Housing /> */}
			{/* <DownloadApp homePage={true} /> */}
			<ContactUs data={data} />
			<Footer data={data} />

			<ViolationsSuspensions />
			<PrivacyPolicyModal />
		</>
	);
};

export default Home;
