import React from "react";
import Grid from "components/UIComponents/Grid";
import { Column } from "devextreme-react/data-grid";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { departmentSelected } from "features/service/serviceMunicipSlice";
import style from "./../Dashboard.module.scss";
import { Row } from "react-bootstrap";

const DraftsGrid = (props) => {
  const { t, i18n } = useTranslation("common");
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  let departmentName = "Department_Name_EN",
    serviceName = "Service_Name_EN",
    areaName = "Area_Name_EN",
    RequestDesc = "Request_Desc_EN";

  if (i18n.language === "ar") {
    departmentName = "Department_Name_AR";
    serviceName = "Service_Name_AR";
    areaName = "Area_Name_AR";
    RequestDesc = "Request_Desc_AR";
  }

  const handleEdit = (data) => {
    dispatch(departmentSelected(data?.Department_Id));
    history.push(`/service-card/${data?.Service_Id}/${data?.Request_Draft_Id}`, { from: location.pathname });
  };

  return (
    <>
      <Row className='justify-content-center'>
        <p className={style.gridTitle}>{t("drafts")}</p>
      </Row>

      <Row>
        <Grid
          sourceData={props.data}
          addEnabled={false}
          editEnabled={true}
          deleteEnabled={true}
          viewEnabled={false}
          onView={({ data }) => { }}
          onDelete={({ data }) => {
            props.handleShow(data, "delete");
          }}
          onEdit={({ data }) => handleEdit(data)}>
          <Column caption={t("drafts_number")} alignment="center" dataField="Request_Draft_Id" dataType="string" width='auto' />
          <Column caption={t("drafts_date")} alignment="center" dataField="Request_Draft_Date" dataType="date" width='auto' format="yyyy-MM-dd" />
          <Column caption={t("service_name")} alignment="center" dataField={serviceName} dataType="string" width='auto' />
          <Column caption={t("service_issuance")} alignment="center" dataField="Department_Service_Version_Name" dataType="string" width='auto' visible={false} />
          <Column caption={t("department_name")} alignment="center" dataField={departmentName} dataType="string" width='auto' visible={false} />
          <Column caption={t("requestDesc")} alignment="center" dataField={RequestDesc} dataType="string" width='auto' />
        </Grid>
      </Row>
    </>
  );
};

export default DraftsGrid;
