import React, { useEffect, useState, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import style from "./DyanmicField.module.scss";
import { Col, Form } from "react-bootstrap";
import FieldsLookup from "./FieldsLookup";
import debounce from "lodash.debounce";
import DynamicDropdown from "./DynamicDropdown";
import DynamicDropdownWithValidation from "./DynamicDropdownWithValidation";
import { Button } from "devextreme-react/button";
import PopUp from "../PopUp/PopUp";
import { FormText } from "react-bootstrap";
import InputMask from "react-input-mask";
import { GetDraftInputsDataValidation, GetRequestInputsDataValidation } from "api/dropdownsAPI";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Grid from "components/UIComponents/Grid";
import { Column } from "devextreme-react/data-grid";
import styleNew from "./NewDyanmicField.module.scss";
import * as icons from "./icons";
import STRIPAnimatedGif from "global/imagesTemp/STRIPAnimatedGif-inside.gif";
import DateBox from "devextreme-react/date-box";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

const FiledType = Object.freeze({
	textArea: 4,
	dropdownWithValidation: 11,
	dropdown: 2,
	checkbox: 7,
	number: 5,
	date: 6,
	email: 9,
	phone: 8,
	fle: 3,
	// textWithValidation: 10,
	htmlContent: 10,
	text: 1,
	general: 12,
});

const DynamicField = ({
	item,
	defaultValue,
	onSave = async () => { },
	inputsData,
	autoSave = false,
	changesActions,
	handleResetValCallback,
	validationMessage,
	resetAction,
	validatedMsg,
	isFocus
}) => {
	const handleCallback = (childData) => handleResetValCallback(childData);

	if (item.Department_Service_Detail_Type_Id === FiledType.textArea) {
		return (
			<TextareaComponent
				item={item}
				defaultValue={defaultValue}
				validationMessage={validationMessage}
				onSave={onSave}
				resetValCallback={handleCallback}
				validatedMsg={validatedMsg}
				newVal={
					resetAction?.length > 0 &&
					resetAction?.map((i) =>
						i.Department_Service_Detail_Id ==
							item?.Department_Service_Detail_Id
							? i.New_Value
							: ""
					)
				}
				isFocus={isFocus}
			/>
		);
	} else if (item.Department_Service_Detail_Type_Id === FiledType.checkbox) {
		return (
			<DynamicCheckBox
				item={item}
				defaultValue={defaultValue}
				validationMessage={validationMessage}
				onSave={onSave}
				validatedMsg={validatedMsg}
			/>
		);
	} else if (item.Department_Service_Detail_Type_Id === FiledType.dropdown) {
		return (
			<DynamicDropdown
				item={item}
				defaultValue={defaultValue}
				onSave={onSave}
				reloadDropdown={changesActions?.Reload_DropDown}
				validatedMsg={validatedMsg}
				isFocus={isFocus}
			/>
		);
	} else if (item.Department_Service_Detail_Type_Id === FiledType.dropdownWithValidation) {
		return (
			<DynamicDropdownWithValidation
				item={item}
				defaultValue={defaultValue}
				validationMessage={validationMessage}
				onSave={onSave}
				inputsData={inputsData}
				reloadDropdown={changesActions?.Reload_DropDown}
				validatedMsg={validatedMsg}
				newVal={changesActions?.Reset_Value}
				isFocus={isFocus}
			/>
		);
	} else if (item.Department_Service_Detail_Type_Id === FiledType.number) {
		return (
			<NumberComponent
				item={item}
				defaultValue={defaultValue}
				validationMessage={validationMessage}
				onSave={onSave}
				resetValCallback={handleCallback}
				validatedMsg={validatedMsg}
				newVal={
					resetAction?.length > 0 &&
					resetAction?.map((i) =>
						i.Department_Service_Detail_Id ==
							item?.Department_Service_Detail_Id
							? i.New_Value
							: ""
					)
				}
				isFocus={isFocus}
			/>
		);
	} else if (item.Department_Service_Detail_Type_Id === FiledType.email) {
		return (
			<EmailComponent
				item={item}
				defaultValue={defaultValue}
				validationMessage={validationMessage}
				onSave={onSave}
				resetValCallback={handleCallback}
				validatedMsg={validatedMsg}
				newVal={
					resetAction?.length > 0 &&
					resetAction?.map((i) =>
						i.Department_Service_Detail_Id ==
							item?.Department_Service_Detail_Id
							? i.New_Value
							: ""
					)
				}
				isFocus={isFocus}
			/>
		);
	} else if (item.Department_Service_Detail_Type_Id === FiledType.date) {
		return (
			<DateComponent
				item={item}
				defaultValue={defaultValue}
				validationMessage={validationMessage}
				onSave={onSave}
				resetValCallback={handleCallback}
				validatedMsg={validatedMsg}
				newVal={
					resetAction?.length > 0 &&
					resetAction?.map((i) =>
						i.Department_Service_Detail_Id ==
							item?.Department_Service_Detail_Id
							? i.New_Value
							: ""
					)
				}
				isFocus={isFocus}
			/>
		);
	} else if (item.Department_Service_Detail_Type_Id === FiledType.phone) {
		return (
			<PhoneComponent
				item={item}
				defaultValue={defaultValue}
				validationMessage={validationMessage}
				onSave={onSave}
				resetValCallback={handleCallback}
				validatedMsg={validatedMsg}
				newVal={
					resetAction?.length > 0 &&
					resetAction?.map((i) =>
						i.Department_Service_Detail_Id ==
							item?.Department_Service_Detail_Id
							? i.New_Value
							: ""
					)
				}
				isFocus={isFocus}
			/>
		);
	} else if (item.Department_Service_Detail_Type_Id === FiledType.htmlContent) {
		return <HtmlDisplay
			item={item}
			isFocus={isFocus}
		/>;
	} else if (item.Department_Service_Detail_Type_Id === FiledType.text) {
		return (
			<TextComponent
				item={item}
				defaultValue={defaultValue}
				validationMessage={validationMessage}
				onSave={onSave}
				resetValCallback={handleCallback}
				inputsData={inputsData}
				validatedMsg={validatedMsg}
				newVal={
					resetAction?.length > 0 &&
					resetAction?.map((i) =>
						i.Department_Service_Detail_Id ==
							item?.Department_Service_Detail_Id
							? i.New_Value
							: ""
					)[0]
				}
				isFocus={isFocus}
			/>
		);
	} else if (item.Department_Service_Detail_Type_Id === FiledType.general) {
		return <></>;
	} else {
		return (
			<TextComponent
				item={item}
				defaultValue={defaultValue}
				validationMessage={validationMessage}
				onSave={onSave}
				inputsData={inputsData}
				resetValCallback={handleCallback}
				validatedMsg={validatedMsg}
				newVal={
					resetAction?.length > 0 &&
					resetAction?.map((i) =>
						i.Department_Service_Detail_Id ==
							item?.Department_Service_Detail_Id
							? i.New_Value
							: ""
					)
				}
				isFocus={isFocus}
			/>
		);
	}
};

export default DynamicField;

const DynamicCheckBox = ({
	item,
	defaultValue,
	validationMessage,
	onSave,
	autoSave = false,
	validatedMsg
}) => {
	const { t, i18n } = useTranslation("common");
	const [isTextExpanded, setIsTextExpanded] = useState(false);
	const { clientProfiles } = useSelector((state) => state.user);
	const { currentTaskData } = useSelector(
		(state) => state.service.updateRequest
	);
	const { requestId } = useSelector((state) => state.service.serviceRequest);
	const { requestId: paramRequestId } = useParams();
	const [validationObject, setValidationObject] = useState([false, [], []]);
	const [isPopUp, setIsPopUp] = useState(false);
	const [showErrorMsg, setShowErrorMsg] = useState(true);

	const handleExpandLabel = () => {
		setIsTextExpanded((prev) => !prev);
	};

	const [checked, setChecked] = useState(
		defaultValue() ? (defaultValue() === "true" ? true : false) : ""
	);

	const handleChangeWithoutDebounce = () => {
		setChecked(!checked);
	};

	const handleChangeWithDebounce = async (e) => {
		setChecked(e.target.checked);
		let event = { ...e };
		let obj = { target: { value: event?.target?.checked ? true : false } };
		await onSave(obj, item);
	};

	let handleOpenViolationPopup = useCallback(() => {
		setIsPopUp(!isPopUp);
	}, [isPopUp]);

	let handleMessage = (res) => {
		let validationArr = [];
		let violationsArray = [];
		const {
			Service_Detail_Validation_Message_AR,
			Service_Detail_Validation_Message_EN,
			Service_Detail_Value_Contents,
			Service_Detail_Violations_List,
		} = res?.Data?.Result_Data ? res?.Data?.Result_Data : res?.Data;

		if (
			(Service_Detail_Validation_Message_EN?.trim() !== "" || null) &&
			i18n.language === "en"
		) {
			validationArr.push(Service_Detail_Validation_Message_EN);
		} else if (
			Service_Detail_Validation_Message_AR?.trim() !== "" ||
			null
		) {
			validationArr.push(Service_Detail_Validation_Message_AR);
		}

		if (Service_Detail_Value_Contents?.trim() !== "" || null) {
			Service_Detail_Value_Contents.toString()
				.split("\r\n")
				.map((ele) => validationArr.push(ele));
		}

		if (
			Service_Detail_Violations_List !== null ||
			Service_Detail_Violations_List !== undefined
		) {
			violationsArray = Service_Detail_Violations_List;
		}

		if (res?.Data?.Result_Data.Service_Detail_Validation === 1) {
			setValidationObject([true, validationArr, violationsArray]);
		} else if (res?.Data.Result_Data.Service_Detail_Validation === 0) {
			setValidationObject([false, validationArr, violationsArray]);
		}
	};

	let handleCheck = useCallback(() => {
		if (item.Department_Service_Detail_API_Id > 0) {
			if (window.location.href.includes("update-request")) {
				GetRequestInputsDataValidation({
					Client_Profile_Id: clientProfiles?.Client_Profile_Id,
					Request_Id: requestId ? requestId : paramRequestId,
					Task_Id: currentTaskData?.Task_Id,
					Service_Detail_Id: item.Service_Detail_Id,
					Lang_Id: i18n.language == 'ar' ? 1 : 2
				})
					.then((res) => handleMessage(res))
					.catch((err) => console.log(err));
			} else {
				GetDraftInputsDataValidation({
					Client_Profile_Id: clientProfiles?.Client_Profile_Id,
					Request_Draft_Id: requestId ? requestId : paramRequestId,
					Service_Detail_Id: item.Service_Detail_Id,
					Lang_Id: i18n.language == 'ar' ? 1 : 2
				})
					.then((res) => handleMessage(res))
					.catch((err) => console.log(err));
			}
		}
	}, []);

	useEffect(() => {
		if (validationMessage?.Service_Detail_Validation) {
			let validationArr = [];
			let violationsArray = [];
			if (
				(validationMessage.Service_Detail_Validation_Message_EN?.trim() !==
					"" ||
					null) &&
				i18n.language === "en"
			) {
				validationArr.push(
					validationMessage.Service_Detail_Validation_Message_EN
				);
			} else if (
				validationMessage.Service_Detail_Validation_Message_AR?.trim() !==
				"" ||
				null
			) {
				validationArr.push(
					validationMessage.Service_Detail_Validation_Message_AR
				);
			}

			if (
				validationMessage.Service_Detail_Value_Contents?.trim() !==
				"" ||
				null
			) {
				validationMessage.Service_Detail_Value_Contents?.toString()
					.split("\r\n")
					.map((ele) => validationArr.push(ele));
			}

			if (
				validationMessage.Service_Detail_Violations_List !== null ||
				validationMessage.Service_Detail_Violations_List !== undefined
			) {
				violationsArray =
					validationMessage.Service_Detail_Violations_List;
			}

			setValidationObject([true, validationArr, violationsArray]);
		}
	}, [validationMessage]);

	return (
		<Col
			className={style.checkBoxContainer}
			key={item.Department_Service_Detail_Id}
			md={6}
			id={item.Department_Service_Detail_Title_EN}
		>
			<Form.Check
				type="checkbox"
				className={style.checkBox}
				name={item.Service_Detail_Id}
				checked={checked}
				onChange={(e) => {
					autoSave
						? handleChangeWithoutDebounce()
						: handleChangeWithDebounce(e);
					handleCheck();
					setShowErrorMsg(false)
				}}
				required={
					item.Department_Service_Detail_Required ? true : false
				}
			/>
			<Form.Label
				onClick={() =>
					handleExpandLabel(
						i18n.language === "ar"
							? item?.Department_Service_Detail_Title_AR
							: item?.Department_Service_Detail_Title_EN
					)
				}
				className={
					isTextExpanded
						? styleNew.textLabel
						: styleNew.textExpandedLabel
				}
			>
				{i18n.language === "ar"
					? item?.Department_Service_Detail_Title_AR
					: item?.Department_Service_Detail_Title_EN}
				{item.Department_Service_Detail_Required ? (
					<span className={style.required}>*</span>
				) : (
					<></>
				)}
			</Form.Label>

			{validatedMsg?.map(vm => {
				if (vm?.Parameter_Id == item?.Service_Detail_Id) {
					return (
						<div className={styleNew.errMsg}>
							{i18n.language == 'ar' ? vm?.Errors_AR?.map(m => <div>{m}</div>) : vm?.Errors_EN?.map(m => <div>{m}</div>)}
						</div>
					)
				}
			})}

			{validationObject[1]?.map((ele, key) =>
				key === 0 ? (
					<FormText
						className={[
							validationObject[0] === true
								? style.validationSuccess
								: style.validationError,
							style.validationStateFont,
						]}
					>
						<div className="font-weight-bold">
							{ele}
							{validationObject[2]?.length > 0 && (
								<span
									className={style.showViolations}
									onClick={handleOpenViolationPopup}
								>
									{i18n.language === "en"
										? " - Show Violations"
										: " - عرض المخالفات"}
								</span>
							)}
						</div>
					</FormText>
				) : (
					<FormText className={style.validationSuccess}>
						<div> {ele} </div>
					</FormText>
				)
			)}

			<PopUp
				show={isPopUp}
				handleClose={handleOpenViolationPopup}
				title={t("violations")}
				smallModal={false}
			>
				<Grid sourceData={validationObject[2]} editEnabled={false}>
					<Column
						caption={t("violation_no")}
						alignment="center"
						dataField="Violation_Id"
						dataType="string"
					// width={120}
					/>
					<Column
						caption={t("violation_date")}
						alignment="center"
						dataField="Violation_Date"
						dataType="string"
					// width={120}
					/>
					<Column
						caption={t("violation_type")}
						alignment="center"
						dataField="Violation_Type_Name_AR"
						dataType="string"
					// width={120}
					/>
					<Column
						caption={t("violation_value")}
						alignment="center"
						dataField="Violation_Value"
						dataType="string"
					// width={120}
					/>
					<Column
						caption={t("violation_on")}
						alignment="center"
						dataField="Violation_Holder_Name_AR"
						dataType="string"
					// width={120}
					/>
					<Column
						caption={t("land_piece_no")}
						alignment="center"
						dataField="Plot_No"
						dataType="string"
					// width={120}
					/>
					<Column
						caption={t("project_no")}
						alignment="center"
						dataField="Project_No"
						dataType="string"
					// width={120}
					/>
					<Column
						caption={t("violation_description")}
						alignment="center"
						dataField="Violation_Description"
						dataType="string"
					// width={120}
					/>
				</Grid>
			</PopUp>
		</Col>
	);
};

const TextComponent = ({
	item,
	defaultValue,
	validationMessage,
	onSave,
	resetValCallback,
	newVal,
	validatedMsg,
	isFocus
}) => {
	const { t, i18n } = useTranslation("common");
	const [value, setValue] = useState("");
	// const [value, setValue] = useState(defaultValue ? defaultValue : '')
	const [validationError, setValidationError] = useState(false);
	const [isTextExpanded, setIsTextExpanded] = useState(false);
	const [clientEIDVal, setClientEIDVal] = useState({
		value: "",
		mask: "784-9999-9999999-9",
	});
	const { clientProfiles } = useSelector((state) => state.user);
	const { currentTaskData } = useSelector(
		(state) => state.service.updateRequest
	);
	const { requestId } = useSelector((state) => state.service.serviceRequest);
	const { requestId: paramRequestId } = useParams();
	const [validationObject, setValidationObject] = useState([false, [], []]);
	const [isPopUp, setIsPopUp] = useState(false);
	const [showSpinner, setShowSpinner] = useState(false);
	const [showValidatedMsg, setShowValidatedMsg] = useState(true);
	const textRef = useRef();
	const EIDRef = useRef();

	useEffect(() => {
		setClientEIDVal({ mask: "784-9999-9999999-9", value: defaultValue() });
	}, [defaultValue()]);

	useEffect(() => {
		if (newVal) {
			setValue(newVal);
			setShowValidatedMsg(false)
		} else if (defaultValue() !== null || "") {
			setValue(defaultValue());
		}
	}, [defaultValue, newVal]);

	useEffect(() => {
		if (isFocus == item.Service_Detail_Id) {
			if (item.Department_Service_Detail_Format_Id == 1) {
				EIDRef && EIDRef?.current?.instance?.focus();
			} else {
				textRef && textRef?.current?.focus();
			}
		}
	}, [isFocus])

	const onKeyPress = (e) => {
		setValidationError(false);
		var ew = e.which;

		// Arabic
		if (item.Department_Service_Detail_Lang_Id == 1) {
			if (ew != 8) {
				if (ew >= 65 && ew <= 120 && ew != 32 && ew != 0) {
					setValidationError(true);
					e.preventDefault();
					return false;
				}
			}
			// Eng
		} else if (item.Department_Service_Detail_Lang_Id == 2) {
			if (ew == 32) {
				return true;
			} else if (48 <= ew && ew <= 57) {
				return true;
			} else if (65 <= ew && ew <= 90) {
				return true;
			} else if (97 <= ew && ew <= 122) {
				return true;
			} else if (ew == 64 || ew == 46 || ew == 95 || ew == 45) {
				return true;
			} else {
				setValidationError(true);
				e.preventDefault();
				return false;
			}
		} else if (item.Department_Service_Detail_Allow_Symbols == 0) {
			if (
				ew == 63 ||
				ew == 47 ||
				ew == 62 ||
				ew == 60 ||
				ew == 46 ||
				ew == 44 ||
				ew == 34 ||
				ew == 58 ||
				ew == 124 ||
				ew == 39 ||
				ew == 59 ||
				ew == 125 ||
				ew == 123 ||
				ew == 126 ||
				ew == 33 ||
				ew == 64 ||
				ew == 35 ||
				ew == 36 ||
				ew == 37 ||
				ew == 38 ||
				ew == 42 ||
				ew == 40 ||
				ew == 41 ||
				ew == 43 ||
				ew == 61 ||
				ew == 45 ||
				ew == 1567 ||
				ew == 1617
			) {
				e.preventDefault();
				return false;
			}
		}

		return true;
	};

	const handleExpandLabel = () => {
		setIsTextExpanded((prev) => !prev);
	};

	const handleChangeWithDebounce = debounce(async (e) => {
		setValue(e?.target?.value);
		await onSave(e, item).then((res) => {
			handleCheck();
		});
	}, 500);

	const handleEIDMask = (val) => {
		let newState = {
			mask: "784-9999-9999999-9",
			value: val,
		};
		if (/^3[47]/.test(val)) {
			newState.mask = "784-9999-9999999-9";
		}
		setClientEIDVal(newState);
	};

	let handleOpenViolationPopup = useCallback(() => {
		setIsPopUp(!isPopUp);
	}, [isPopUp]);

	let handleMessage = (res) => {
		let validationArr = [];
		let violationsArray = [];
		const {
			Service_Detail_Validation_Message_AR,
			Service_Detail_Validation_Message_EN,
			Service_Detail_Value_Contents,
			Service_Detail_Violations_List,
		} = res?.Data?.Result_Data ?? res?.Data;

		if (
			(Service_Detail_Validation_Message_EN?.trim() !== "" || null) &&
			i18n.language === "en"
		) {
			validationArr.push(Service_Detail_Validation_Message_EN);
		} else if (
			Service_Detail_Validation_Message_AR?.trim() !== "" ||
			null
		) {
			validationArr.push(Service_Detail_Validation_Message_AR);
		}

		if (Service_Detail_Value_Contents?.trim() !== "" || null) {
			Service_Detail_Value_Contents?.toString()
				.split("\r\n")
				.map((ele) => validationArr.push(ele));
		}

		if (
			Service_Detail_Violations_List !== null ||
			Service_Detail_Violations_List !== undefined
		) {
			violationsArray = Service_Detail_Violations_List;
		}

		if (res?.Data?.Result_Data?.Service_Detail_Validation === 1) {
			setValidationObject([true, validationArr, violationsArray]);
		} else if (res?.Data.Result_Data.Service_Detail_Validation === 0) {
			setValidationObject([false, validationArr, violationsArray]);
		}
	};

	useEffect(() => {
		if (validationMessage?.Service_Detail_Validation) {
			let validationArr = [];
			let violationsArray = [];
			if (
				(validationMessage.Service_Detail_Validation_Message_EN?.trim() !==
					"" ||
					null) &&
				i18n.language === "en"
			) {
				validationArr.push(
					validationMessage.Service_Detail_Validation_Message_EN
				);
			} else if (
				validationMessage.Service_Detail_Validation_Message_AR?.trim() !==
				"" ||
				null
			) {
				validationArr.push(
					validationMessage.Service_Detail_Validation_Message_AR
				);
			}

			if (
				validationMessage.Service_Detail_Value_Contents?.trim() !==
				"" ||
				null
			) {
				validationMessage.Service_Detail_Value_Contents?.toString()
					.split("\r\n")
					.map((ele) => validationArr.push(ele));
			}

			if (
				validationMessage.Service_Detail_Violations_List !== null ||
				validationMessage.Service_Detail_Violations_List !== undefined
			) {
				violationsArray =
					validationMessage.Service_Detail_Violations_List;
			}

			setValidationObject([true, validationArr, violationsArray]);
		}
	}, [validationMessage]);

	let handleCheck = useCallback(() => {
		if (item.Department_Service_Detail_API_Id > 0) {
			if (window.location.href.includes("update-request")) {
				GetRequestInputsDataValidation({
					Client_Profile_Id: clientProfiles?.Client_Profile_Id,
					Request_Id: requestId ? requestId : paramRequestId,
					Task_Id: currentTaskData?.Task_Id,
					Service_Detail_Id: item.Service_Detail_Id,
					Lang_Id: i18n.language == 'ar' ? 1 : 2
				}).then((res) => {
					resetValCallback(res?.Data?.Changes_Actions?.Reset_Value);
					handleMessage(res);
					setShowSpinner(false)
				}).catch((err) => console.log(err));
			} else {
				GetDraftInputsDataValidation({
					Client_Profile_Id: clientProfiles?.Client_Profile_Id,
					Request_Draft_Id: requestId ?? paramRequestId,
					Service_Detail_Id: item.Service_Detail_Id,
					Lang_Id: i18n.language == 'ar' ? 1 : 2
				}).then((res) => {
					resetValCallback(res?.Data?.Changes_Actions?.Reset_Value);
					handleMessage(res);
					setShowSpinner(false)
				});
			}
		} else {
			setShowSpinner(false)
		}
	}, []);

	return (
		<Col key={item.Department_Service_Detail_Id} md={12} id={item.Department_Service_Detail_Title_EN}>
			<Form.Label
				onClick={() =>
					handleExpandLabel(
						i18n.language === "ar"
							? item?.Department_Service_Detail_Title_AR
							: item?.Department_Service_Detail_Title_EN
					)
				}
				className={
					isTextExpanded
						? styleNew.textLabel
						: styleNew.textExpandedLabel
				}
			>
				{i18n.language === "ar"
					? item?.Department_Service_Detail_Title_AR
					: item?.Department_Service_Detail_Title_EN}
				{item.Department_Service_Detail_Required ? (
					<span className={style.required}>*</span>
				) : (
					<></>
				)}
			</Form.Label>

			<div className={styleNew.inputTextContainer}>
				{item.Department_Service_Detail_Format_Id == 1 ? (
					<>
						<InputMask
							ref={EIDRef}
							name="Client_EID"
							{...clientEIDVal}
							style={{
								direction: "ltr",
								height: "calc(1.5em + 0.75rem + 2px)",
								outline: "none",
								textAlign:
									i18n.language == "ar" ? "end" : "start",
							}}
							onChange={(e) => {
								handleEIDMask(e.target.value)
							}}
							onBlur={(e) => {
								handleEIDMask(e.target.value);
								handleChangeWithDebounce(e);
								if (e) {
									setShowSpinner(true)
								}
							}}
							autoComplete="off"
							required={
								item.Department_Service_Detail_Required
									? true
									: false
							}
						/>
					</>
				) : (
					<>
						<Form.Control
							ref={textRef}
							type="text"
							name={item.Service_Detail_Id}
							defaultValue={value}
							onKeyPress={onKeyPress}
							required={item.Department_Service_Detail_Required ? true : false}
							onBlur={(e) => {
								handleChangeWithDebounce(e);
								if (e) {
									setShowSpinner(true)
								}
							}}
						/>
					</>
				)}
			</div>

			{showValidatedMsg && validatedMsg?.map(vm => {
				if (vm?.Parameter_Id == item?.Service_Detail_Id) {
					return (
						<div className={styleNew.errMsg}>
							{i18n.language == 'ar' ? vm?.Errors_AR?.map(m => <div>{m}</div>) : vm?.Errors_EN?.map(m => <div>{m}</div>)}
						</div>
					)
				}
			})}

			<div className={styleNew.violationContainer}>
				{showSpinner &&
					<>
						<div className={styleNew.loadingOverlay}></div>
						<div className={[styleNew.img, i18n.language == 'ar' ? styleNew.imgAR : styleNew.imgEN].join(' ')}>
							<img width='40' src={STRIPAnimatedGif} />
						</div>
					</>
				}

				{validationError && item.Department_Service_Detail_Lang_Id == 1 ? (
					<Form.Text className={style.onError}>
						{t("Write Arabic only")}
					</Form.Text>
				) : validationError &&
					item.Department_Service_Detail_Lang_Id == 2 ? (
					<Form.Text className={style.onError}>
						{t("Write English only")}
					</Form.Text>
				) : null}

				{validationObject[1]?.map((ele, key) =>
					key === 0 ? (
						<FormText
							className={[
								validationObject[0] === true
									? style.validationSuccess
									: style.validationError,
								style.validationStateFont,
							]}
						>
							<div className="font-weight-bold">
								{ele}
								{validationObject[2]?.length > 0 && (
									<span
										className={style.showViolations}
										onClick={handleOpenViolationPopup}
									>
										{i18n.language === "en"
											? " - Show Violations"
											: " - عرض المخالفات"}
									</span>
								)}
							</div>
						</FormText>
					) : (
						<FormText className={style.validationSuccess}>
							<div> {ele} </div>
						</FormText>
					)
				)}
			</div>

			<PopUp
				show={isPopUp}
				handleClose={handleOpenViolationPopup}
				title={t("violations")}
				smallModal={false}
			>
				<Grid sourceData={validationObject[2]} editEnabled={false}>
					<Column
						caption={t("violation_no")}
						alignment="center"
						dataField="Violation_Id"
						dataType="string"
					// width={120}
					/>
					<Column
						caption={t("violation_date")}
						alignment="center"
						dataField="Violation_Date"
						dataType="string"
					// width={120}
					/>
					<Column
						caption={t("violation_type")}
						alignment="center"
						dataField="Violation_Type_Name_AR"
						dataType="string"
					// width={120}
					/>
					<Column
						caption={t("violation_value")}
						alignment="center"
						dataField="Violation_Value"
						dataType="string"
					// width={120}
					/>
					<Column
						caption={t("violation_on")}
						alignment="center"
						dataField="Violation_Holder_Name_AR"
						dataType="string"
					// width={120}
					/>
					<Column
						caption={t("land_piece_no")}
						alignment="center"
						dataField="Plot_No"
						dataType="string"
					// width={120}
					/>
					<Column
						caption={t("project_no")}
						alignment="center"
						dataField="Project_No"
						dataType="string"
					// width={120}
					/>
					<Column
						caption={t("violation_description")}
						alignment="center"
						dataField="Violation_Description"
						dataType="string"
					// width={120}
					/>
				</Grid>
			</PopUp>
		</Col>
	);
};

const GeneralComponent = ({ item, defaultValue, onSave }) => {
	const { t, i18n } = useTranslation("common");
	const [value, setValue] = useState(defaultValue ? defaultValue : "");
	const [validationError, setValidationError] = useState(false);

	const handleChangeWithDebounce = debounce(async (e) => {
		await onSave(e, item);
		setValue(e?.target?.value);
	}, 500);

	return (
		<Col
			className={style.dynamics}
			key={item.Department_Service_Detail_Id}
			id={item.Department_Service_Detail_Title_EN}
			md={6}
		>
			<Form.Label className={style.label}>
				{i18n.language === "ar"
					? item?.Department_Service_Detail_Title_AR
					: item?.Department_Service_Detail_Title_EN}
				{item.Department_Service_Detail_Required ? (
					<span className={style.required}>*</span>
				) : (
					<></>
				)}
			</Form.Label>
			<Form.Control
				type="text"
				name={item.Service_Detail_Id}
				defaultValue={value}
				onChange={handleChangeWithDebounce}
				required={
					item.Department_Service_Detail_Required ? true : false
				}
			/>
			{validationError && (
				<Form.Text className={style.onError}>
					{t("Write Arabic only")}
				</Form.Text>
			)}
		</Col>
	);
};

const TextWithValidationComponent = ({
	item,
	defaultValue,
	onSave,
	inputsData,
	autoSave = false,
}) => {
	const { t, i18n } = useTranslation("common");
	const [value, setValue] = useState(defaultValue ? defaultValue : "");
	const [validationError, setValidationError] = useState(false);
	const { clientProfiles } = useSelector((state) => state.user);
	const { currentTaskData } = useSelector(
		(state) => state.service.updateRequest
	);

	const { requestId } = useSelector((state) => state.service.serviceRequest);
	const { requestId: paramRequestId } = useParams();
	const [isPopUp, setIsPopUp] = useState(false);
	const [violationText, setViolationText] = useState("");
	const [validationObject, setValidationObject] = useState([false, [], []]);
	const [isTextExpanded, setIsTextExpanded] = useState(false);

	const handleExpandLabel = () => {
		setIsTextExpanded((prev) => !prev);
	};

	useEffect(() => {
		if (inputsData && inputsData()) {
			let res = { Data: {} };
			res.Data = inputsData();
			handleMessage(res);
		}
	}, [inputsData]);

	useEffect(() => {
		const subscriber = (data) => {
			// just in case another dropdown in the same page changed so do not fire check func
			if (data.detail.item.Department_Service_Detail_No === 5) {
				handleCheck();
			}
		};
		document.addEventListener("dropDownChanged", subscriber);

		return () => {
			document.removeEventListener("dropDownChanged", subscriber);
		};
	}, []);

	const onKeyPress = (e) => {
		var ew = e.which;

		if (ew != 8) {
			// if ((ew < 48 || ew > 57) && (ew < 0x0600 || ew > 0x06ff) && ew != 32)  {
			if (ew >= 65 && ew <= 120 && ew != 32 && ew != 0) {
				setValidationError(true);
				e.preventDefault();
				return false;
			}
		}

		if (validationError) {
			setValidationError(false);
		}
		return true;
	};

	const handleChangeWithDebounce = debounce(async (e) => {
		await onSave(e, item);
		setValue(e?.target?.value);
	}, 500);

	let handleOpenViolationPopup = useCallback(() => {
		setIsPopUp(!isPopUp);
	}, [isPopUp]);

	// ahmed
	let handleMessage = (res) => {
		let validationArr = [];
		let violationsArray = [];
		const {
			Service_Detail_Validation_Message_AR,
			Service_Detail_Validation_Message_EN,
			Service_Detail_Value_Contents,
			Service_Detail_Violations_List,
		} = res?.Data?.Result_Data ? res?.Data?.Result_Data : res?.Data;

		if (
			(Service_Detail_Validation_Message_EN?.trim() !== "" || null) &&
			i18n.language === "en"
		) {
			validationArr.push(Service_Detail_Validation_Message_EN);
		} else if (
			Service_Detail_Validation_Message_AR?.trim() !== "" ||
			null
		) {
			validationArr.push(Service_Detail_Validation_Message_AR);
		}

		if (Service_Detail_Value_Contents?.trim() !== "" || null) {
			Service_Detail_Value_Contents.toString()
				.split("\r\n")
				.map((ele) => validationArr.push(ele));
		}

		if (
			Service_Detail_Violations_List !== null ||
			Service_Detail_Violations_List !== undefined
		) {
			violationsArray = Service_Detail_Violations_List;
		}

		if (res?.Data?.Result_Data?.Service_Detail_Validation === 1) {
			setValidationObject([true, validationArr, violationsArray]);
		} else if (res?.Data.Result_Data?.Service_Detail_Validation === 0) {
			setValidationObject([false, validationArr, violationsArray]);
		}
	};

	let handleCheck = useCallback(() => {
		if (window.location.href.includes("update-request")) {
			GetRequestInputsDataValidation({
				Client_Profile_Id: clientProfiles?.Client_Profile_Id,
				Request_Id: requestId ? requestId : paramRequestId,
				Task_Id: currentTaskData?.Task_Id,
				Service_Detail_Id: item.Service_Detail_Id,
				Lang_Id: i18n.language == 'ar' ? 1 : 2
			})
				.then((res) => handleMessage(res))
				.catch((err) => console.log(err));
		} else {
			GetDraftInputsDataValidation({
				Client_Profile_Id: clientProfiles?.Client_Profile_Id,
				Request_Draft_Id: requestId ? requestId : paramRequestId,
				Service_Detail_Id: item.Service_Detail_Id,
				Lang_Id: i18n.language == 'ar' ? 1 : 2
			})
				.then((res) => handleMessage(res))
				.catch((err) => console.log(err));
		}
	}, []);

	const onInputUntouched = async (e) => {
		await onSave(e, item);
		handleCheck();
	};

	const handleChangeWithoutDebounce = (e) => {
		setValue(e.target.value);
	};

	return (
		<Col
			// className={style.dynamics}
			key={item.Department_Service_Detail_Id}
			md={12}
			id={item.Department_Service_Detail_Title_EN}
		>
			<Form.Label
				onClick={() =>
					handleExpandLabel(
						i18n.language === "ar"
							? item?.Department_Service_Detail_Title_AR
							: item?.Department_Service_Detail_Title_EN
					)
				}
				className={
					isTextExpanded
						? styleNew.textLabel
						: styleNew.textExpandedLabel
				}
			>
				{i18n.language === "ar"
					? item?.Department_Service_Detail_Title_AR
					: item?.Department_Service_Detail_Title_EN}
				{item.Department_Service_Detail_Required ? (
					<span className={style.required}>*</span>
				) : (
					<></>
				)}
			</Form.Label>
			<div className={styleNew.inputTextContainer}>
				<Form.Control
					type="text"
					// className={style.dynamicTextValidation}
					name={item.Service_Detail_Id}
					defaultValue={value}
					// onKeyPress={onKeyPress}
					onChange={
						autoSave
							? handleChangeWithoutDebounce
							: handleChangeWithDebounce
					}
					required={
						item.Department_Service_Detail_Required ? true : false
					}
					{...(autoSave && { onChange: onInputUntouched })}
				/>

				<div className={styleNew.inputTextIcon}>
					<div className={styleNew.numberIconContainer}>
						<button type="button">{icons.textInput}</button>
					</div>
				</div>

				{!autoSave && (
					<Button
						style={{ backgroundColor: "#58743A" }}
						icon="check"
						type="success"
						onClick={handleCheck}
						className="mx-2"
					/>
				)}
			</div>
			{validationObject[1]?.map((ele, key) =>
				key === 0 ? (
					<FormText
						className={[
							validationObject[0] === true
								? style.validationSuccess
								: style.validationError,
							style.validationStateFont,
						]}
					>
						<div className="font-weight-bold">
							{ele}
							{validationObject[2]?.length > 0 && (
								<span
									className={style.showViolations}
									onClick={handleOpenViolationPopup}
								>
									{i18n.language === "en"
										? " - Show Violations"
										: " - عرض المخالفات"}
								</span>
							)}
						</div>
					</FormText>
				) : (
					<FormText className={style.validationSuccess}>
						<div> {ele} </div>
					</FormText>
				)
			)}

			<PopUp
				show={isPopUp}
				handleClose={handleOpenViolationPopup}
				title={t("violations")}
				smallModal={false}
			>
				<Grid sourceData={validationObject[2]} editEnabled={false}>
					<Column
						caption={t("violation_no")}
						alignment="center"
						dataField="Violation_Id"
						dataType="string"
					// width={120}
					/>
					<Column
						caption={t("violation_date")}
						alignment="center"
						dataField="Violation_Date"
						dataType="string"
					// width={120}
					/>
					<Column
						caption={t("violation_type")}
						alignment="center"
						dataField="Violation_Type_Name_AR"
						dataType="string"
					// width={120}
					/>
					<Column
						caption={t("violation_value")}
						alignment="center"
						dataField="Violation_Value"
						dataType="string"
					// width={120}
					/>
					<Column
						caption={t("violation_on")}
						alignment="center"
						dataField="Violation_Holder_Name_AR"
						dataType="string"
					// width={120}
					/>
					<Column
						caption={t("land_piece_no")}
						alignment="center"
						dataField="Plot_No"
						dataType="string"
					// width={120}
					/>
					<Column
						caption={t("project_no")}
						alignment="center"
						dataField="Project_No"
						dataType="string"
					// width={120}
					/>
					<Column
						caption={t("violation_description")}
						alignment="center"
						dataField="Violation_Description"
						dataType="string"
					// width={120}
					/>
				</Grid>
			</PopUp>

			{/* {validationError && (
        <Form.Text className={style.onError}>
          {t("Write Arabic only")}
        </Form.Text>
      )} */}
		</Col>
	);
};

const PhoneComponent = ({
	item,
	defaultValue,
	validationMessage,
	onSave,
	resetValCallback,
	newVal,
	validatedMsg,
	isFocus
}) => {
	const { i18n, t } = useTranslation("common");
	const [value, setValue] = useState(defaultValue ? defaultValue : "");
	const [validationError, setValidationError] = useState(false);
	const [isTextExpanded, setIsTextExpanded] = useState(false);
	const { clientProfiles } = useSelector((state) => state.user);
	const { currentTaskData } = useSelector(
		(state) => state.service.updateRequest
	);
	const { requestId } = useSelector((state) => state.service.serviceRequest);
	const { requestId: paramRequestId } = useParams();
	const [validationObject, setValidationObject] = useState([false, [], []]);
	const [isPopUp, setIsPopUp] = useState(false);
	const [showSpinner, setShowSpinner] = useState(false);
	const inputRef = useRef();

	useEffect(() => {
		if (newVal) {
			setValue(newVal[0]);
		}
	}, [newVal]);

	useEffect(() => {
		if (validationMessage?.Service_Detail_Validation) {
			let validationArr = [];
			let violationsArray = [];
			if (
				(validationMessage.Service_Detail_Validation_Message_EN?.trim() !==
					"" ||
					null) &&
				i18n.language === "en"
			) {
				validationArr.push(
					validationMessage.Service_Detail_Validation_Message_EN
				);
			} else if (
				validationMessage.Service_Detail_Validation_Message_AR?.trim() !==
				"" ||
				null
			) {
				validationArr.push(
					validationMessage.Service_Detail_Validation_Message_AR
				);
			}

			if (
				validationMessage.Service_Detail_Value_Contents?.trim() !==
				"" ||
				null
			) {
				validationMessage.Service_Detail_Value_Contents?.toString()
					.split("\r\n")
					.map((ele) => validationArr.push(ele));
			}

			if (
				validationMessage.Service_Detail_Violations_List !== null ||
				validationMessage.Service_Detail_Violations_List !== undefined
			) {
				violationsArray =
					validationMessage.Service_Detail_Violations_List;
			}

			setValidationObject([true, validationArr, violationsArray]);
		}
	}, [validationMessage]);

	useEffect(() => {
		if (isFocus == item.Service_Detail_Id) {
			inputRef && inputRef?.current?.focus();
			console.log(inputRef)
		}
	}, [isFocus])

	const handleExpandLabel = () => {
		setIsTextExpanded((prev) => !prev);
	};

	const onKeyPress = (e) => {
		//   setValidationError(false);
		// }
		return true;
	}; // if (validationError) {

	const handleChangeWithDebounce = debounce(async (e) => {
		await onSave(e, item).then(() => handleCheck());
		setValue(e?.target?.value);
	}, 500);

	let handleOpenViolationPopup = useCallback(() => {
		setIsPopUp(!isPopUp);
	}, [isPopUp]);

	let handleMessage = (res) => {
		let validationArr = [];
		let violationsArray = [];
		const {
			Service_Detail_Validation_Message_AR,
			Service_Detail_Validation_Message_EN,
			Service_Detail_Value_Contents,
			Service_Detail_Violations_List,
		} = res?.Data?.Result_Data ? res?.Data?.Result_Data : res?.Data;

		if (
			(Service_Detail_Validation_Message_EN?.trim() !== "" || null) &&
			i18n.language === "en"
		) {
			validationArr.push(Service_Detail_Validation_Message_EN);
		} else if (
			Service_Detail_Validation_Message_AR?.trim() !== "" ||
			null
		) {
			validationArr.push(Service_Detail_Validation_Message_AR);
		}

		if (Service_Detail_Value_Contents?.trim() !== "" || null) {
			Service_Detail_Value_Contents.toString()
				.split("\r\n")
				.map((ele) => validationArr.push(ele));
		}

		if (
			Service_Detail_Violations_List !== null ||
			Service_Detail_Violations_List !== undefined
		) {
			violationsArray = Service_Detail_Violations_List;
		}

		if (res?.Data?.Result_Data?.Service_Detail_Validation === 1) {
			setValidationObject([true, validationArr, violationsArray]);
		} else if (res?.Data.Result_Data?.Service_Detail_Validation === 0) {
			setValidationObject([false, validationArr, violationsArray]);
		}
	};

	let handleCheck = useCallback(() => {
		if (item.Department_Service_Detail_API_Id > 0) {
			if (window.location.href.includes("update-request")) {
				GetRequestInputsDataValidation({
					Client_Profile_Id: clientProfiles?.Client_Profile_Id,
					Request_Id: requestId ? requestId : paramRequestId,
					Task_Id: currentTaskData?.Task_Id,
					Service_Detail_Id: item.Service_Detail_Id,
					Lang_Id: i18n.language == 'ar' ? 1 : 2
				})
					.then((res) => {
						resetValCallback(
							res?.Data?.Changes_Actions?.Reset_Value
						);
						handleMessage(res);
						setShowSpinner(false)
					})
					.catch((err) => console.log(err));
			} else {
				GetDraftInputsDataValidation({
					Client_Profile_Id: clientProfiles?.Client_Profile_Id,
					Request_Draft_Id: requestId ? requestId : paramRequestId,
					Service_Detail_Id: item.Service_Detail_Id,
					Lang_Id: i18n.language == 'ar' ? 1 : 2
				})
					.then((res) => {
						resetValCallback(
							res?.Data?.Changes_Actions?.Reset_Value
						);
						handleMessage(res);
						setShowSpinner(false)
					})
					.catch((err) => console.log(err));
			}
		} else {
			setShowSpinner(false)
		}
	}, []);

	return (
		<Col
			className={style.dynamics}
			key={item.Department_Service_Detail_Id}
			md={12}
			id={item.Department_Service_Detail_Title_EN}
		>
			<Form.Label
				onClick={() =>
					handleExpandLabel(
						setIsTextExpanded,
						i18n.language === "ar"
							? item?.Department_Service_Detail_Title_AR
							: item?.Department_Service_Detail_Title_EN
					)
				}
				className={
					isTextExpanded
						? styleNew.textLabel
						: styleNew.textExpandedLabel
				}
			>
				{i18n.language === "ar"
					? item?.Department_Service_Detail_Title_AR
					: item?.Department_Service_Detail_Title_EN}
				{item.Department_Service_Detail_Required ? (
					<span className={style.required}>*</span>
				) : (
					<></>
				)}
			</Form.Label>
			<div className={styleNew.inputTextContainer}>
				{/* <Form.Control
					type="phone"
					name={item.Service_Detail_Id}
					defaultValue={value}
					onKeyPress={onKeyPress}
					onBlur={(e) => {
						handleChangeWithDebounce(e);
						if (e) {
							setShowSpinner(true)
						}
					}}
					required={item.Department_Service_Detail_Required ? true : false}
				/> */}

				<PhoneInput
					ref={inputRef}
					international
					countryCallingCodeEditable={false}
					defaultCountry="AE"
					name={item.Service_Detail_Id}
					className={`${styleNew.phoneInput}`}
					style={{ direction: "ltr" }}
					value={value}
					onKeyPress={onKeyPress}
					onBlur={(e) => {
						handleChangeWithDebounce(e);
						if (e) {
							setShowSpinner(true)
						}
					}}
					onChange={val => console.log(val)}
					// onChange={val => {
					// 	setFieldValue("Client_Phone", val)
					// 	setPhoneToCheck(val)
					// }}
					required={
						item.Department_Service_Detail_Required ? true : false
					}
				/>

				{validationError ? <></> : <></>}
			</div>

			{validatedMsg?.map(vm => {
				if (vm?.Parameter_Id == item?.Service_Detail_Id) {
					return (
						<div className={styleNew.errMsg}>
							{i18n.language == 'ar' ? vm?.Errors_AR?.map(m => <div>{m}</div>) : vm?.Errors_EN?.map(m => <div>{m}</div>)}
						</div>
					)
				}
			})}

			<div className={styleNew.violationContainer}>
				{showSpinner &&
					<>
						<div className={styleNew.loadingOverlay}></div>
						<div className={[styleNew.img, i18n.language == 'ar' ? styleNew.imgAR : styleNew.imgEN].join(' ')}>
							<img width='40' src={STRIPAnimatedGif} />
						</div>
					</>
				}

				{validationObject[1]?.map((ele, key) =>
					key === 0 ? (
						<FormText
							className={[
								validationObject[0] === true
									? style.validationSuccess
									: style.validationError,
								style.validationStateFont,
							]}
						>
							<div className="font-weight-bold">
								{ele}
								{validationObject[2]?.length > 0 && (
									<span
										className={style.showViolations}
										onClick={handleOpenViolationPopup}
									>
										{i18n.language === "en"
											? " - Show Violations"
											: " - عرض المخالفات"}
									</span>
								)}
							</div>
						</FormText>
					) : (
						<FormText className={style.validationSuccess}>
							<div> {ele} </div>
						</FormText>
					)
				)}
			</div>

			<PopUp
				show={isPopUp}
				handleClose={handleOpenViolationPopup}
				title={t("violations")}
				smallModal={false}
			>
				<Grid sourceData={validationObject[2]} editEnabled={false}>
					<Column
						caption={t("violation_no")}
						alignment="center"
						dataField="Violation_Id"
						dataType="string"
					// width={120}
					/>
					<Column
						caption={t("violation_date")}
						alignment="center"
						dataField="Violation_Date"
						dataType="string"
					// width={120}
					/>
					<Column
						caption={t("violation_type")}
						alignment="center"
						dataField="Violation_Type_Name_AR"
						dataType="string"
					// width={120}
					/>
					<Column
						caption={t("violation_value")}
						alignment="center"
						dataField="Violation_Value"
						dataType="string"
					// width={120}
					/>
					<Column
						caption={t("violation_on")}
						alignment="center"
						dataField="Violation_Holder_Name_AR"
						dataType="string"
					// width={120}
					/>
					<Column
						caption={t("land_piece_no")}
						alignment="center"
						dataField="Plot_No"
						dataType="string"
					// width={120}
					/>
					<Column
						caption={t("project_no")}
						alignment="center"
						dataField="Project_No"
						dataType="string"
					// width={120}
					/>
					<Column
						caption={t("violation_description")}
						alignment="center"
						dataField="Violation_Description"
						dataType="string"
					// width={120}
					/>
				</Grid>
			</PopUp>
		</Col>
	);
};

const DateComponent = ({
	item,
	defaultValue,
	validationMessage,
	onSave,
	resetValCallback,
	newVal,
	validatedMsg,
	isFocus
}) => {
	const { i18n, t } = useTranslation("common");
	const [value, setValue] = useState(defaultValue ? defaultValue : "");
	const [validationError, setValidationError] = useState(false);
	// const [errorMessage, setErrorMessage] = useState("");
	const [isTextExpanded, setIsTextExpanded] = useState(false);
	const { clientProfiles } = useSelector((state) => state.user);
	const { currentTaskData } = useSelector(
		(state) => state.service.updateRequest
	);
	const { requestId } = useSelector((state) => state.service.serviceRequest);
	const { requestId: paramRequestId } = useParams();
	const [validationObject, setValidationObject] = useState([false, [], []]);
	const [isPopUp, setIsPopUp] = useState(false);
	const [showSpinner, setShowSpinner] = useState(false);
	const inputRef = useRef();

	useEffect(() => {
		if (newVal) {
			setValue(newVal[0]);
		}
	}, [newVal]);

	useEffect(() => {
		if (validationMessage?.Service_Detail_Validation) {
			let validationArr = [];
			let violationsArray = [];
			if (
				(validationMessage.Service_Detail_Validation_Message_EN?.trim() !==
					"" ||
					null) &&
				i18n.language === "en"
			) {
				validationArr.push(
					validationMessage.Service_Detail_Validation_Message_EN
				);
			} else if (
				validationMessage.Service_Detail_Validation_Message_AR?.trim() !==
				"" ||
				null
			) {
				validationArr.push(
					validationMessage.Service_Detail_Validation_Message_AR
				);
			}

			if (
				validationMessage.Service_Detail_Value_Contents?.trim() !==
				"" ||
				null
			) {
				validationMessage.Service_Detail_Value_Contents?.toString()
					.split("\r\n")
					.map((ele) => validationArr.push(ele));
			}

			if (
				validationMessage.Service_Detail_Violations_List !== null ||
				validationMessage.Service_Detail_Violations_List !== undefined
			) {
				violationsArray =
					validationMessage.Service_Detail_Violations_List;
			}

			setValidationObject([true, validationArr, violationsArray]);
		}
	}, [validationMessage]);

	useEffect(() => {
		if (isFocus == item.Service_Detail_Id) {
			inputRef && inputRef?.current?.instance?.focus();
		}
	}, [isFocus])

	const handleExpandLabel = () => {
		setIsTextExpanded((prev) => !prev);
	};

	const onKeyPress = (e) => {
		return true;
	};

	const handleChangeWithDebounce = debounce(async (e) => {
		await onSave(e, item, true).then(() => handleCheck());
		setValue(e?.target?.value);
	}, 500);

	let handleOpenViolationPopup = useCallback(() => {
		setIsPopUp(!isPopUp);
	}, [isPopUp]);

	let handleMessage = (res) => {
		let validationArr = [];
		let violationsArray = [];
		const {
			Service_Detail_Validation_Message_AR,
			Service_Detail_Validation_Message_EN,
			Service_Detail_Value_Contents,
			Service_Detail_Violations_List,
		} = res?.Data?.Result_Data ? res?.Data?.Result_Data : res?.Data;

		if (
			(Service_Detail_Validation_Message_EN?.trim() !== "" || null) &&
			i18n.language === "en"
		) {
			validationArr.push(Service_Detail_Validation_Message_EN);
		} else if (
			Service_Detail_Validation_Message_AR?.trim() !== "" ||
			null
		) {
			validationArr.push(Service_Detail_Validation_Message_AR);
		}

		if (Service_Detail_Value_Contents?.trim() !== "" || null) {
			Service_Detail_Value_Contents.toString()
				.split("\r\n")
				.map((ele) => validationArr.push(ele));
		}

		if (
			Service_Detail_Violations_List !== null ||
			Service_Detail_Violations_List !== undefined
		) {
			violationsArray = Service_Detail_Violations_List;
		}

		if (res?.Data?.Result_Data?.Service_Detail_Validation === 1) {
			setValidationObject([true, validationArr, violationsArray]);
		} else if (res?.Data.Result_Data?.Service_Detail_Validation === 0) {
			setValidationObject([false, validationArr, violationsArray]);
		}
	};

	let handleCheck = useCallback(() => {
		if (item.Department_Service_Detail_API_Id > 0) {
			if (window.location.href.includes("update-request")) {
				GetRequestInputsDataValidation({
					Client_Profile_Id: clientProfiles?.Client_Profile_Id,
					Request_Id: requestId ? requestId : paramRequestId,
					Task_Id: currentTaskData?.Task_Id,
					Service_Detail_Id: item.Service_Detail_Id,
					Lang_Id: i18n.language == 'ar' ? 1 : 2
				})
					.then((res) => {
						resetValCallback(
							res?.Data?.Changes_Actions?.Reset_Value
						);
						handleMessage(res);
						setShowSpinner(false)
					})
					.catch((err) => console.log(err));
			} else {
				GetDraftInputsDataValidation({
					Client_Profile_Id: clientProfiles?.Client_Profile_Id,
					Request_Draft_Id: requestId ? requestId : paramRequestId,
					Service_Detail_Id: item.Service_Detail_Id,
					Lang_Id: i18n.language == 'ar' ? 1 : 2
				})
					.then((res) => {
						resetValCallback(
							res?.Data?.Changes_Actions?.Reset_Value
						);
						handleMessage(res);
						setShowSpinner(false);
					})
					.catch((err) => console.log(err));
			}
		} else {
			setShowSpinner(false)
		}
	}, []);

	return (
		<Col
			className={style.dynamics}
			key={item.Department_Service_Detail_Id}
			md={12}
			id={item.Department_Service_Detail_Title_EN}
		>
			<Form.Label
				onClick={() =>
					handleExpandLabel(
						i18n.language === "ar"
							? item?.Department_Service_Detail_Title_AR
							: item?.Department_Service_Detail_Title_EN
					)
				}
				className={
					isTextExpanded
						? styleNew.textLabel
						: styleNew.textExpandedLabel
				}
			>
				{i18n.language === "ar"
					? item?.Department_Service_Detail_Title_AR
					: item?.Department_Service_Detail_Title_EN}
				{item.Department_Service_Detail_Required ? (
					<span className={style.required}>*</span>
				) : (
					<></>
				)}
			</Form.Label>
			<div className={styleNew.dateContainer}>
				<DateBox
					ref={inputRef}
					acceptCustomValue={true}
					name={item.Service_Detail_Id}
					defaultValue={value}
					onKeyDown={onKeyPress}
					onFocusOut={(e) => {
						handleChangeWithDebounce(e?.event);
						if (e.event) {
							setShowSpinner(true)
						}
					}}
					required={item.Department_Service_Detail_Required ? true : false}
					displayFormat="dd-MM-yyyy"
					showClearButton={true}
					inputAttr={{ 'aria-label': 'Clear' }}
					type="date"
					pickerType="calendar"
				/>
				{/* <DateBox
					acceptCustomValue={false}
					dateSerializationFormat="dd-MM-yyyy"
					displayFormat="dd-MM-yyyy"
					type="date"
					defaultValue={value}
					name={(item.Service_Detail_Id).toString()}
					showClearButton={true}
					required={item.Department_Service_Detail_Required ? true : false}
					// inputAttr={{ 'aria-label': 'Clear' }}
					onKeyDown={onKeyPress}
					onFocusOut={(e) => {
						handleChangeWithDebounce(e?.event);
						if (e.event) {
							setShowSpinner(true)
						}
						console.log('Nehaal')
						console.log(e?.target?.value)
					}}
				/> */}
				{/* <Form.Control
					type="date"
					name={item.Service_Detail_Id}
					defaultValue={value}
					onKeyPress={onKeyPress}
					onBlur={(e) => {
						handleChangeWithDebounce(e);
					}}
					required={
						item.Department_Service_Detail_Required ? true : false
					}
				/> */}
				{/* 
					{errorMessage?.length > 0 && (
							<Form.Text className={style.onError}>{errorMessage}</Form.Text>
					)} 
				*/}

				{/* 
					<div className={styleNew.inputTextIcon}>
						<div className={styleNew.numberIconContainer}>
							<button type="button">{icons.textInput}</button>
						</div>
					</div> 
				*/}
				{validationError ? <></> : <></>}
			</div>

			{validatedMsg?.map(vm => {
				if (vm?.Parameter_Id == item?.Service_Detail_Id) {
					return (
						<div className={styleNew.errMsg}>
							{i18n.language == 'ar' ? vm?.Errors_AR?.map(m => <div>{m}</div>) : vm?.Errors_EN?.map(m => <div>{m}</div>)}
						</div>
					)
				}
			})}

			<div className={styleNew.violationContainer}>
				{showSpinner &&
					<>
						<div className={styleNew.loadingOverlay}></div>
						<div className={[styleNew.img, i18n.language == 'ar' ? styleNew.imgAR : styleNew.imgEN].join(' ')}>
							<img width='40' src={STRIPAnimatedGif} />
						</div>
					</>
				}

				{validationObject[1]?.map((ele, key) =>
					key === 0 ? (
						<FormText
							className={[
								validationObject[0] === true
									? style.validationSuccess
									: style.validationError,
								style.validationStateFont,
							]}
						>
							<div className="font-weight-bold">
								{ele}
								{validationObject[2]?.length > 0 && (
									<span
										className={style.showViolations}
										onClick={handleOpenViolationPopup}
									>
										{i18n.language === "en"
											? " - Show Violations"
											: " - عرض المخالفات"}
									</span>
								)}
							</div>
						</FormText>
					) : (
						<FormText className={style.validationSuccess}>
							<div> {ele} </div>
						</FormText>
					)
				)}
			</div>

			<PopUp
				show={isPopUp}
				handleClose={handleOpenViolationPopup}
				title={t("violations")}
				smallModal={false}
			>
				<Grid sourceData={validationObject[2]} editEnabled={false}>
					<Column
						caption={t("violation_no")}
						alignment="center"
						dataField="Violation_Id"
						dataType="string"
					// width={120}
					/>
					<Column
						caption={t("violation_date")}
						alignment="center"
						dataField="Violation_Date"
						dataType="string"
					// width={120}
					/>
					<Column
						caption={t("violation_type")}
						alignment="center"
						dataField="Violation_Type_Name_AR"
						dataType="string"
					// width={120}
					/>
					<Column
						caption={t("violation_value")}
						alignment="center"
						dataField="Violation_Value"
						dataType="string"
					// width={120}
					/>
					<Column
						caption={t("violation_on")}
						alignment="center"
						dataField="Violation_Holder_Name_AR"
						dataType="string"
					// width={120}
					/>
					<Column
						caption={t("land_piece_no")}
						alignment="center"
						dataField="Plot_No"
						dataType="string"
					// width={120}
					/>
					<Column
						caption={t("project_no")}
						alignment="center"
						dataField="Project_No"
						dataType="string"
					// width={120}
					/>
					<Column
						caption={t("violation_description")}
						alignment="center"
						dataField="Violation_Description"
						dataType="string"
					// width={120}
					/>
				</Grid>
			</PopUp>
		</Col>
	);
};

const NumberComponent = ({
	item,
	defaultValue,
	validationMessage,
	onSave,
	resetValCallback,
	newVal,
	validatedMsg,
	isFocus
}) => {
	const { i18n, t } = useTranslation("common");
	const [value, setValue] = useState(defaultValue() ? defaultValue() : 0);
	const [validationError, setValidationError] = useState(false);
	const [isTextExpanded, setIsTextExpanded] = useState(false);
	const { clientProfiles } = useSelector((state) => state.user);
	const { currentTaskData } = useSelector(
		(state) => state.service.updateRequest
	);
	const { requestId } = useSelector((state) => state.service.serviceRequest);
	const { requestId: paramRequestId } = useParams();
	const [validationObject, setValidationObject] = useState([false, [], []]);
	const [isPopUp, setIsPopUp] = useState(false);
	const [showSpinner, setShowSpinner] = useState(false);
	const inputRef = useRef();

	useEffect(() => {
		if (newVal) {
			setValue(newVal[0]);
		}
	}, [newVal]);

	useEffect(() => {
		if (isFocus == item.Service_Detail_Id) {
			inputRef && inputRef?.current?.focus();
		}
	}, [isFocus])

	const handleExpandLabel = () => {
		setIsTextExpanded((prev) => !prev);
	};

	const handleNumberUpClick = () => {
		// if (response?.RESULT_CODE === 200) {
		//   i18n.language === "en"
		//     ? setErrorMessage(response?.Error_EN)
		//     : setErrorMessage(response?.Error_AR);
		// }

		setValue((prev) => {
			let val = ++prev;
			let obj = { target: {} };

			obj.target.value = val;
			debounce(async () => {
				await onSave(obj, item);
			}, 500)();
			return val;
		});
	};

	const handleNumberDownClick = () => {
		// if (response?.RESULT_CODE === 200) {
		//   i18n.language === "en"
		//     ? setErrorMessage(response?.Error_EN)
		//     : setErrorMessage(response?.Error_AR);
		// }

		setValue((prev) => {
			let val = --prev;
			let obj = { target: {} };

			obj.target.value = val;
			debounce(async () => {
				await onSave(obj, item);
			}, 500)();
			return val;
		});
	};

	const onKeyPress = (e) => {
		//   setValidationError(false);
		// }
		return true;
	}; // if (validationError) {

	// const handleChangeWithDebounce = (e) => {
	// 	debounce(async () => {
	// 		await onSave(e, item);
	// 	}, 500)();
	// 	setValue(e?.target?.value);
	// 	;
	// };

	const handleChangeWithDebounce = debounce(async (e) => {
		setValue(e?.target?.value);
		await onSave(e, item).then(() => {
			handleCheck();
		});
	}, 500);

	let handleOpenViolationPopup = useCallback(() => {
		setIsPopUp(!isPopUp);
	}, [isPopUp]);

	let handleMessage = (res) => {
		let validationArr = [];
		let violationsArray = [];
		const {
			Service_Detail_Validation_Message_AR,
			Service_Detail_Validation_Message_EN,
			Service_Detail_Value_Contents,
			Service_Detail_Violations_List,
		} = res?.Data?.Result_Data ? res?.Data?.Result_Data : res?.Data;

		if (
			(Service_Detail_Validation_Message_EN?.trim() !== "" || null) &&
			i18n.language === "en"
		) {
			validationArr.push(Service_Detail_Validation_Message_EN);
		} else if (
			Service_Detail_Validation_Message_AR?.trim() !== "" ||
			null
		) {
			validationArr.push(Service_Detail_Validation_Message_AR);
		}

		if (Service_Detail_Value_Contents?.trim() !== "" || null) {
			Service_Detail_Value_Contents.toString()
				.split("\r\n")
				.map((ele) => validationArr.push(ele));
		}

		if (
			Service_Detail_Violations_List !== null ||
			Service_Detail_Violations_List !== undefined
		) {
			violationsArray = Service_Detail_Violations_List;
		}

		if (res?.Data?.Result_Data.Service_Detail_Validation === 1) {
			setValidationObject([true, validationArr, violationsArray]);
		} else if (res?.Data.Result_Data.Service_Detail_Validation === 0) {
			setValidationObject([false, validationArr, violationsArray]);
		}
	};

	useEffect(() => {
		if (validationMessage?.Service_Detail_Validation) {
			let validationArr = [];
			let violationsArray = [];
			if (
				(validationMessage.Service_Detail_Validation_Message_EN?.trim() !==
					"" ||
					null) &&
				i18n.language === "en"
			) {
				validationArr.push(
					validationMessage.Service_Detail_Validation_Message_EN
				);
			} else if (
				validationMessage.Service_Detail_Validation_Message_AR?.trim() !==
				"" ||
				null
			) {
				validationArr.push(
					validationMessage.Service_Detail_Validation_Message_AR
				);
			}

			if (
				validationMessage.Service_Detail_Value_Contents?.trim() !==
				"" ||
				null
			) {
				validationMessage.Service_Detail_Value_Contents?.toString()
					.split("\r\n")
					.map((ele) => validationArr.push(ele));
			}

			if (
				validationMessage.Service_Detail_Violations_List !== null ||
				validationMessage.Service_Detail_Violations_List !== undefined
			) {
				violationsArray =
					validationMessage.Service_Detail_Violations_List;
			}

			setValidationObject([true, validationArr, violationsArray]);
		}
	}, [validationMessage]);

	let handleCheck = useCallback(() => {
		if (item.Department_Service_Detail_API_Id > 0) {
			if (window.location.href.includes("update-request")) {
				GetRequestInputsDataValidation({
					Client_Profile_Id: clientProfiles?.Client_Profile_Id,
					Request_Id: requestId ? requestId : paramRequestId,
					Task_Id: currentTaskData?.Task_Id,
					Service_Detail_Id: item.Service_Detail_Id,
					Lang_Id: i18n.language == 'ar' ? 1 : 2
				})
					.then((res) => {
						resetValCallback(
							res?.Data?.Changes_Actions?.Reset_Value
						);
						handleMessage(res);
						setShowSpinner(false)
					})
					.catch((err) => console.log(err));
			} else {
				GetDraftInputsDataValidation({
					Client_Profile_Id: clientProfiles?.Client_Profile_Id,
					Request_Draft_Id: requestId ? requestId : paramRequestId,
					Service_Detail_Id: item.Service_Detail_Id,
					Lang_Id: i18n.language == 'ar' ? 1 : 2
				})
					.then((res) => {
						resetValCallback(
							res?.Data?.Changes_Actions?.Reset_Value
						);
						handleMessage(res);
						setShowSpinner(false)
					})
					.catch((err) => console.log(err));
			}
		} else {
			setShowSpinner(false)
		}
	}, []);

	return (
		<Col className={style.dynamics} key={item.Department_Service_Detail_Id} id={item.Department_Service_Detail_Title_EN}>
			<Form.Label
				onClick={() =>
					handleExpandLabel(
						setIsTextExpanded,
						i18n.language === "ar"
							? item?.Department_Service_Detail_Title_AR
							: item?.Department_Service_Detail_Title_EN
					)
				}
				className={
					isTextExpanded
						? styleNew.textLabel
						: styleNew.textExpandedLabel
				}
			>
				{i18n.language === "ar"
					? item?.Department_Service_Detail_Title_AR
					: item?.Department_Service_Detail_Title_EN}
				{item.Department_Service_Detail_Required ? (
					<span className={style.required}>*</span>
				) : (
					<></>
				)}
			</Form.Label>
			<div className={styleNew.inputTextContainer}>
				<Form.Control
					ref={inputRef}
					type="number"
					name={item.Service_Detail_Id}
					defaultValue={value}
					onKeyPress={onKeyPress}
					onBlur={(e) => {
						handleChangeWithDebounce(e);
						if (e) {
							setShowSpinner(true)
						}
					}}
					onChange={(e) => {
						handleChangeWithDebounce(e);
						if (e) {
							setShowSpinner(true)
						}
					}}
					required={item.Department_Service_Detail_Required ? true : false}
				/>
			</div>


			{validatedMsg?.map(vm => {
				if (vm?.Parameter_Id == item?.Service_Detail_Id) {
					return (
						<div className={styleNew.errMsg}>
							{i18n.language == 'ar' ? vm?.Errors_AR?.map(m => <div>{m}</div>) : vm?.Errors_EN?.map(m => <div>{m}</div>)}
						</div>
					)
				}
			})}

			<div className={styleNew.violationContainer}>
				{showSpinner &&
					<>
						<div className={styleNew.loadingOverlay}></div>
						<div className={[styleNew.img, i18n.language == 'ar' ? styleNew.imgAR : styleNew.imgEN].join(' ')}>
							<img width='40' src={STRIPAnimatedGif} />
						</div>
					</>
				}

				{validationError ? <></> : <></>}

				{validationObject[1]?.map((ele, key) =>
					key === 0 ? (
						<FormText
							className={[
								validationObject[0] === true
									? style.validationSuccess
									: style.validationError,
								style.validationStateFont,
							]}
						>
							<div className="font-weight-bold">
								{ele}
								{validationObject[2]?.length > 0 && (
									<span
										className={style.showViolations}
										onClick={handleOpenViolationPopup}
									>
										{i18n.language === "en"
											? " - Show Violations"
											: " - عرض المخالفات"}
									</span>
								)}
							</div>
						</FormText>
					) : (
						<FormText className={style.validationSuccess}>
							<div> {ele} </div>
						</FormText>
					)
				)}
			</div>

			<PopUp
				show={isPopUp}
				handleClose={handleOpenViolationPopup}
				title={t("violations")}
				smallModal={false}
			>
				<Grid sourceData={validationObject[2]} editEnabled={false}>
					<Column
						caption={t("violation_no")}
						alignment="center"
						dataField="Violation_Id"
						dataType="string"
					// width={120}
					/>
					<Column
						caption={t("violation_date")}
						alignment="center"
						dataField="Violation_Date"
						dataType="string"
					// width={120}
					/>
					<Column
						caption={t("violation_type")}
						alignment="center"
						dataField="Violation_Type_Name_AR"
						dataType="string"
					// width={120}
					/>
					<Column
						caption={t("violation_value")}
						alignment="center"
						dataField="Violation_Value"
						dataType="string"
					// width={120}
					/>
					<Column
						caption={t("violation_on")}
						alignment="center"
						dataField="Violation_Holder_Name_AR"
						dataType="string"
					// width={120}
					/>
					<Column
						caption={t("land_piece_no")}
						alignment="center"
						dataField="Plot_No"
						dataType="string"
					// width={120}
					/>
					<Column
						caption={t("project_no")}
						alignment="center"
						dataField="Project_No"
						dataType="string"
					// width={120}
					/>
					<Column
						caption={t("violation_description")}
						alignment="center"
						dataField="Violation_Description"
						dataType="string"
					// width={120}
					/>
				</Grid>
			</PopUp>
		</Col>
	);
};

const EmailComponent = ({
	item,
	defaultValue,
	validationMessage,
	onSave,
	resetValCallback,
	newVal,
	validatedMsg,
	isFocus
}) => {
	const { i18n, t } = useTranslation("common");
	const [value, setValue] = useState(defaultValue ? defaultValue : "");
	const [validationError, setValidationError] = useState(false);
	// const [errorMessage, setErrorMessage] = useState("");
	const [isTextExpanded, setIsTextExpanded] = useState(false);
	const { clientProfiles } = useSelector((state) => state.user);
	const { currentTaskData } = useSelector((state) => state.service.updateRequest);
	const { requestId } = useSelector((state) => state.service.serviceRequest);
	const { requestId: paramRequestId } = useParams();
	const [validationObject, setValidationObject] = useState([false, [], []]);
	const [isPopUp, setIsPopUp] = useState(false);
	const [showSpinner, setShowSpinner] = useState(false);
	const inputRef = useRef();

	useEffect(() => {
		if (newVal) {
			setValue(newVal[0]);
		}
	}, [newVal]);

	useEffect(() => {
		if (validationMessage?.Service_Detail_Validation) {
			let validationArr = [];
			let violationsArray = [];
			if (
				(validationMessage.Service_Detail_Validation_Message_EN?.trim() !==
					"" ||
					null) &&
				i18n.language === "en"
			) {
				validationArr.push(
					validationMessage.Service_Detail_Validation_Message_EN
				);
			} else if (
				validationMessage.Service_Detail_Validation_Message_AR?.trim() !==
				"" ||
				null
			) {
				validationArr.push(
					validationMessage.Service_Detail_Validation_Message_AR
				);
			}

			if (
				validationMessage.Service_Detail_Value_Contents?.trim() !==
				"" ||
				null
			) {
				validationMessage.Service_Detail_Value_Contents?.toString()
					.split("\r\n")
					.map((ele) => validationArr.push(ele));
			}

			if (
				validationMessage.Service_Detail_Violations_List !== null ||
				validationMessage.Service_Detail_Violations_List !== undefined
			) {
				violationsArray =
					validationMessage.Service_Detail_Violations_List;
			}

			setValidationObject([true, validationArr, violationsArray]);
		}
	}, [validationMessage]);

	useEffect(() => {
		if (isFocus == item.Service_Detail_Id) {
			inputRef && inputRef?.current?.focus();
		}
	}, [isFocus])

	const handleExpandLabel = () => {
		setIsTextExpanded((prev) => !prev);
	};

	const onKeyPress = (e) => {
		//   setValidationError(false);
		// }
		return true;
	}; // if (validationError) {

	const handleChangeWithDebounce = debounce(async (e) => {
		await onSave(e, item).then((res) => {
			handleCheck();
		});
		setValue(e?.target?.value);
	}, 500);

	let handleOpenViolationPopup = useCallback(() => {
		setIsPopUp(!isPopUp);
	}, [isPopUp]);

	let handleMessage = (res) => {
		let validationArr = [];
		let violationsArray = [];
		const {
			Service_Detail_Validation_Message_AR,
			Service_Detail_Validation_Message_EN,
			Service_Detail_Value_Contents,
			Service_Detail_Violations_List,
		} = res?.Data?.Result_Data ? res?.Data?.Result_Data : res?.Data;

		if (
			(Service_Detail_Validation_Message_EN?.trim() !== "" || null) &&
			i18n.language === "en"
		) {
			validationArr.push(Service_Detail_Validation_Message_EN);
		} else if (
			Service_Detail_Validation_Message_AR?.trim() !== "" ||
			null
		) {
			validationArr.push(Service_Detail_Validation_Message_AR);
		}

		if (Service_Detail_Value_Contents?.trim() !== "" || null) {
			Service_Detail_Value_Contents.toString()
				.split("\r\n")
				.map((ele) => validationArr.push(ele));
		}

		if (
			Service_Detail_Violations_List !== null ||
			Service_Detail_Violations_List !== undefined
		) {
			violationsArray = Service_Detail_Violations_List;
		}

		if (res?.Data?.Result_Data?.Service_Detail_Validation === 1) {
			setValidationObject([true, validationArr, violationsArray]);
		} else if (res?.Data.Result_Data?.Service_Detail_Validation === 0) {
			setValidationObject([false, validationArr, violationsArray]);
		}
	};

	let handleCheck = useCallback(() => {
		if (item.Department_Service_Detail_API_Id > 0) {
			if (window.location.href.includes("update-request")) {
				GetRequestInputsDataValidation({
					Client_Profile_Id: clientProfiles?.Client_Profile_Id,
					Request_Id: requestId ? requestId : paramRequestId,
					Task_Id: currentTaskData?.Task_Id,
					Service_Detail_Id: item.Service_Detail_Id,
					Lang_Id: i18n.language == 'ar' ? 1 : 2
				})
					.then((res) => {
						resetValCallback(
							res?.Data?.Changes_Actions?.Reset_Value
						);
						handleMessage(res);
						setShowSpinner(false)
					})
					.catch((err) => console.log(err));
			} else {
				GetDraftInputsDataValidation({
					Client_Profile_Id: clientProfiles?.Client_Profile_Id,
					Request_Draft_Id: requestId ? requestId : paramRequestId,
					Service_Detail_Id: item.Service_Detail_Id,
					Lang_Id: i18n.language == 'ar' ? 1 : 2
				})
					.then((res) => {
						resetValCallback(
							res?.Data?.Changes_Actions?.Reset_Value
						);
						handleMessage(res);
						setShowSpinner(false)
					})
					.catch((err) => console.log(err));
			}
		} else {
			setShowSpinner(false)
		}
	}, []);

	return (
		<Col
			// className={style.dynamics}
			key={item.Department_Service_Detail_Id}
			md={12}
			id={item.Department_Service_Detail_Title_EN}
		>
			<Form.Label
				onClick={() =>
					handleExpandLabel(
						i18n.language === "ar"
							? item?.Department_Service_Detail_Title_AR
							: item?.Department_Service_Detail_Title_EN
					)
				}
				className={
					isTextExpanded
						? styleNew.textLabel
						: styleNew.textExpandedLabel
				}
			>
				{i18n.language === "ar"
					? item?.Department_Service_Detail_Title_AR
					: item?.Department_Service_Detail_Title_EN}
				{item.Department_Service_Detail_Required ? (
					<span className={style.required}>*</span>
				) : (
					<></>
				)}
			</Form.Label>

			<div className={styleNew.inputTextContainer}>
				<Form.Control
					ref={inputRef}
					type="email"
					name={item.Service_Detail_Id}
					defaultValue={value}
					onKeyPress={onKeyPress}
					onBlur={(e) => {
						handleChangeWithDebounce(e);
						if (e) {
							setShowSpinner(true)
						}
					}}
					required={item.Department_Service_Detail_Required ? true : false}
				/>
			</div>

			{validatedMsg?.map(vm => {
				if (vm?.Parameter_Id == item?.Service_Detail_Id) {
					return (
						<div className={styleNew.errMsg}>
							{i18n.language == 'ar' ? vm?.Errors_AR?.map(m => <div>{m}</div>) : vm?.Errors_EN?.map(m => <div>{m}</div>)}
						</div>
					)
				}
			})}

			<div className={styleNew.violationContainer}>
				{showSpinner &&
					<>
						<div className={styleNew.loadingOverlay}></div>
						<div className={[styleNew.img, i18n.language == 'ar' ? styleNew.imgAR : styleNew.imgEN].join(' ')}>
							<img width='40' src={STRIPAnimatedGif} />
						</div>
					</>
				}

				{validationError ? <></> : <></>}


				{validationObject[1]?.map((ele, key) =>
					key === 0 ? (
						<FormText
							className={[
								validationObject[0] === true
									? style.validationSuccess
									: style.validationError,
								style.validationStateFont,
							]}
						>
							<div className="font-weight-bold">
								{ele}
								{validationObject[2]?.length > 0 && (
									<span
										className={style.showViolations}
										onClick={handleOpenViolationPopup}
									>
										{i18n.language === "en"
											? " - Show Violations"
											: " - عرض المخالفات"}
									</span>
								)}
							</div>
						</FormText>
					) : (
						<FormText className={style.validationSuccess}>
							<div> {ele} </div>
						</FormText>
					)
				)}
			</div>

			<PopUp
				show={isPopUp}
				handleClose={handleOpenViolationPopup}
				title={t("violations")}
				smallModal={false}
			>
				<Grid sourceData={validationObject[2]} editEnabled={false}>
					<Column
						caption={t("violation_no")}
						alignment="center"
						dataField="Violation_Id"
						dataType="string"
					// width={120}
					/>
					<Column
						caption={t("violation_date")}
						alignment="center"
						dataField="Violation_Date"
						dataType="string"
					// width={120}
					/>
					<Column
						caption={t("violation_type")}
						alignment="center"
						dataField="Violation_Type_Name_AR"
						dataType="string"
					// width={120}
					/>
					<Column
						caption={t("violation_value")}
						alignment="center"
						dataField="Violation_Value"
						dataType="string"
					// width={120}
					/>
					<Column
						caption={t("violation_on")}
						alignment="center"
						dataField="Violation_Holder_Name_AR"
						dataType="string"
					// width={120}
					/>
					<Column
						caption={t("land_piece_no")}
						alignment="center"
						dataField="Plot_No"
						dataType="string"
					// width={120}
					/>
					<Column
						caption={t("project_no")}
						alignment="center"
						dataField="Project_No"
						dataType="string"
					// width={120}
					/>
					<Column
						caption={t("violation_description")}
						alignment="center"
						dataField="Violation_Description"
						dataType="string"
					// width={120}
					/>
				</Grid>
			</PopUp>
		</Col>
	);
};

const TextareaComponent = ({
	item,
	defaultValue,
	validationMessage,
	onSave,
	resetValCallback,
	newVal,
	validatedMsg,
	isFocus
}) => {
	const { t, i18n } = useTranslation("common");
	const [value, setValue] = useState(defaultValue ? defaultValue : "");
	const [validationError, setValidationError] = useState(false);
	// const [errorMessage, setErrorMessage] = useState("");
	const [isTextExpanded, setIsTextExpanded] = useState(false);
	const { clientProfiles } = useSelector((state) => state.user);
	const { currentTaskData } = useSelector((state) => state.service.updateRequest);
	const { requestId } = useSelector((state) => state.service.serviceRequest);
	const { requestId: paramRequestId } = useParams();
	const [validationObject, setValidationObject] = useState([false, [], []]);
	const [isPopUp, setIsPopUp] = useState(false);
	const [showSpinner, setShowSpinner] = useState(false);
	const inputRef = useRef();

	useEffect(() => {
		if (newVal) {
			setValue(newVal[0]);
		}
	}, [newVal]);

	useEffect(() => {
		if (validationMessage?.Service_Detail_Validation) {
			let validationArr = [];
			let violationsArray = [];
			if (
				(validationMessage.Service_Detail_Validation_Message_EN?.trim() !==
					"" ||
					null) &&
				i18n.language === "en"
			) {
				validationArr.push(
					validationMessage.Service_Detail_Validation_Message_EN
				);
			} else if (
				validationMessage.Service_Detail_Validation_Message_AR?.trim() !==
				"" ||
				null
			) {
				validationArr.push(
					validationMessage.Service_Detail_Validation_Message_AR
				);
			}

			if (
				validationMessage.Service_Detail_Value_Contents?.trim() !==
				"" ||
				null
			) {
				validationMessage.Service_Detail_Value_Contents?.toString()
					.split("\r\n")
					.map((ele) => validationArr.push(ele));
			}

			if (
				validationMessage.Service_Detail_Violations_List !== null ||
				validationMessage.Service_Detail_Violations_List !== undefined
			) {
				violationsArray =
					validationMessage.Service_Detail_Violations_List;
			}

			setValidationObject([true, validationArr, violationsArray]);
		}
	}, [validationMessage]);

	useEffect(() => {
		if (isFocus == item.Service_Detail_Id) {
			inputRef && inputRef?.current?.focus();
		}
	}, [isFocus])

	const handleExpandLabel = () => {
		setIsTextExpanded((prev) => !prev);
	};

	const onKeyPress = (e) => {
		setValidationError(false);
		var ew = e.which;
		// if (ew != 8) {
		//   // if ((ew < 48 || ew > 57) && (ew < 0x0600 || ew > 0x06ff) && ew != 32) {
		//   if (ew >= 65 && ew <= 120 && ew != 32 && ew != 0) {
		//     setValidationError(true);
		//     e.preventDefault();
		//     return false;
		//   }
		// }

		if (item.Department_Service_Detail_Lang_Id == 1) {
			if (ew != 8) {
				if (ew >= 65 && ew <= 120 && ew != 32 && ew != 0) {
					setValidationError(true);
					e.preventDefault();
					return false;
				}
			}
		} else if (item.Department_Service_Detail_Lang_Id == 2) {
			if (ew == 32) {
				return true;
			} else if (48 <= ew && ew <= 57) {
				return true;
			} else if (65 <= ew && ew <= 90) {
				return true;
			} else if (97 <= ew && ew <= 122) {
				return true;
			} else {
				setValidationError(true);
				e.preventDefault();
				return false;
			}
		}

		if (item.Department_Service_Detail_Allow_Symbols == 0) {
			if (
				ew == 63 ||
				ew == 47 ||
				ew == 62 ||
				ew == 60 ||
				ew == 46 ||
				ew == 44 ||
				ew == 34 ||
				ew == 58 ||
				ew == 124 ||
				ew == 39 ||
				ew == 59 ||
				ew == 125 ||
				ew == 123 ||
				ew == 126 ||
				ew == 33 ||
				ew == 64 ||
				ew == 35 ||
				ew == 36 ||
				ew == 37 ||
				ew == 38 ||
				ew == 42 ||
				ew == 40 ||
				ew == 41 ||
				ew == 43 ||
				ew == 61 ||
				ew == 45 ||
				ew == 1567 ||
				ew == 1617
			) {
				e.preventDefault();
				return false;
			}
		}

		// if (validationError) {
		// 	setValidationError(false);
		// }
		return true;
	};

	const handleChangeWithDebounce = debounce(async (e) => {
		await onSave(e, item).then(() => handleCheck());
		setValue(e?.target?.value);
	}, 500);

	let handleOpenViolationPopup = useCallback(() => {
		setIsPopUp(!isPopUp);
	}, [isPopUp]);

	let handleMessage = (res) => {
		let validationArr = [];
		let violationsArray = [];
		const {
			Service_Detail_Validation_Message_AR,
			Service_Detail_Validation_Message_EN,
			Service_Detail_Value_Contents,
			Service_Detail_Violations_List,
		} = res?.Data?.Result_Data ? res?.Data?.Result_Data : res?.Data;

		if (
			(Service_Detail_Validation_Message_EN?.trim() !== "" || null) &&
			i18n.language === "en"
		) {
			validationArr.push(Service_Detail_Validation_Message_EN);
		} else if (
			Service_Detail_Validation_Message_AR?.trim() !== "" ||
			null
		) {
			validationArr.push(Service_Detail_Validation_Message_AR);
		}

		if (Service_Detail_Value_Contents?.trim() !== "" || null) {
			Service_Detail_Value_Contents.toString()
				.split("\r\n")
				.map((ele) => validationArr.push(ele));
		}

		if (
			Service_Detail_Violations_List !== null ||
			Service_Detail_Violations_List !== undefined
		) {
			violationsArray = Service_Detail_Violations_List;
		}

		if (res?.Data?.Result_Data.Service_Detail_Validation === 1) {
			setValidationObject([true, validationArr, violationsArray]);
		} else if (res?.Data.Result_Data.Service_Detail_Validation === 0) {
			setValidationObject([false, validationArr, violationsArray]);
		}
	};

	let handleCheck = useCallback(() => {
		if (item.Department_Service_Detail_API_Id > 0) {
			if (window.location.href.includes("update-request")) {
				GetRequestInputsDataValidation({
					Client_Profile_Id: clientProfiles?.Client_Profile_Id,
					Request_Id: requestId ? requestId : paramRequestId,
					Task_Id: currentTaskData?.Task_Id,
					Service_Detail_Id: item.Service_Detail_Id,
					Lang_Id: i18n.language == 'ar' ? 1 : 2
				})
					.then((res) => {
						resetValCallback(
							res?.Data?.Changes_Actions?.Reset_Value
						);
						handleMessage(res);
						setShowSpinner(false)
					})
					.catch((err) => console.log(err));
			} else {
				GetDraftInputsDataValidation({
					Client_Profile_Id: clientProfiles?.Client_Profile_Id,
					Request_Draft_Id: requestId ? requestId : paramRequestId,
					Service_Detail_Id: item.Service_Detail_Id,
					Lang_Id: i18n.language == 'ar' ? 1 : 2
				})
					.then((res) => {
						resetValCallback(
							res?.Data?.Changes_Actions?.Reset_Value
						);
						handleMessage(res);
						setShowSpinner(false)
					})
					.catch((err) => console.log(err));
			}
		} else {
			setShowSpinner(false)
		}
	}, []);

	return (
		<Col key={item.Department_Service_Detail_Id} md={12} id={item.Department_Service_Detail_Title_EN}>
			<Form.Label
				onClick={() =>
					handleExpandLabel(
						i18n.language === "ar"
							? item?.Department_Service_Detail_Title_AR
							: item?.Department_Service_Detail_Title_EN
					)
				}
				className={
					isTextExpanded
						? styleNew.textLabel
						: styleNew.textExpandedLabel
				}
			>
				{i18n.language === "ar"
					? item?.Department_Service_Detail_Title_AR
					: item?.Department_Service_Detail_Title_EN}
				{item.Department_Service_Detail_Required ? (
					<span className={style.required}>*</span>
				) : (
					<></>
				)}
			</Form.Label>
			<div className={styleNew.inputTextContainer}>
				<Form.Control
					ref={inputRef}
					as="textarea"
					name={item.Service_Detail_Id}
					defaultValue={value}
					onKeyPress={onKeyPress}
					onBlur={(e) => {
						handleChangeWithDebounce(e);
						if (e) {
							setShowSpinner(true)
						}
					}}
					required={item.Department_Service_Detail_Required ? true : false}
				/>

				{validatedMsg?.map(vm => {
					if (vm?.Parameter_Id == item?.Service_Detail_Id) {
						return (
							<div className={styleNew.errMsg}>
								{i18n.language == 'ar' ? vm?.Errors_AR?.map(m => <div>{m}</div>) : vm?.Errors_EN?.map(m => <div>{m}</div>)}
							</div>
						)
					}
				})}

				<div className={styleNew.violationContainer}>
					{showSpinner &&
						<>
							<div className={styleNew.loadingOverlay}></div>
							<div className={[styleNew.img, i18n.language == 'ar' ? styleNew.imgAR : styleNew.imgEN].join(' ')}>
								<img width='40' src={STRIPAnimatedGif} />
							</div>
						</>
					}

					{validationError &&
						item.Department_Service_Detail_Lang_Id == 1 ? (
						<Form.Text className={style.onError}>
							{t("Write Arabic only")}
						</Form.Text>
					) : validationError &&
						item.Department_Service_Detail_Lang_Id == 2 ? (
						<Form.Text className={style.onError}>
							{t("Write English only")}
						</Form.Text>
					) : null}

					{validationObject[1]?.map((ele, key) =>
						key === 0 ? (
							<FormText
								className={[
									validationObject[0] === true
										? style.validationSuccess
										: style.validationError,
									style.validationStateFont,
								]}
							>
								<div className="font-weight-bold">
									{ele}
									{validationObject[2]?.length > 0 && (
										<span
											className={style.showViolations}
											onClick={handleOpenViolationPopup}
										>
											{i18n.language === "en"
												? " - Show Violations"
												: " - عرض المخالفات"}
										</span>
									)}
								</div>
							</FormText>
						) : (
							<FormText className={style.validationSuccess}>
								<div> {ele} </div>
							</FormText>
						)
					)}
				</div>

				<PopUp
					show={isPopUp}
					handleClose={handleOpenViolationPopup}
					title={t("violations")}
					smallModal={false}
				>
					<Grid sourceData={validationObject[2]} editEnabled={false}>
						<Column
							caption={t("violation_no")}
							alignment="center"
							dataField="Violation_Id"
							dataType="string"
						// width={120}
						/>
						<Column
							caption={t("violation_date")}
							alignment="center"
							dataField="Violation_Date"
							dataType="string"
						// width={120}
						/>
						<Column
							caption={t("violation_type")}
							alignment="center"
							dataField="Violation_Type_Name_AR"
							dataType="string"
						// width={120}
						/>
						<Column
							caption={t("violation_value")}
							alignment="center"
							dataField="Violation_Value"
							dataType="string"
						// width={120}
						/>
						<Column
							caption={t("violation_on")}
							alignment="center"
							dataField="Violation_Holder_Name_AR"
							dataType="string"
						// width={120}
						/>
						<Column
							caption={t("land_piece_no")}
							alignment="center"
							dataField="Plot_No"
							dataType="string"
						// width={120}
						/>
						<Column
							caption={t("project_no")}
							alignment="center"
							dataField="Project_No"
							dataType="string"
						// width={120}
						/>
						<Column
							caption={t("violation_description")}
							alignment="center"
							dataField="Violation_Description"
							dataType="string"
						// width={120}
						/>
					</Grid>
				</PopUp>
			</div>
		</Col>
	);
};

const HtmlDisplay = ({ item }) => {
	const { t, i18n } = useTranslation("common");

	return (
		<div id={item.Service_Detail_Id}>
			<style jsx global>{`
				p {
					margin: 0px !important;
				}
			`}</style>
			<p
				dangerouslySetInnerHTML={{
					__html:
						i18n.language === "en"
							? item.Department_Service_Detail_Label_Text_EN
							: item.Department_Service_Detail_Label_Text_AR,
				}}
			/>
		</div>
	);
};
