import React from "react";
import style from "./SearchList.module.scss";
import { useTranslation } from "react-i18next";
import { Row, Col, Form } from "react-bootstrap";
import { NavLink, useParams } from "react-router-dom";

const SearchList = (props) => {
  const { t, i18n } = useTranslation("common");
  let { municipalId } = useParams();

  return (
  
      <div className="container-fluid mt-sm-2">

      <Row>
        <Col>
          <Form>
            <Form.Group>
              <Form.Control className={style.searchInput} type="text" value={props.searchVal} onChange={(e) => props.getSearchVal(e.target.value)} placeholder={t("search_for_services")} />
              <span className={[style.searchIcon, i18n.language === "en" ? style.searchIconEn : style.searchIconAr].join(" ")}>
                <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                  <title>search-line</title>
                  <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="0" transform="translate(-584.000000, -222.000000)">
                      <g id="search-line" transform="translate(584.000000, 222.000000)">
                        <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                        <path
                          d="M18.031,16.617 L22.314,20.899 L20.899,22.314 L16.617,18.031 C15.0237382,19.3082211 13.0420011,20.0029052 11,20 C6.032,20 2,15.968 2,11 C2,6.032 6.032,2 11,2 C15.968,2 20,6.032 20,11 C20.0029052,13.0420011 19.3082211,15.0237382 18.031,16.617 Z M16.025,15.875 C17.2941129,14.5698857 18.002867,12.8204293 18,11 C18,7.132 14.867,4 11,4 C7.132,4 4,7.132 4,11 C4,14.867 7.132,18 11,18 C12.8204293,18.002867 14.5698857,17.2941129 15.875,16.025 L16.025,15.875 L16.025,15.875 Z"
                          id="Shape"
                          fill="#000000"
                          fillRule="nonzero"></path>
                      </g>
                    </g>
                  </g>
                </svg>
              </span>
            </Form.Group>
          </Form>
        </Col>
      </Row>
     
        <Row className="align-items-center">
          <Col md={8} sm={6}>
            <div className="d-flex align-items-center">
              <span className={style.searchKeys}>{props.activeTabName}</span>
            </div>
          </Col>

          <Col md={4} sm={6}>
            <div className="d-flex justify-content-end align-items-center">
              <p className={style.searchResults}>
                {t("search_results")} {props.serviceList.length}
              </p>

            </div>
          </Col>
        </Row>

   
        <div className="row justify-content-sm-center">
          {props.serviceList
            .filter((service) => {
              if (!props.searchVal) return true;
              if (i18n.language === "en") {
                if (service.Service_Name_EN.includes(props.searchVal) || service.Service_Description_EN.includes(props.searchVal)) {
                  return true;
                }
              } else {
                if (service.Service_Name_AR.includes(props.searchVal) || service.Service_Description_AR.includes(props.searchVal)) {
                  return true;
                }
              }
            })
            .map((service) => (
              <Col md={12} key={service.Service_Id}>
                <div className={[style.serviceContainer, i18n.language === "en" ? style.arrowEn : null].join(" ")}>
                  <p className={style.serviceTitle}>{i18n.language === "en" ? service.Service_Name_EN : service.Service_Name_AR}</p>
                  <p className={style.serviceDesc}>{i18n.language === "en" ? service.Service_Description_EN : service.Service_Description_AR}</p>
                  <span className="d-flex align-items-center">
                    {/* <NavLink className={style.serviceDetails} to={`../service-card/${municipalId}/${props.activeTabId}/${service.Service_Id}`}> */}
                    <NavLink className={style.serviceDetails} to={`/service-card/${service.Service_Id}`}>
                      {t("service_details")}
                    </NavLink>
                  </span>
                </div>
              </Col>
            ))}
        </div>
        </div>
 
  );
};

export default SearchList;
