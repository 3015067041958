import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import style from "./MunicipalPara.module.scss";
import axios from "axios";
import { useDispatch } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { departmentDataSelected } from "features/service/serviceMunicipSlice";

const MunicipalPara = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation("common");
  const [departmentData, setDepartmentData] = useState([]);
  const [loading, setLoading] = useState(false);
  let { municipalId } = useParams();

  useEffect(() => {
    const fetchDepartmentData = async () => {
      setLoading(true);
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/PORTAL/DEPARTMENTS/GET_DEPARTMENT_INFO`, {
        Department_Id: municipalId,
      });
      setDepartmentData(res.data.Data);
      setLoading(false);
    };
    fetchDepartmentData();
  }, [municipalId]);

  useEffect(() => {
    props.activeMunicipal(i18n.language === "en" ? departmentData?.Department_Name_EN : departmentData?.Department_Name_AR);
    props.municipalLat(departmentData?.Department_latitudes);
    props.municipalLng(departmentData?.Department_longitudes);
    props.getName(i18n.language === 'en' ? departmentData?.Department_Name_EN : departmentData?.Department_Name_AR);
    dispatch(departmentDataSelected(departmentData));
  }, [departmentData]);

  return (
    <Container fluid="lg" className={style.paraContainer} style={{ display: 'none' }}
    >
      <Row>
        <Col className="text-center">
          <p dangerouslySetInnerHTML={{ __html: i18n.language === "en" ? departmentData.Department_Name_EN : departmentData.Department_Name_AR }} className={style.title} />
          <p dangerouslySetInnerHTML={{ __html: i18n.language === "en" ? departmentData.Department_Description_EN : departmentData.Department_Description_AR }} className={style.para} />
          {departmentData.Department_Website ? (
            <>
              <p className={style.forInfo}>{t("for_more_info")}</p>
              <a className={style.websiteURL} target="_blank" href={departmentData.Department_Website}>
                {departmentData.Department_Website}
              </a>
            </>
          ) : null}
        </Col>
      </Row>
    </Container>
  );
};

export default MunicipalPara;
