import React, { useState, useEffect } from "react";
import Grid from "components/UIComponents/Grid";
import { Column } from "devextreme-react/data-grid";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { taskSelected } from "features/service/updateRequestSlice";
import { CheckBox } from "devextreme-react/check-box";
import style from "./../form.module.scss";
import style1 from "./../Dashboard.module.scss";
import { Row } from "react-bootstrap";

const InspectionDatesGrid = (props) => {
  const { t, i18n } = useTranslation("common");
  const { user, clientProfiles } = useSelector((state) => state.user);
  const [allRequests, setAllRequests] = useState(false);
  const [data, setData] = useState();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const getInspectionsVisitsList = async () => {
      const res = await axios
        .post(
          `${process.env.REACT_APP_API_URL}/PORTAL/CLIENTS/DASHBOARD/GET_CLIENT_DASHBOARD_INSPECTION_VISITS_LIST`,
          {
            Client_Profile_Id: clientProfiles?.Client_Profile_Id,
            PageIndex: "1",
            PageCount: "1000",
            Showall: allRequests,
          },
          {
            headers: {
              Authorization: `Bearer ${user?.access_token}`,
            },
          }
        )
        .then((data) => {
          setData(data.data.Data.Data);
          props.dataIsReady(false);
        });
    };
    getInspectionsVisitsList();
  }, [allRequests]);

  let serviceName = "Service_Name_EN",
    departmentName = "Department_Name_EN",
    departmentServiceStepName = "Department_Service_Step_Name_EN",
    requestStatusName = "Request_Status_Name_EN",
    requestTaskTitle = "Request_Task_Title_Name_EN",
    serviceCategoryName = "Service_Category_Name_EN",
    areaName = "Area_Name_EN",
    serviceStepScreenName = "Service_Step_Screen_Name_EN",
    RequestDesc = "Request_Desc_EN";

  if (i18n.language === "ar") {
    serviceName = "Service_Name_AR";
    departmentName = "Department_Name_AR";
    departmentServiceStepName = "Department_Service_Step_Name_AR";
    requestStatusName = "Request_Status_Name_AR";
    requestTaskTitle = "Request_Task_Title_Name_AR";
    serviceCategoryName = "Service_Category_Name_AR";
    serviceStepScreenName = "Service_Step_Screen_Name_AR";
    areaName = "Area_Name_AR";
    RequestDesc = "Request_Desc_AR";
  }

  const handleEdit = (data) => {
    dispatch(taskSelected(data?.Task_Type_Id));
    history.push(`/update-request/${data?.Task_Type_Id}/${data?.Request_Id}`);
  };

  return (
    <>
      <Row className="justify-content-center">
        <p className={style1.gridTitle}>{t("inspection_dates")}</p>
      </Row>
      {/* 
      <Row className="p-2">
        <div>
          <CheckBox
            defaultValue={false}
            onValueChanged={() => setAllRequests(!allRequests)}
          />
          <span className={["px-2", style.checkbox].join(" ")}>{t("all")}</span>
        </div>
      </Row> */}

      <Row>
        <Grid
          sourceData={data}
          addEnabled={false}
          editEnabled={true}
          deleteEnabled={false}
          viewEnabled={false}
          onView={({ data }) => { }}
          onDelete={({ data }) => { }}
          onEdit={({ data }) => handleEdit(data)}
        >
          <Column
            caption={t("request_no")}
            alignment="center"
            dataField="Request_No"
            dataType="string"
            width="auto"
          />
          <Column
            caption={t("request_date")}
            alignment="center"
            dataField="Request_Date"
            dataType="date"
            format="yyyy-MM-dd"
            width="auto"
          />
          <Column
            caption={t("department_name")}
            alignment="center"
            dataField={departmentName}
            dataType="string"
            width="auto"
            visible={false}
          />
          <Column
            caption={t("service_name")}
            alignment="center"
            dataField={serviceName}
            dataType="string"
            width="auto"
          />
          <Column
            caption={t("service_step_name")}
            alignment="center"
            dataField={departmentServiceStepName}
            dataType="string"
            width="auto"
          />
          <Column
            caption={t("request_Status_Name")}
            alignment="center"
            dataField={requestStatusName}
            dataType="string"
            width="auto"
          />
          <Column
            caption={t("requestTaskTitle")}
            alignment="center"
            dataField={requestTaskTitle}
            dataType="string"
            width="auto"
          />
          <Column
            caption={t("serviceCategoryName")}
            alignment="center"
            dataField={serviceCategoryName}
            dataType="string"
            width="auto"
            visible={false}
          />
          <Column
            caption={t("serviceStepScreenName")}
            alignment="center"
            dataField={serviceStepScreenName}
            dataType="string"
            width="auto"
            visible={false}
          />
          <Column
            caption={t("owner")}
            alignment="center"
            dataField="Owner_Name"
            dataType="string"
            width="auto"
          />
          <Column
            caption={t("plot_no")}
            alignment="center"
            dataField="Plot_No"
            dataType="string"
            width="auto"
          />
          <Column
            caption={t("area_name")}
            alignment="center"
            dataField={areaName}
            dataType="string"
            width="auto"
          />
          <Column
            caption={t("project_no")}
            alignment="center"
            dataField="Project_No"
            dataType="string"
            width="auto"
          />
          <Column
            caption={t("requestDesc")}
            alignment="center"
            dataField={RequestDesc}
            dataType="string"
            width="auto"
          />
        </Grid>
      </Row>
    </>
  );
};

export default InspectionDatesGrid;
