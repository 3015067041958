import React, { useState, useEffect } from "react";
import Header from "./../../UIComponents/Header/Header";
import Media from "./../../UIComponents/Media/Media";
import AllServices from "./../../UIComponents/AllServices/AllServices";
import DownloadApp from "./../../UIComponents/DownloadApp/DownloadApp";
import Footer from "./../../UIComponents/Footer/Footer";
import MunicipalPara from "./MunicipalPara/MunicipalPara";
import DepartmentContactUs from "./../../UIComponents/DepartmentContactUS/DepartmentContactUs";
import { useDispatch } from "react-redux";
import { resetDepartment } from "features/service/serviceMunicipSlice";

const Municipal = () => {
  const dispatch = useDispatch();
  const [municipal, setMunicipal] = useState();
  const [muniLatitudes, setMuniLatitudes] = useState();
  const [muniLongitudes, setMuniLongitudes] = useState();
  const [name, getNameS] = useState();

  const activeMunicipal = (val) => {
    setMunicipal(val);
    if (val !== undefined && municipal !== undefined) {
      if (val !== municipal) {
        window.location.reload();
      }
    }
  };
  const municipalLat = (lat) => setMuniLatitudes(lat);
  const municipalLng = (lng) => setMuniLongitudes(lng);
  const getName = (name) => getNameS(name);

  useEffect(() => {
    return () => {
      if (!window.location.pathname.includes("service-card")) {
        dispatch(resetDepartment());
      }
    };
  }, []);

  return (
    <>
      <Header municipal={municipal} />
      <Media />
      <MunicipalPara
        activeMunicipal={activeMunicipal}
        municipalLat={municipalLat}
        municipalLng={municipalLng}
        getName={getName}
      />
      <AllServices homePage={false} />
      {/* <DownloadApp /> */}
      <DepartmentContactUs municipalPage={true} muniLongitudes={muniLongitudes} muniLatitudes={muniLatitudes} name={name} />
      <Footer />
    </>
  );
};

export default Municipal;
