import React, { useState, useEffect } from 'react';
import transitionVairant from './transitionVairant.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { Form } from 'formik';
import FormWrapper from 'components/UIComponents/Login/FormWrapper';
import { login } from 'features/user/userSlice';
import InputField from 'components/UIComponents/Login/Input';
import TransitionControl from 'components/UIComponents/StateMachine/TransitionControl';
import { toState } from './userMachineSlice';
import * as Yup from 'yup';
import { Container, Row, Col } from 'react-bootstrap';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import formStyle from './formStyle.module.scss';
import LoadingOverlay from 'components/UIComponents/LoadingOverlay';
import LanguageBtn from 'components/UIComponents/Header/LanguageBtn/LanguageBtn';
import stripLogo from 'global/imagesTemp/strip-logo.png';
import ueaPass from 'global/images/ueaPass.png';

const validationSchema = Yup.object({
	Login_Name: Yup.string().required('This field is required'),
	Login_Password: Yup.string().required('This field is required'),
});

const SavePasswordCheck = withStyles({
	root: {
		color: '#0F2980',
		padding: '1px',
		'&$checked': {
			color: '#0F2980',
		},
	},
	checked: {},
})((props) => <Checkbox color="default" {...props} />);

const LoginForm = () => {
	const dispatch = useDispatch();
	const { t, i18n } = useTranslation('auth');
	const { loading, error, isAuthenticated } = useSelector((state) => state.user);
	const [savePassword, setSavePassword] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [open, setOpen] = useState(false);

	const onSubmit = (values) => {
		dispatch(login(values)).then(res => {
			if (res?.type == 'user/login/fulfilled') {
				localStorage.setItem('savePassword', savePassword)
			}
		});
	};

	useEffect(() => {
		if (error) {
			setOpen(true);
		}
	}, [error]);

	const handleClose = (_event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};

	const handleColorAcc = () => {
		let theme = document.getElementById('theme');
		if (theme.classList.contains('mainTheme')) {
			theme.classList.add('colorBlind1');
			theme.classList.remove('mainTheme');
		} else if (theme.classList.contains('colorBlind1')) {
			theme.classList.add('colorBlind2');
			theme.classList.remove('colorBlind1');
		} else if (theme.classList.contains('colorBlind2')) {
			theme.classList.add('mainTheme');
			theme.classList.remove('colorBlind2');
		}
	};

	return (
		<>
			<Row className="justify-content-center mx-md-0 mt-md-0 mx-1 mt-2">
				{/* <Row className="justify-content-center mx-0"> */}
				<Col lg={12} md={12} sm={12} className={formStyle.cardStyle}>
					<div className="row">
						{/* <Col md={6} className={formStyle.info_wrapper}>
							<div className={formStyle.overlay}></div>

							<div className={formStyle.info_section}>
								<div className="d-flex justify-content-center w-100">
									<img src={stripLogo} alt="logo" width="285px"/>
								</div>
								<div>
									<h1>
										
                    <Trans
														i18nKey={'LoginInfoTitle'}
														t={t}
													/>
									</h1>
									<p>
                    <Trans
														i18nKey={'LoginInfoPrag'}
														t={t}
													/>
									</p>
								</div>
							</div>
						</Col> */}
						<Col md={12}>
							<div className={formStyle.form_wrapper}>
								<div class="row justify-content-center m-sm-4">
									<div className="col-md-6">
										<div className="justify-content-center">
											{/* <div
												className={formStyle.btn_wwrapper}
											>
												<LanguageBtn theme="black" />
												<span
													className={
														formStyle.brightIcon
													}
													onClick={() =>
														handleColorAcc()
													}
												>
													<svg
														width="13px"
														height="13px"
														viewBox="0 0 13 13"
														version="1.1"
														xmlns="http://www.w3.org/2000/svg"
														xmlnsXlink="http://www.w3.org/1999/xlink"
													>
														<title>Bright</title>
														<g
															id="Page-1"
															stroke="none"
															strokeWidth="1"
															fill="none"
															fillRule="evenodd"
														>
															<g
																id="0"
																transform="translate(-459.000000, -35.000000)"
																fill="#000000"
																fillRule="nonzero"
															>
																<path
																	d="M465.5,48 C461.91005,48 459,45.08995 459,41.5 C459,37.91005 461.91005,35 465.5,35 C469.08995,35 472,37.91005 472,41.5 C472,45.08995 469.08995,48 465.5,48 Z M465.5,46.7 L465.5,36.3 C462.628119,36.3 460.3,38.6281193 460.3,41.5 C460.3,44.3718807 462.628119,46.7 465.5,46.7 L465.5,46.7 Z"
																	id="Bright"
																></path>
															</g>
														</g>
													</svg>
												</span>
											</div> */}
											<div className={formStyle.form_box}>
												<div
													className={
														formStyle.heading
													}
												>
													<Trans
														i18nKey={'Login'}
														t={t}
													/>
												</div>
												<FormWrapper
													initialValues={{
														Login_Name: '',
														Login_Password: '',
													}}
													validation={
														validationSchema
													}
													onSubmit={onSubmit}
												>
													{(props) => (
														<Form
															className={`${formStyle.form}`}
														>
															<Col md={12}>
																<label
																	className={`${formStyle.label}`}
																>
																	{/* {t(
																		'NameorLicenseNum'
																	)}{' '} */}
																	{t('username')}
																</label>
																<InputField
																	name="Login_Name"
																	className={`${formStyle.input}`}
																	type="text"
																	autoComplete="off"
																/>
															</Col>

															<Col md={12}>
																<label
																	className={`${formStyle.label}`}
																>
																	{t(
																		'Password'
																	)}
																</label>
																<InputField
																	name="Login_Password"
																	className={`${formStyle.input}`}
																	type={
																		showPassword
																			? 'text'
																			: 'password'
																	}
																	autoComplete="off"
																/>
																{props.values
																	.Login_Password ? (
																	<span
																		className={[
																			formStyle.passwordIcon,
																			i18n.language ===
																				'ar'
																				? formStyle.passwordIconAR
																				: formStyle.passwordIconEN,
																		].join(
																			' '
																		)}
																		onClick={() =>
																			setShowPassword(
																				!showPassword
																			)
																		}
																	>
																		{showPassword ? (
																			<svg
																				className={`${formStyle.eyeSvg}`}
																				version="1.0"
																				xmlns="http://www.w3.org/2000/svg"
																				width="512.000000pt"
																				height="512.000000pt"
																				viewBox="0 0 512.000000 512.000000"
																				preserveAspectRatio="xMidYMid meet"
																			>
																				<g
																					transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
																					fill="#000000"
																					stroke="none"
																				>
																					<path
																						d="M2275 4194 c-631 -96 -1266 -455 -1865 -1054 -197 -197 -345 -370
                              -379 -440 -22 -46 -26 -69 -26 -140 0 -113 24 -162 151 -309 438 -508 1011
                              -946 1508 -1154 344 -143 604 -193 960 -184 339 8 607 75 951 237 401 187 882
                              553 1252 950 261 281 288 324 288 460 0 116 -23 162 -165 325 -384 440 -832
                              803 -1271 1029 -300 154 -557 241 -839 281 -131 18 -441 18 -565 -1z m477
                              -549 c146 -26 292 -84 423 -170 77 -50 250 -223 300 -300 125 -191 184 -386
                              185 -611 1 -357 -157 -673 -445 -889 -190 -143 -410 -215 -657 -215 -610 0
                              -1099 490 -1098 1099 1 296 100 545 300 755 119 125 246 212 393 270 66 26
                              192 61 252 69 70 10 276 5 347 -8z"
																					/>
																					<path
																						d="M2445 3340 c-193 -31 -373 -133 -495 -283 -62 -76 -133 -217 -157
                              -312 -24 -101 -22 -286 5 -386 75 -276 288 -487 572 -565 97 -27 290 -25 393
                              4 418 118 661 530 562 952 -21 89 -93 232 -155 307 -101 125 -271 233 -420
                              267 -84 20 -229 27 -305 16z"
																					/>
																				</g>
																			</svg>
																		) : (
																			<svg
																				className={`${formStyle.eyeSvg}`}
																				version="1.0"
																				xmlns="http://www.w3.org/2000/svg"
																				width="512.000000pt"
																				height="512.000000pt"
																				viewBox="0 0 512.000000 512.000000"
																				preserveAspectRatio="xMidYMid meet"
																			>
																				<g
																					transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
																					fill="#000000"
																					stroke="none"
																				>
																					<path
																						d="M4905 5104 c-16 -9 -298 -283 -625 -610 l-595 -594 -110 54 c-847
                                418 -1660 321 -2530 -305 -333 -239 -745 -642 -994 -972 -52 -68 -63 -124 -36
                                -178 24 -47 181 -240 312 -384 229 -250 528 -516 770 -682 l72 -50 -565 -564
                                c-311 -310 -574 -581 -585 -601 -24 -48 -24 -89 1 -138 23 -45 80 -80 130 -80
                                17 0 47 9 68 19 46 25 4864 4844 4886 4888 61 123 -78 260 -199 197z m-2097
                                -1524 c46 -12 116 -35 155 -51 73 -30 217 -115 217 -127 0 -4 -47 -54 -104
                                -111 l-104 -104 -53 30 c-148 84 -337 112 -507 77 -394 -81 -656 -458 -592
                                -852 14 -82 33 -141 76 -226 l35 -70 -103 -103 c-56 -57 -106 -103 -110 -103
                                -12 0 -97 144 -127 217 -60 145 -76 232 -75 408 1 129 5 175 22 235 55 203
                                146 366 281 500 155 156 372 266 586 300 97 15 308 4 403 -20z"
																					/>
																					<path
																						d="M3871 3236 l-323 -323 21 -66 c107 -348 0 -760 -268 -1028 -268 -268
                                -680 -375 -1028 -268 l-66 21 -241 -241 c-132 -132 -237 -243 -234 -246 12
                                -12 229 -85 326 -110 192 -48 283 -59 507 -59 172 1 232 5 330 23 357 65 706
                                212 1055 444 367 245 736 586 1050 972 159 195 159 215 0 410 -231 284 -582
                                633 -781 775 l-25 19 -323 -323z"
																					/>
																					<path
																						d="M2876 2241 c-321 -321 -414 -420 -401 -425 55 -21 254 4 359 45 211
                                83 391 285 451 508 19 69 31 252 19 274 -8 14 -86 -60 -428 -402z"
																					/>
																				</g>
																			</svg>
																		)}
																	</span>
																) : null}
															</Col>

															<Row className="mx-0 mt-1 mb-0 align-items-center">
																<Col sm={6}>
																	<FormGroup>
																		<FormControlLabel
																			className="mx-0"
																			control={
																				<SavePasswordCheck
																					checked={
																						savePassword
																					}
																					onChange={() =>
																						setSavePassword(
																							!savePassword
																						)
																					}
																					name="savePassword"
																				/>
																			}
																			label={
																				<span
																					className={
																						formStyle.checkbox
																					}
																				>
																					{t(
																						'keep_me_signed'
																					)}
																				</span>
																			}
																		/>
																	</FormGroup>
																</Col>

																<Col sm={6}>
																	<TransitionControl
																		callback={() =>
																			dispatch(
																				toState(
																					'Forgot'
																				)
																			)
																		}
																		triggerLabel={
																			<Trans
																				i18nKey={
																					'forgot your password'
																				}
																				t={
																					t
																				}
																			/>
																		}
																		styles={
																			transitionVairant
																		}
																	/>
																</Col>
															</Row>

															<Col md={12}>
																<button
																	type="submit"
																	className={[
																		formStyle.primBtn,
																		formStyle.formBtn,
																	].join(' ')}
																	data-cursor={`${props.isValid &&
																		props.dirty &&
																		loading !==
																		'pending'
																		? 'pointer'
																		: loading !==
																			'pending'
																			? 'not-allowed'
																			: 'wait'
																		}`}
																	disabled={
																		!(
																			props.isValid &&
																			props.dirty
																		) ||
																		loading ===
																		'pending'
																	}
																>
																	{loading !==
																		'pending' ? (
																		<Trans
																			i18nKey={
																				'Login'
																			}
																			t={
																				t
																			}
																		/>
																	) : (
																		<Trans
																			i18nKey={
																				'Please wait'
																			}
																			t={
																				t
																			}
																		/>
																	)}
																</button>
															</Col>
															<Col md={12}>
																<button
																	type="button"
																	className={[
																		formStyle.primBtn,
																		formStyle.formBtn,
																		formStyle.outline,
																	].join(' ')}
																>
																	<img
																		src={
																			ueaPass
																		}
																		alt=""
																		width="25px"
																		className="mx-1"
																	/>
																	<Trans
																		i18nKey={
																			'SigninwithUAEPASS'
																		}
																		t={t}
																	/>
																</button>
															</Col>
														</Form>
													)}
												</FormWrapper>
												<div>
													<div className="border-bottom w-75 my-0 mb-3 mx-auto"></div>

													<TransitionControl
														callback={() =>
															dispatch(
																toState(
																	'Register'
																)
															)
														}
														additional={
															<Trans
																i18nKey={
																	"You don't have an account"
																}
																t={t}
															/>
														}
														triggerLabel={
															<Trans
																i18nKey={
																	'Register a new account'
																}
																t={t}
															/>
														}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							{/* <div className="border-bottom w-75 my-3 mb-3 mx-auto"></div> */}
						</Col>
					</div>

					{/* <button type="button" className={`${styles.btn} ${styles.uaePassBtn}`}>
            SIGN IN WITH UAE PASS
          </button> */}
					{/* <hr className={styles.hr} /> */}
				</Col>
			</Row>
			<Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
				<MuiAlert
					elevation={6}
					variant="filled"
					onClose={handleClose}
					severity="error"
				>
					{i18n.language === 'ar' ? error?.Error_AR : error?.Error_EN}
				</MuiAlert>
			</Snackbar>
			{loading === 'pending' && <LoadingOverlay />}
		</>
	);
};

export default LoginForm;
