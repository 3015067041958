import React, { useState, useEffect } from 'react'
import Grid from 'components/UIComponents/Grid'
import { Column } from 'devextreme-react/data-grid'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { useSelector } from 'react-redux'
import style from "./../Dashboard.module.scss";
import { Row } from "react-bootstrap";

const SuggestionsGrid = props => {

  const { t, i18n } = useTranslation('common')
  const [data, setData] = useState()
  const { user, clientProfiles } = useSelector((state) => state.user);

  useEffect(() => {
    const getSuggestionsList = async () => {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/PORTAL/CLIENTS/CLIENTMESSAGES/GET_CLIENT_MESSAGES_LIST`,
        {
          'Client_Profile_Id': clientProfiles?.Client_Profile_Id,
          'PageIndex': '1',
          'PageCount': '1000'
        },
        {
          headers: {
            Authorization: `Bearer ${user?.access_token}`
          }
        }
      ).then(data => {
        setData(data.data.Data)
        props.dataIsReady(false)
      })
    }
    getSuggestionsList()
  }, [])

  let messageType = "Client_Message_Type_Name_EN",
    RequestDesc = "Request_Desc_EN",
    areaName = "Area_Name_EN";


  if (i18n.language === "ar") {
    messageType = "Client_Message_Type_Name_AR";
    areaName = "Area_Name_AR";
    RequestDesc = "Request_Desc_AR";
  }

  return (
    <>
      <Row className='justify-content-center'>
        <p className={style.gridTitle}>{t("complaints_suggestions")}</p>
      </Row>

      <Row>
        <Grid
          sourceData={data}
          addEnabled={false}
          editEnabled={false}
          deleteEnabled={false}
          viewEnabled={true}
          onView={({ data }) => { props.handleShow(data) }}
          onDelete={({ data }) => { }}
        >
          <Column caption={t('message_no')} alignment="center" dataField="Client_Message_No" dataType="string" width="auto" />
          <Column caption={t('message_date')} alignment="center" dataField="Client_Message_Date" dataType="date" width="auto" format="yyyy-MM-dd" />
          <Column caption={t('message_type')} alignment="center" dataField={messageType} dataType="string" width="auto" />
          <Column caption={t('closed')} alignment="center" dataField="Closed" dataType="boolean" width="auto" />
          <Column caption={t("requestDesc")} alignment="center" dataField={RequestDesc} dataType="string" width="auto" />
        </Grid>
      </Row>
    </>
  )
}

export default SuggestionsGrid
