import React, { useEffect, useState } from "react";
import style from "./Description.module.scss";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { authorizedState } from "./../../../../features/service/serviceMachineSlice";
import Accordion from 'react-bootstrap/Accordion';

const Description = ({ serviceData, serviceDetailsData, serviceStepsData, onStart }) => {
  const { t, i18n } = useTranslation("common");
  const { isAuthenticated } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [detailsArr, setDetailsArr] = useState([])
  const [activeCollapseStep, setActiveCollapseStep] = useState(null)
  const [activeCollapsePart, setActiveCollapsePart] = useState(null)

  String.prototype.toIndiaDigits = function () {
    var id = ['۰', '۱', '۲', '۳', '٤', '٥', '٦', '۷', '۸', '٩'];
    return this.replace(/[0-9]/g, function (w) {
      return id[+w]
    });
  }

  useEffect(() => {
    let newArr = []
    const lookup = Object.groupBy(serviceDetailsData, e => e.Service_Detail_Title_AR);
    Object.entries(lookup)?.map(x => newArr.push(x[1]))
    setDetailsArr(newArr)
  }, [serviceDetailsData])

  const details = (
    <>
      {detailsArr?.length > 0 && detailsArr?.map((arr, i) =>
        arr?.length == 1 ? arr?.map(x =>
          <li className={style.step} key={x?.Service_Detail_Id}>
            <span>
              <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5 0.5C6.01664 0.5 4.56659 0.939867 3.33323 1.76398C2.09986 2.58809 1.13856 3.75943 0.570907 5.12987C0.00324963 6.50032 -0.145275 8.00832 0.144114 9.46318C0.433503 10.918 1.14781 12.2544 2.1967 13.3033C3.2456 14.3522 4.58197 15.0665 6.03682 15.3559C7.49168 15.6453 8.99968 15.4967 10.3701 14.9291C11.7406 14.3614 12.9119 13.4001 13.736 12.1668C14.5601 10.9334 15 9.48336 15 8C14.9969 6.01181 14.2058 4.10593 12.7999 2.70007C11.3941 1.29421 9.48819 0.503052 7.5 0.5ZM11.2731 6.51154L7.92692 10.9769C7.76697 11.1932 7.55819 11.3687 7.31757 11.489C7.07696 11.6093 6.81132 11.671 6.54231 11.6692C6.08344 11.6688 5.6435 11.4862 5.31923 11.1615L3.58846 9.43077C3.4356 9.26672 3.35238 9.04974 3.35634 8.82555C3.36029 8.60135 3.45112 8.38745 3.60967 8.2289C3.76822 8.07034 3.98213 7.97952 4.20632 7.97557C4.43052 7.97161 4.64749 8.05483 4.81154 8.20769L6.31154 9.73077C6.33752 9.76221 6.37013 9.78752 6.40703 9.80489C6.44393 9.82227 6.48422 9.83128 6.525 9.83128C6.56579 9.83128 6.60607 9.82227 6.64297 9.80489C6.67987 9.78752 6.71248 9.76221 6.73846 9.73077L9.88846 5.46154C9.95741 5.37062 10.0436 5.29418 10.1421 5.23657C10.2406 5.17895 10.3494 5.14131 10.4625 5.12577C10.5755 5.11024 10.6905 5.11712 10.8009 5.14602C10.9113 5.17492 11.0149 5.22529 11.1058 5.29423C11.1967 5.36317 11.2731 5.44935 11.3307 5.54784C11.3884 5.64632 11.426 5.7552 11.4415 5.86823C11.4571 5.98127 11.4502 6.09626 11.4213 6.20664C11.3924 6.31702 11.342 6.42062 11.2731 6.51154Z" fill="#00B0EB" />
              </svg>
            </span>
            <div className={style.stepTitle}>
              <p>{i18n.language === "en" ? x?.Service_Detail_Title_EN : x?.Service_Detail_Title_AR} {x?.Service_Detail_Required == 1 ? `(${t('required')})` : null}</p>
              <span>{i18n.language === "en" ? x?.File_Content_Type_Name_EN : x?.File_Content_Type_Name_AR}</span>
            </div>
          </li>
        ) : arr?.length > 1 && (
          <Accordion activeKey={activeCollapseStep}>
            <>
              <Accordion.Toggle
                eventKey={i}
                className={[style.step, style.collapseStep].join(' ')}
                onClick={() => {
                  if (activeCollapseStep == i) {
                    setActiveCollapseStep(null)
                  } else {
                    setActiveCollapseStep(i)
                  }
                }}
              >
                <span>
                  <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 0.5C6.01664 0.5 4.56659 0.939867 3.33323 1.76398C2.09986 2.58809 1.13856 3.75943 0.570907 5.12987C0.00324963 6.50032 -0.145275 8.00832 0.144114 9.46318C0.433503 10.918 1.14781 12.2544 2.1967 13.3033C3.2456 14.3522 4.58197 15.0665 6.03682 15.3559C7.49168 15.6453 8.99968 15.4967 10.3701 14.9291C11.7406 14.3614 12.9119 13.4001 13.736 12.1668C14.5601 10.9334 15 9.48336 15 8C14.9969 6.01181 14.2058 4.10593 12.7999 2.70007C11.3941 1.29421 9.48819 0.503052 7.5 0.5ZM11.2731 6.51154L7.92692 10.9769C7.76697 11.1932 7.55819 11.3687 7.31757 11.489C7.07696 11.6093 6.81132 11.671 6.54231 11.6692C6.08344 11.6688 5.6435 11.4862 5.31923 11.1615L3.58846 9.43077C3.4356 9.26672 3.35238 9.04974 3.35634 8.82555C3.36029 8.60135 3.45112 8.38745 3.60967 8.2289C3.76822 8.07034 3.98213 7.97952 4.20632 7.97557C4.43052 7.97161 4.64749 8.05483 4.81154 8.20769L6.31154 9.73077C6.33752 9.76221 6.37013 9.78752 6.40703 9.80489C6.44393 9.82227 6.48422 9.83128 6.525 9.83128C6.56579 9.83128 6.60607 9.82227 6.64297 9.80489C6.67987 9.78752 6.71248 9.76221 6.73846 9.73077L9.88846 5.46154C9.95741 5.37062 10.0436 5.29418 10.1421 5.23657C10.2406 5.17895 10.3494 5.14131 10.4625 5.12577C10.5755 5.11024 10.6905 5.11712 10.8009 5.14602C10.9113 5.17492 11.0149 5.22529 11.1058 5.29423C11.1967 5.36317 11.2731 5.44935 11.3307 5.54784C11.3884 5.64632 11.426 5.7552 11.4415 5.86823C11.4571 5.98127 11.4502 6.09626 11.4213 6.20664C11.3924 6.31702 11.342 6.42062 11.2731 6.51154Z" fill="#00B0EB" />
                  </svg>
                </span>
                <p>{i18n.language === "en" ? arr[0]?.Service_Detail_Title_EN : arr[0]?.Service_Detail_Title_AR}</p>

                {/* <span className={[
                  style.accArrow,
                  activeCollapseStep == i ? style.upArrow : null
                ].join(' ')}>
                  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    width="451.847px" height="451.847px" viewBox="0 0 451.847 451.847" xmlSpace="preserve"
                  >
                    <g>
                      <path d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751
                                                                c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0
                                                                c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"/>
                    </g>
                  </svg>
                </span> */}
              </Accordion.Toggle>

              {/* <Accordion.Collapse eventKey={i}>
                <div className={style.collapseStepBody}>
                  {arr?.map(x =>
                    <p>{i18n.language === "en" ? x?.Service_Detail_Title_EN : x?.Service_Detail_Title_AR} - {i18n.language === "en" ? x?.File_Content_Type_Name_EN : x?.File_Content_Type_Name_AR}</p>
                  )}
                </div>
              </Accordion.Collapse> */}
            </>
          </Accordion>
        ))}
    </>
  )

  return (
    <div className="container-fluid py-4">
      <Accordion activeKey={activeCollapsePart}>

        <Row>
          <Accordion.Toggle
            eventKey={1}
            className={style.collapsePart}
            onClick={() => {
              if (activeCollapsePart == 1) {
                setActiveCollapsePart(null)
              } else {
                setActiveCollapsePart(1)
              }
            }}
          >
            <Col xl={12}>
              <p className={style.title}>{t("description")}</p>

              <span className={[
                style.accArrow,
                activeCollapsePart == 1 ? style.upArrow : null
              ].join(' ')}>
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                  width="451.847px" height="451.847px" viewBox="0 0 451.847 451.847" xmlSpace="preserve"
                >
                  <g>
                    <path d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751
                                                                c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0
                                                                c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"/>
                  </g>
                </svg>
              </span>
            </Col>
          </Accordion.Toggle>

          <Accordion.Collapse eventKey={1}>
            <Col xl={12}>{i18n.language === "en" ? <p className={style.content}>{serviceData.Service_Description_EN}</p> : <p className={style.content}>{serviceData.Service_Description_AR}</p>}</Col>
          </Accordion.Collapse>
        </Row>

        <Row>
          <Accordion.Toggle
            eventKey={2}
            className={style.collapsePart}
            onClick={() => {
              if (activeCollapsePart == 2) {
                setActiveCollapsePart(null)
              } else {
                setActiveCollapsePart(2)
              }
            }}
          >
            <Col xl={12}>
              <p className={style.title}>{t("categorize")}</p>

              <span className={[
                style.accArrow,
                activeCollapsePart == 2 ? style.upArrow : null
              ].join(' ')}>
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                  width="451.847px" height="451.847px" viewBox="0 0 451.847 451.847" xmlSpace="preserve"
                >
                  <g>
                    <path d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751
                                                                c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0
                                                                c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"/>
                  </g>
                </svg>
              </span>
            </Col>
          </Accordion.Toggle>

          <Accordion.Collapse eventKey={2}>
            <Col xl={12}>{i18n.language === "en" ? <p className={style.content}>{serviceData.Service_Category_Name_EN}</p> : <p className={style.content}>{serviceData.Service_Category_Name_AR}</p>}</Col>
          </Accordion.Collapse>
        </Row>

        {serviceData?.Service_Requirements_EN &&
          <Row>
            <Accordion.Toggle
              eventKey={3}
              className={style.collapsePart}
              onClick={() => {
                if (activeCollapsePart == 3) {
                  setActiveCollapsePart(null)
                } else {
                  setActiveCollapsePart(3)
                }
              }}
            >
              <Col xl={12}>
                <p className={style.title}>{t("serviceRequirements")}</p>

                <span className={[
                  style.accArrow,
                  activeCollapsePart == 3 ? style.upArrow : null
                ].join(' ')}>
                  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    width="451.847px" height="451.847px" viewBox="0 0 451.847 451.847" xmlSpace="preserve"
                  >
                    <g>
                      <path d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751
                                                                c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0
                                                                c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"/>
                    </g>
                  </svg>
                </span>
              </Col>
            </Accordion.Toggle>

            <Accordion.Collapse eventKey={3}>
              <Col xl={12}>{i18n.language === "en" ?
                <p dangerouslySetInnerHTML={{ __html: serviceData.Service_Requirements_EN }} className={style.content} />
                : <p dangerouslySetInnerHTML={{ __html: serviceData.Service_Requirements_AR }} className={style.content} />}

              </Col>
            </Accordion.Collapse>
          </Row>
        }

        {serviceData?.Service_Requirements_EN == '' &&
          <Row>
            <Accordion.Toggle
              eventKey={4}
              className={style.collapsePart}
              onClick={() => {
                if (activeCollapsePart == 4) {
                  setActiveCollapsePart(null)
                } else {
                  setActiveCollapsePart(4)
                }
              }}
            >
              <Col xl={12}>
                <p className={style.title}>{t("required_documents")}</p>

                <span className={[
                  style.accArrow,
                  activeCollapsePart == 4 ? style.upArrow : null
                ].join(' ')}>
                  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    width="451.847px" height="451.847px" viewBox="0 0 451.847 451.847" xmlSpace="preserve"
                  >
                    <g>
                      <path d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751
                                                                c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0
                                                                c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"/>
                    </g>
                  </svg>
                </span>
              </Col>
            </Accordion.Toggle>

            <Accordion.Collapse eventKey={4}>
              <Col xl={12}>
                <ul className={style.stepsList}>
                  {details}
                </ul>
              </Col>
            </Accordion.Collapse>
          </Row>
        }

        <Row>
          <Accordion.Toggle
            eventKey={5}
            className={style.collapsePart}
            onClick={() => {
              if (activeCollapsePart == 5) {
                setActiveCollapsePart(null)
              } else {
                setActiveCollapsePart(5)
              }
            }}
          >
            <Col xl={12}>
              <p className={style.title}>{t("requestSteps")}</p>

              <span className={[
                style.accArrow,
                activeCollapsePart == 5 ? style.upArrow : null
              ].join(' ')}>
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                  width="451.847px" height="451.847px" viewBox="0 0 451.847 451.847" xmlSpace="preserve"
                >
                  <g>
                    <path d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751
                                                                c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0
                                                                c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"/>
                  </g>
                </svg>
              </span>
            </Col>
          </Accordion.Toggle>

          <Accordion.Collapse eventKey={5}>
            <Col xl={12}>
              <ul className={style.stepsList}>
                {serviceStepsData.length > 0 &&
                  serviceStepsData.map((step) => (
                    <li className={style.step} key={step.Service_Step_Id}>
                      <span className={style.number}>{step.Service_Step_No}</span>
                      <div className={style.stepTitle}>
                        <p>{i18n.language === "en" ? step.Service_Step_Name_EN : step.Service_Step_Name_AR}</p>
                        <span>{i18n.language === "en" ? step.Service_Step_Details_EN : step.Service_Step_Details_AR}</span>
                      </div>
                    </li>
                  ))}
              </ul>
            </Col>
          </Accordion.Collapse>
        </Row>

        <Row>
          <Accordion.Toggle
            eventKey={6}
            className={style.collapsePart}
            onClick={() => {
              if (activeCollapsePart == 6) {
                setActiveCollapsePart(null)
              } else {
                setActiveCollapsePart(6)
              }
            }}
          >
            <Col xl={12}>
              <p className={style.title}>{t("terms_of_service")}</p>

              <span className={[
                style.accArrow,
                activeCollapsePart == 6 ? style.upArrow : null
              ].join(' ')}>
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                  width="451.847px" height="451.847px" viewBox="0 0 451.847 451.847" xmlSpace="preserve"
                >
                  <g>
                    <path d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751
                                                                c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0
                                                                c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"/>
                  </g>
                </svg>
              </span>
            </Col>
          </Accordion.Toggle>

          <Accordion.Collapse eventKey={6}>
            <Col xl={12}>{i18n.language === "en" ?
              <p dangerouslySetInnerHTML={{ __html: serviceData.Service_Rules_EN }} className={style.content} />
              : <p dangerouslySetInnerHTML={{ __html: serviceData.Service_Rules_AR }} className={style.content} />}

            </Col>
          </Accordion.Collapse>
        </Row>

      </Accordion>

      <Row className={style.title}>
        <Col md={6}>
          <p className={style.fees}>{t("fees")}</p>
          <p className={style.feesVal} style={{ direction: i18n.language == 'ar' ? 'rtl' : 'ltr' }}>
            {i18n.language === "en" ? serviceData.Service_Fees_EN : (serviceData.Service_Fees_AR).toIndiaDigits()}
          </p>
        </Col>

        <Col md={6} className="d-flex justify-content-end align-items-center">
          <button
            type="button"
            className={["mainBtn secondarymainBtn lgBtn", style.startBtn].join(" ")}
            onClick={() => isAuthenticated ? dispatch(authorizedState("Request")) : window.location.replace("/login")}
          // onClick={onStart}
          >
            {t("start_service")}
          </button>
        </Col>
      </Row>
    </div>
  );
};

export default Description;
