import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import axios from "axios";
import style from "./ToolCards.module.scss";
import pic1 from "./../../../global/images/offering1.png";
import pic2 from "./../../../global/images/offering2.png";
import pic3 from "./../../../global/images/offering3.png";
import pic4 from "./../../../global/images/maktabi.png";
import { NavLink } from "react-router-dom";
const ToolCards = () => {
	const { t, i18n } = useTranslation("common");
	const [offersList, setOffersList] = useState([]);
	const { user } = useSelector((state) => state.user);

	useEffect(() => {
		getLicensesOffers();
	}, []);

	const getLicensesOffers = async () => {
		const res = await axios.post(
			`${process.env.REACT_APP_API_URL}PORTAL/LICENSES/OFFERS/Get_Portal_Licenses_Offers_List`
		);
		setOffersList(res.data.Data);
	};

	return (
		<Container>
			<Row>
				<Col md={6} className={style.title}>
					<p className={style.offeringsTitle}>{t("offerings")}</p>
					{/* <p className={style.offerings}>{t('offerings')}</p> */}
					{/* <p className={style.offeringsTitle}>
						{t('offeringsTitle')}
					</p> */}
					{/* <p className={style.offeringsPara}>{t('offeringsPara')}</p> */}
				</Col>
			</Row>

			{offersList.length > 0 && (
				<Row>
					<Col md={6}>
						<div className={style.offeringCard}>
							<Row>
								<Col md={12}>
									{offersList[0]?.License_Offer_Icon_URL ? (
										<img
											width="40px"
											alt={
												offersList[0]
													?.License_Offer_Name_EN
											}
											src={
												offersList[0]
													?.License_Offer_Icon_URL
											}
										/>
									) : (
										<svg
											width="70"
											height="70"
											viewBox="0 0 73 73"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<circle
												cx="36.5"
												cy="36.5"
												r="36.5"
												fill="#A7E0F7"
												fillOpacity="0.47"
											/>
											<g clipPath="url(#clip0_519_679)">
												<path
													d="M41.6674 26.0833H36.0008M41.6674 31.75H36.0008M41.6674 34.5833V20.4166H30.3341V34.5833L22.4503 41.3408C21.5473 42.1151 20.9032 43.1474 20.6045 44.2989C20.3058 45.4503 20.367 46.6656 20.7797 47.7812C21.1925 48.8968 21.9371 49.8592 22.9133 50.539C23.8894 51.2187 25.0504 51.5832 26.2399 51.5833H45.7616C46.9511 51.5832 48.1121 51.2187 49.0883 50.539C50.0644 49.8592 50.809 48.8968 51.2218 47.7812C51.6345 46.6656 51.6957 45.4503 51.397 44.2989C51.0983 43.1474 50.4542 42.1151 49.5512 41.3408L41.6674 34.5833Z"
													stroke="black"
													strokeWidth="2"
													strokeLinejoin="round"
												/>
												<path
													d="M20.4316 45.9166C20.4686 47.4324 21.0969 48.8736 22.1821 49.9325C23.2674 50.9913 24.7237 51.5837 26.24 51.5833H45.7602C47.2765 51.5837 48.7328 50.9913 49.818 49.9325C50.9033 48.8736 51.5316 47.4324 51.5686 45.9166H20.4316Z"
													fill="black"
												/>
											</g>
											<defs>
												<clipPath id="clip0_519_679">
													<rect
														width="34"
														height="34"
														fill="white"
														transform="translate(19 19)"
													/>
												</clipPath>
											</defs>
										</svg>
									)}

									<p className={style.title}>
										{i18n.language == "ar"
											? offersList[0]
													?.License_Offer_Name_AR
											: offersList[0]
													?.License_Offer_Name_EN}
									</p>
									<p
										className={style.para}
										dangerouslySetInnerHTML={{
											__html:
												i18n.language == "ar"
													? offersList[0]
															?.License_Offer_Description_AR
													: offersList[0]
															?.License_Offer_Description_EN,
										}}
									/>
									<div className={style.image}>
										<img
											src={
												offersList[0]
													?.License_Offer_Image_URL
													? offersList[0]
															?.License_Offer_Image_URL
													: pic1
											}
										/>
										<div
											className={[
												style.cost,
												i18n.language == "en"
													? style.costEn
													: style.costAr,
											].join(" ")}
										>
											<p>
												{
													offersList[0]
														?.License_Offer_Price
												}{" "}
												{t("CurrencyName")}
											</p>
											<p
												dangerouslySetInnerHTML={{
													__html:
														i18n.language == "ar"
															? offersList[0]
																	?.License_Offer_Price_Description_AR
															: offersList[0]
																	?.License_Offer_Price_Description_EN,
												}}
											></p>
										</div>
									</div>
								</Col>
							</Row>
						</div>
					</Col>

					<Col md={6}>
						<div className={style.offeringCard}>
							<Row>
								<Col md={12}>
									{offersList[1]?.License_Offer_Icon_URL ? (
										<img
											width="40px"
											alt={
												offersList[1]
													?.License_Offer_Name_EN
											}
											src={
												offersList[1]
													?.License_Offer_Icon_URL
											}
										/>
									) : (
										<svg
											width="70"
											height="70"
											viewBox="0 0 73 73"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<circle
												cx="36.5"
												cy="36.5"
												r="36.5"
												fill="#DCA1FF"
												fillOpacity="0.27"
											/>
											<g clipPath="url(#clip0_519_704)">
												<path
													d="M36.291 20.8334C32.9108 20.8375 29.6702 22.1821 27.28 24.5723C24.8898 26.9625 23.5451 30.2031 23.541 33.5834V35H29.8083C30.3575 36.2629 31.2636 37.3378 32.4152 38.0928C33.5669 38.8478 34.914 39.25 36.291 39.25C37.6681 39.25 39.0152 38.8478 40.1668 38.0928C41.3184 37.3378 42.2245 36.2629 42.7737 35H49.041V33.5834C49.0369 30.2031 47.6923 26.9625 45.3021 24.5723C42.9119 22.1821 39.6713 20.8375 36.291 20.8334ZM40.541 32.1667C40.541 33.2939 40.0932 34.3749 39.2962 35.1719C38.4992 35.9689 37.4182 36.4167 36.291 36.4167C35.1638 36.4167 34.0828 35.9689 33.2858 35.1719C32.4888 34.3749 32.041 33.2939 32.041 32.1667V31.7885C33.6706 31.1524 35.1268 30.1403 36.291 28.8347C37.4552 30.1403 38.9115 31.1524 40.541 31.7885V32.1667ZM43.3743 32.1667V30.75C43.3743 30.4342 43.2688 30.1275 43.0745 29.8785C42.8802 29.6295 42.6083 29.4526 42.3019 29.3759C40.3269 28.7992 38.6281 27.5257 37.5207 25.7917C37.3862 25.5886 37.2035 25.422 36.989 25.3067C36.7744 25.1914 36.5346 25.1311 36.291 25.1311C36.0474 25.1311 35.8076 25.1914 35.5931 25.3067C35.3785 25.422 35.1958 25.5886 35.0613 25.7917C33.9522 27.5241 32.2541 28.7971 30.2801 29.3759C29.9737 29.4526 29.7018 29.6295 29.5075 29.8785C29.3132 30.1275 29.2077 30.4342 29.2077 30.75V32.1667H26.4749C26.8139 29.8049 27.993 27.6445 29.796 26.0818C31.599 24.5191 33.905 23.6589 36.291 23.6589C38.677 23.6589 40.983 24.5191 42.786 26.0818C44.589 27.6445 45.7681 29.8049 46.1071 32.1667H43.3743ZM41.2989 41.4473L40.5778 41.2036L36.291 44.5385L32.0042 41.2036L31.2831 41.4473C27.3221 42.7804 24.9577 45.6661 24.9577 49.1667V52H47.6243V49.1667C47.6243 45.6661 45.2599 42.7804 41.2989 41.4473ZM27.791 49.1667C27.8445 48.0879 28.2281 47.0515 28.89 46.1979C29.5518 45.3443 30.4599 44.7146 31.4913 44.394L36.291 48.1297L41.0907 44.394C42.1221 44.7146 43.0302 45.3443 43.6921 46.1979C44.3539 47.0515 44.7375 48.0879 44.791 49.1667H27.791Z"
													fill="black"
												/>
											</g>
											<defs>
												<clipPath id="clip0_519_704">
													<rect
														width="34"
														height="34"
														fill="white"
														transform="translate(19 19)"
													/>
												</clipPath>
											</defs>
										</svg>
									)}

									<p className={style.title}>
										{i18n.language == "ar"
											? offersList[1]
													?.License_Offer_Name_AR
											: offersList[1]
													?.License_Offer_Name_EN}
									</p>
									<p
										className={style.para}
										dangerouslySetInnerHTML={{
											__html:
												i18n.language == "ar"
													? offersList[1]
															?.License_Offer_Description_AR
													: offersList[1]
															?.License_Offer_Description_EN,
										}}
									></p>
									{/* <p
										className={style.para}
										dangerouslySetInnerHTML={{
											__html:
												i18n.language == 'ar'
													? offersList[1]
														?.License_Offer_Description_AR
													: offersList[1]
														?.License_Offer_Description_EN,
										}}
									/> */}
									<div className={style.image}>
										<img
											src={
												offersList[1]
													?.License_Offer_Image_URL
													? offersList[1]
															?.License_Offer_Image_URL
													: pic2
											}
										/>
										<div
											className={[
												style.cost,
												i18n.language == "en"
													? style.costEn
													: style.costAr,
											].join(" ")}
										>
											<p>
												{
													offersList[1]
														?.License_Offer_Price
												}{" "}
												{t("CurrencyName")}
											</p>
											<p
												dangerouslySetInnerHTML={{
													__html:
														i18n.language == "ar"
															? offersList[1]
																	?.License_Offer_Price_Description_AR
															: offersList[1]
																	?.License_Offer_Price_Description_EN,
												}}
											/>
										</div>
									</div>
								</Col>
							</Row>
						</div>
					</Col>

					<Col md={12}>
						<div
							className={[
								style.offeringCard,
								style.horizCard,
							].join(" ")}
						>
							<Row>
								<Col md={6}>
									{offersList[2]?.License_Offer_Icon_URL ? (
										<img
											width="40px"
											alt={
												offersList[2]
													?.License_Offer_Name_EN
											}
											src={
												offersList[2]
													?.License_Offer_Icon_URL
											}
										/>
									) : (
										<svg
											width="70"
											height="70"
											viewBox="0 0 73 73"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<circle
												cx="36.5"
												cy="36.5"
												r="36.5"
												fill="#A7E0F7"
												fillOpacity="0.47"
											/>
											<g clipPath="url(#clip0_519_679)">
												<path
													d="M41.6674 26.0833H36.0008M41.6674 31.75H36.0008M41.6674 34.5833V20.4166H30.3341V34.5833L22.4503 41.3408C21.5473 42.1151 20.9032 43.1474 20.6045 44.2989C20.3058 45.4503 20.367 46.6656 20.7797 47.7812C21.1925 48.8968 21.9371 49.8592 22.9133 50.539C23.8894 51.2187 25.0504 51.5832 26.2399 51.5833H45.7616C46.9511 51.5832 48.1121 51.2187 49.0883 50.539C50.0644 49.8592 50.809 48.8968 51.2218 47.7812C51.6345 46.6656 51.6957 45.4503 51.397 44.2989C51.0983 43.1474 50.4542 42.1151 49.5512 41.3408L41.6674 34.5833Z"
													stroke="black"
													strokeWidth="2"
													strokeLinejoin="round"
												/>
												<path
													d="M20.4316 45.9166C20.4686 47.4324 21.0969 48.8736 22.1821 49.9325C23.2674 50.9913 24.7237 51.5837 26.24 51.5833H45.7602C47.2765 51.5837 48.7328 50.9913 49.818 49.9325C50.9033 48.8736 51.5316 47.4324 51.5686 45.9166H20.4316Z"
													fill="black"
												/>
											</g>
											<defs>
												<clipPath id="clip0_519_679">
													<rect
														width="34"
														height="34"
														fill="white"
														transform="translate(19 19)"
													/>
												</clipPath>
											</defs>
										</svg>
									)}

									<p className={style.title}>
										{i18n.language == "ar"
											? offersList[2]
													?.License_Offer_Name_AR
											: offersList[2]
													?.License_Offer_Name_EN}
									</p>
									<p
										className={style.para}
										dangerouslySetInnerHTML={{
											__html:
												i18n.language == "ar"
													? offersList[2]
															?.License_Offer_Description_AR
													: offersList[2]
															?.License_Offer_Description_EN,
										}}
									/>
								</Col>

								<Col md={6}>
									<div className={style.image}>
										<img
											src={
												offersList[2]
													?.License_Offer_Image_URL
													? offersList[2]
															?.License_Offer_Image_URL
													: pic3
											}
										/>
										<div
											className={[
												style.cost,
												i18n.language == "en"
													? style.costEn
													: style.costAr,
											].join(" ")}
										>
											<p>
												{
													offersList[2]
														?.License_Offer_Price
												}{" "}
												{t("CurrencyName")}
											</p>
											<p
												dangerouslySetInnerHTML={{
													__html:
														i18n.language == "ar"
															? offersList[2]
																	?.License_Offer_Price_Description_AR
															: offersList[2]
																	?.License_Offer_Price_Description_EN,
												}}
											/>
										</div>
									</div>
								</Col>
							</Row>
						</div>
					</Col>

					<Col md={4}>
						<div className={style.offeringCard}>
							{offersList[3]?.License_Offer_Icon_URL ? (
								<img
									width="40px"
									alt={offersList[3]?.License_Offer_Name_EN}
									src={offersList[3]?.License_Offer_Icon_URL}
								/>
							) : (
								<svg
									width="70"
									height="70"
									viewBox="0 0 73 73"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<circle
										cx="36.5"
										cy="36.5"
										r="36.5"
										fill="#A7E0F7"
										fillOpacity="0.47"
									/>
									<g clipPath="url(#clip0_519_679)">
										<path
											d="M41.6674 26.0833H36.0008M41.6674 31.75H36.0008M41.6674 34.5833V20.4166H30.3341V34.5833L22.4503 41.3408C21.5473 42.1151 20.9032 43.1474 20.6045 44.2989C20.3058 45.4503 20.367 46.6656 20.7797 47.7812C21.1925 48.8968 21.9371 49.8592 22.9133 50.539C23.8894 51.2187 25.0504 51.5832 26.2399 51.5833H45.7616C46.9511 51.5832 48.1121 51.2187 49.0883 50.539C50.0644 49.8592 50.809 48.8968 51.2218 47.7812C51.6345 46.6656 51.6957 45.4503 51.397 44.2989C51.0983 43.1474 50.4542 42.1151 49.5512 41.3408L41.6674 34.5833Z"
											stroke="black"
											strokeWidth="2"
											strokeLinejoin="round"
										/>
										<path
											d="M20.4316 45.9166C20.4686 47.4324 21.0969 48.8736 22.1821 49.9325C23.2674 50.9913 24.7237 51.5837 26.24 51.5833H45.7602C47.2765 51.5837 48.7328 50.9913 49.818 49.9325C50.9033 48.8736 51.5316 47.4324 51.5686 45.9166H20.4316Z"
											fill="black"
										/>
									</g>
									<defs>
										<clipPath id="clip0_519_679">
											<rect
												width="34"
												height="34"
												fill="white"
												transform="translate(19 19)"
											/>
										</clipPath>
									</defs>
								</svg>
							)}

							<p className={style.title}>
								{i18n.language == "ar"
									? offersList[3]?.License_Offer_Name_AR
									: offersList[3]?.License_Offer_Name_EN}
							</p>
							<div className={style.cardBottom}>
								<div>
									<img
										src={
											offersList[3]
												?.License_Offer_Image_URL
												? offersList[3]
														?.License_Offer_Image_URL
												: pic4
										}
									/>
								</div>
								<div>
									<p>
										{offersList[3]?.License_Offer_Price}{" "}
										{t("CurrencyName")}
									</p>
									<p
										dangerouslySetInnerHTML={{
											__html:
												i18n.language == "ar"
													? offersList[3]
															?.License_Offer_Price_Description_AR
													: offersList[3]
															?.License_Offer_Price_Description_EN,
										}}
									/>
								</div>
							</div>
						</div>
					</Col>

					<Col md={4}>
						<div className={style.offeringCard}>
							{offersList[4]?.License_Offer_Icon_URL ? (
								<img
									width="40px"
									alt={offersList[4]?.License_Offer_Name_EN}
									src={offersList[4]?.License_Offer_Icon_URL}
								/>
							) : (
								<svg
									width="70"
									height="70"
									viewBox="0 0 73 73"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<circle
										cx="36.5"
										cy="36.5"
										r="36.5"
										fill="#A7E0F7"
										fillOpacity="0.47"
									/>
									<g clipPath="url(#clip0_519_679)">
										<path
											d="M41.6674 26.0833H36.0008M41.6674 31.75H36.0008M41.6674 34.5833V20.4166H30.3341V34.5833L22.4503 41.3408C21.5473 42.1151 20.9032 43.1474 20.6045 44.2989C20.3058 45.4503 20.367 46.6656 20.7797 47.7812C21.1925 48.8968 21.9371 49.8592 22.9133 50.539C23.8894 51.2187 25.0504 51.5832 26.2399 51.5833H45.7616C46.9511 51.5832 48.1121 51.2187 49.0883 50.539C50.0644 49.8592 50.809 48.8968 51.2218 47.7812C51.6345 46.6656 51.6957 45.4503 51.397 44.2989C51.0983 43.1474 50.4542 42.1151 49.5512 41.3408L41.6674 34.5833Z"
											stroke="black"
											strokeWidth="2"
											strokeLinejoin="round"
										/>
										<path
											d="M20.4316 45.9166C20.4686 47.4324 21.0969 48.8736 22.1821 49.9325C23.2674 50.9913 24.7237 51.5837 26.24 51.5833H45.7602C47.2765 51.5837 48.7328 50.9913 49.818 49.9325C50.9033 48.8736 51.5316 47.4324 51.5686 45.9166H20.4316Z"
											fill="black"
										/>
									</g>
									<defs>
										<clipPath id="clip0_519_679">
											<rect
												width="34"
												height="34"
												fill="white"
												transform="translate(19 19)"
											/>
										</clipPath>
									</defs>
								</svg>
							)}

							<p className={style.title}>
								{i18n.language == "ar"
									? offersList[4]?.License_Offer_Name_AR
									: offersList[4]?.License_Offer_Name_EN}
							</p>
							<div className={style.cardBottom}>
								<div>
									<img
										src={
											offersList[4]
												?.License_Offer_Image_URL
												? offersList[4]
														?.License_Offer_Image_URL
												: pic4
										}
									/>
								</div>
								<div>
									<p>
										{offersList[4]?.License_Offer_Price}{" "}
										{t("CurrencyName")}
									</p>
									<p
										dangerouslySetInnerHTML={{
											__html:
												i18n.language == "ar"
													? offersList[4]
															?.License_Offer_Price_Description_AR
													: offersList[4]
															?.License_Offer_Price_Description_EN,
										}}
									/>
								</div>
							</div>
						</div>
					</Col>

					<Col md={4} className={style.btn}>
						<NavLink to="/all_offers" exact>
							<div className={style.seeMore}>
								<p>{t("seeMoreOffers")}</p>
								<div>
									<svg
										width="43"
										height="18"
										viewBox="0 0 43 18"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M42.1401 9.84987C42.6095 9.3805 42.6095 8.6195 42.1401 8.15013L34.4913 0.501311C34.0219 0.0319414 33.2609 0.0319414 32.7916 0.501311C32.3222 0.970681 32.3222 1.73168 32.7916 2.20105L39.5905 9L32.7916 15.799C32.3222 16.2683 32.3222 17.0293 32.7916 17.4987C33.2609 17.9681 34.0219 17.9681 34.4913 17.4987L42.1401 9.84987ZM0 10.2019H41.2903V7.7981H0V10.2019Z"
											fill="#55C3EF"
										/>
									</svg>
								</div>
							</div>
						</NavLink>
					</Col>
				</Row>
			)}
		</Container>
	);
};

export default ToolCards;
