import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import style from './LanguageBtn.module.scss'

const LanguageBtn = ({ theme = 'white' }) => {

    const { t, i18n } = useTranslation('common')

    const handleLanguage = () => {
        if (i18n.language === 'en') {
            localStorage.setItem('lang', 'ar')
        } else {
            localStorage.setItem('lang', 'en')
        }
        window.location.reload()
    }

    useEffect(() => {
        if (window.getComputedStyle(document.body).getPropertyValue('font-size') && i18n.language == 'ar') {
            document.querySelector("html").style.fontSize = "18px";
        }
    }, [])

    return (
        <>
            <button onClick={handleLanguage} className={`${style.langBtn} ${theme === 'black' && style.blackTheme}`}>
                {i18n.language === 'en' ?
                    <>
                        <span>عربي</span>
                        <span className={[style.icon, style.engIcon].join(' ')}>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 6.669 0.109491 7.31243 0.31153 7.91334C0.576169 8.70044 1.60889 8.6768 2.28646 8.19674C2.83666 7.80691 3.24528 7.05111 3.14975 6.38361C3.11192 6.11922 3.093 5.86427 3.093 5.65189C3.093 3.68839 4.677 2.08789 6.69 2.08789C7.74828 2.08789 8.67189 2.52078 9.29188 3.29106C9.6223 3.70158 9.3954 4.27736 8.91743 4.49928C8.38975 4.74428 7.79347 4.37861 7.2689 4.12702C7.09441 4.04334 6.89098 4.00189 6.657 4.00189C5.8485 4.00189 5.271 4.72789 5.271 5.75089C5.271 6.27715 5.40382 6.69155 5.66492 6.99411C5.95047 7.325 6.42129 7.38439 6.85835 7.38439H8.77725C9.39236 7.38439 9.891 7.88303 9.891 8.49814C9.891 9.11325 9.39236 9.61189 8.77725 9.61189H5.3205C5.07036 9.61189 4.84189 9.62427 4.63431 9.6498C3.11732 9.8364 3.82109 11.798 5.34047 11.9642C5.55706 11.9878 5.77711 12 6 12Z"
                                    fill={`${theme === 'black' ? '#1B2124' : 'white'}`} />
                            </svg>
                        </span>
                    </>
                    :
                    <>
                        <span>English</span>
                        <span className={[style.icon, style.arIcon].join(' ')}>
                            <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.41633 10.9916C8.49625 10.318 10.0003 8.36504 10.0003 6.06076C10.0003 3.19886 7.68026 0.87883 4.81836 0.87883C3.64364 0.87883 2.56021 1.26972 1.69106 1.92853C2.09629 1.81678 2.52645 1.7609 2.98154 1.7609C4.00628 1.7609 4.82228 2.01393 5.42954 2.51997C6.04312 3.0197 6.47643 3.68072 6.72945 4.50305C6.9888 5.32538 7.11847 6.22994 7.11847 7.21673H0.277344C0.403856 8.15924 0.7486 8.90566 1.31158 9.45599C1.88088 9.99999 2.59251 10.272 3.44647 10.272C3.95251 10.272 4.44591 10.1992 4.92666 10.0538C5.4074 9.90827 5.90396 9.68371 6.41633 9.38008V10.9916Z" fill="white" />
                                <path d="M5.35363 5.84091H0.296321C0.422832 5.00593 0.716973 4.35123 1.17874 3.87681C1.64683 3.39607 2.22879 3.1557 2.9246 3.1557C3.43065 3.1557 3.85763 3.27588 4.20554 3.51626C4.55345 3.75663 4.81596 4.07607 4.99307 4.47458C5.17019 4.86677 5.29038 5.32221 5.35363 5.84091Z" fill="white" />
                            </svg>
                        </span>
                    </>
                }
            </button>
        </>
    )
}

export default LanguageBtn
