import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import style from "./Menu.module.scss";
import Items from "./Items/Items";
import Icons from "./Icons/Icons";
import { NavLink } from "react-router-dom";
import PrimaryLogo from "global/images/Government_Of_Sharjah.svg";
import STRIPLogo from "global/imagesTemp/STRIP.png";

const Menu = props => {
  // const [isSticky, setIsSticky] = useState(false)
  const [withBg, setWithBg] = useState(false)
  let headerRef = useRef()

  window.onscroll = () => handleHeaderPosition()

  const handleHeaderPosition = () => {
    if (window.pageYOffset > headerRef?.current?.offsetTop) {
      // setIsSticky(true)
      setWithBg(true)
    } else {
      // setIsSticky(false)
      setWithBg(false)
    }
  }

  return (
    <div className={[style.menu, style.sticky, withBg ? style.menuWithbg : null].join(" ")} id="header" ref={headerRef}>
      <div className="container-fluid h-100">
        <Row className="h-100">
          {/* <Row className={[style.menu, isSticky ? style.sticky : null].join(" ")} id="header" ref={headerRef}> */}
          <Col xl={2} md={2} sm={4} xs={8} className="h-100">
            <div className={style.logo}>
              <NavLink
                to="/"
                style={{ display: "block", width: "100%", height: "100%" }}
              >
                <img src={process.env.REACT_APP_IMG_SRC == 2 ? STRIPLogo : PrimaryLogo} />
              </NavLink>
            </div>
          </Col>
          <Col xl={10} md={10} sm={8} xs={4} style={{ zIndex: '99999999' }}>
            <div className="d-flex align-items-center w-100 h-100 justify-content-end justify-content-xl-start">
              <Items municipal={props.municipal} />
            </div>

          </Col>

          {/* <Col xl={4} md={4} sm={3} className={style.icons}>
            <Icons />
          </Col> */}
        </Row>
      </div>
    </div>
  );
};

export default Menu;
