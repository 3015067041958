const UnderProccess = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path d="M26.7143 11.91C26.6955 11.0823 26.4237 10.2801 25.9354 9.61144C24.0676 7.06874 22.5819 5.48752 20.1326 3.59642C19.4563 3.07918 18.6322 2.79207 17.7809 2.77715C16.8833 2.75742 15.9854 2.74751 15.0875 2.74744C12.2074 2.74744 9.88542 2.83446 7.48069 3.00213C6.4928 3.06859 5.56275 3.4919 4.8638 4.19319C4.16485 4.89448 3.74466 5.82594 3.6815 6.81405C3.50197 9.69991 3.41278 12.5907 3.41408 15.4821C3.41408 18.4536 3.50746 21.3528 3.68363 24.1502C3.81097 26.1963 5.43465 27.8242 7.48281 27.9664C8.02828 28.0025 8.57375 28.0364 9.11922 28.0661" stroke="#94AFD5" strokeWidth="2.28571" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M22.8379 16.9657L15.1143 23.7321L13.6286 27.9494C13.3909 28.6286 14.0318 29.3821 14.7429 29.2568L19.234 28.4609L27.1358 22.0448C28.4496 20.9793 28.5112 18.9226 27.2738 17.5027C26.064 16.1189 24.0795 15.879 22.8379 16.9657Z" stroke="#94AFD5" strokeWidth="2.28571" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.09375 10.5453L10.0464 12.3621C10.9654 9.98285 11.7295 8.93861 13.4635 7.52081" stroke="#94AFD5" strokeWidth="2.28571" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.09375 18.5087L10.0464 20.3234C10.9654 17.9442 11.7295 16.8999 13.4635 15.4843" stroke="#94AFD5" strokeWidth="2.28571" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}


export default UnderProccess;
