import React from 'react'
import Header from './../../UIComponents/Header/Header'
import Footer from './../../UIComponents/Footer/Footer'
import InnerTitleCont from "./../../UIComponents/InnerTitleCont/InnerTitleCont"
import { useTranslation } from 'react-i18next'
import { Container, Row, Col } from 'react-bootstrap'
import style from './Footer.module.scss'
import { NavLink } from "react-router-dom";

const PrivacyPolicy = () => {

    const { t, i18n } = useTranslation('common')

    return (
        <>
            <Header />
            <InnerTitleCont
                breadCrumbs={
                    <>
                        <NavLink to="/homePage">
                            <span>
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.6893 2.97833L7.32932 0.6275C6.41349 -0.0141667 5.00766 0.0208334 4.12682 0.703333L1.20432 2.98417C0.62099 3.43917 0.160156 4.3725 0.160156 5.1075V9.1325C0.160156 10.62 1.36766 11.8333 2.85516 11.8333H9.14349C10.631 11.8333 11.8385 10.6258 11.8385 9.13833V5.18333C11.8385 4.39583 11.331 3.4275 10.6893 2.97833ZM6.43682 9.5C6.43682 9.73917 6.23849 9.9375 5.99932 9.9375C5.76016 9.9375 5.56182 9.73917 5.56182 9.5V7.75C5.56182 7.51083 5.76016 7.3125 5.99932 7.3125C6.23849 7.3125 6.43682 7.51083 6.43682 7.75V9.5Z" fill="white" />
                                </svg>
                            </span>
                        </NavLink>

                        <span>
                            <svg
                                style={{
                                    margin: '0 8px',
                                    transform: i18n.language == 'ar' && 'scale(-1)'
                                }}
                                width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L5 5L1 9" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </span>

                        <span>{t("privacyPolicy")}</span>
                    </>
                }
                title={t("privacyPolicy")}
                startBtn={false}
                leaveBtn={false}
            />

            <Container className={style.privacyPage}>
                <Row>
                    <Col md={12} className='mb-3'>
                        <p className={style.mainTitle}>{t('systemPolicyandPrivacyStatement')}</p>
                        <p className={style.boldText}>{t('systemPolicyPara1')}</p>
                    </Col>

                    <Col md={12} className='mb-3'>
                        <p className={style.subTitle}>{t('introduction')}</p>
                        <p>{t('introductionPara')}</p>
                    </Col>

                    <Col md={12} className='mb-3'>
                        <p className={style.boldText}>{t('introductionQuest1')}</p>
                        <p>{t('introductionQuestPara')}</p>
                        <ul>
                            <li>{t('introductionQuestPara1')}</li>
                            <li>{t('introductionQuestPara2')}</li>
                            <li>{t('introductionQuestPara3')}</li>
                            <li>{t('introductionQuestPara4')}</li>
                        </ul>
                    </Col>

                    <Col md={12}>
                        <div className='mb-5'>
                            <p className={style.boldText}>{t('introductionQuest2')}</p>
                            <p>{t('introductionQuest2Para1')}</p>
                            <p>{t('introductionQuest2Para2')}</p>
                            <p>{t('introductionQuest2Para3')}</p>
                            <p>{t('introductionQuest2Para4')}</p>
                        </div>

                        <Row>
                            <Col md={6}>
                                <div className='mb-5'>
                                    <p className={style.heading}>{t('CONSENT')}</p>
                                    <p>{t('CONSENTPara')}</p>
                                </div>

                                <div className='mb-5'>
                                    <p className={style.heading}>{t('cookiesAndTrackingTechnologies')}</p>
                                    <p>{t('cookiesAndTrackingTechnologiesPara1')}</p>
                                    <p>{t('cookiesAndTrackingTechnologiesPara2')}</p>
                                </div>

                                <div>
                                    <p className={style.heading}>{t('complianceWithLaws')}</p>
                                    <p>{t('complianceWithLawsPara')}</p>
                                </div>
                            </Col>

                            <Col md={6}>
                                <div className='mb-5'>
                                    <p className={style.heading}>{t('AGGREGATEDATA')}</p>
                                    <p>{t('AGGREGATEDATAPara1')}</p>
                                    <p>{t('AGGREGATEDATAPara2')}</p>
                                </div>

                                <div className='mb-5'>
                                    <p className={style.heading}>{t('thirdPartyAccess')}</p>
                                    <p>{t('thirdPartyAccessPara')}</p>
                                </div>

                                <div className='mb-5'>
                                    <p className={style.heading}>{t('changestothePrivacyStatement')}</p>
                                    <p>{t('changestothePrivacyStatementPara')}</p>
                                </div>

                                <div>
                                    <p className={style.heading}>{t('contactInformation')}</p>
                                    <p>{t('contactInformationPara')}</p>
                                </div>
                            </Col>

                            <Col md={12}>
                                <div className='mb-5'>
                                    <p className={style.heading}>{t('dataSecurity')}</p>
                                    <p>{t('dataSecurityPara')}</p>
                                </div>

                                {/* <div className='mb-5'>
                                    <p className={style.heading}>Access Rights and Permissions</p>
                                    <ul className={style.rightsPermissionsList}>
                                        <li className='mb-5'>
                                            <p className={style.coloredHeading}>Department Roles</p>
                                            <p>Our system operates with a role-based access control system to manage user permissions based on their respective department roles. The following outlines the access rights and permissions associated with each department role:</p>
                                            <ul className={style.depRolesList}>
                                                <li><span className={style.coloredHeading}>Admin Role:</span> Admin users have full access to all functionalities within the system. They are responsible for overseeing and managing user roles, data security, and system configurations.</li>
                                                <li><span className={style.coloredHeading}>Sales Role:</span> Users in the Sales department have access to customer-related data, sales reports, and other functionalities pertinent to their role. They do not have access to sensitive financial or administrative information.</li>
                                                <li><span className={style.coloredHeading}>IT Role:</span> IT department users have access to system configurations, network settings, and technical troubleshooting functionalities. They do not have access to non-technical user data unless required for system maintenance.</li>
                                                <li><span className={style.coloredHeading}>Finance Role:</span> Finance department users have access to financial records, billing information, and related reports. However, they do not have access to non-financial user data or system configurations.</li>
                                                <li><span className={style.coloredHeading}>Marketing Role:</span> Users in the Marketing department have access to marketing-related data, customer communication history, and analytics tools tailored to their role. They do not have access to confidential HR or financial data.</li>
                                            </ul>
                                        </li>

                                        <li className='mb-5'>
                                            <p className={style.coloredHeading}>Access Requests and Review:</p>
                                            <p>Employees can request additional access or report any concerns related to access rights by contacting the designated department head or the system administrator. Access rights are regularly reviewed and adjusted to align with employees' roles and responsibilities.</p>
                                        </li>

                                        <li className='mb-5'>
                                            <p className={style.coloredHeading}>Training and Compliance</p>
                                            <p>All users are required to undergo training on their respective department roles and associated access rights. Non-compliance with the established access rights may result in disciplinary action, including revocation of system access.</p>
                                            <p>By implementing this role-based access control system, we aim to ensure that each employee has the necessary permissions to perform their job responsibilities while maintaining the confidentiality and security of sensitive information within our system.</p>
                                        </li>
                                    </ul>
                                </div> */}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

            <Footer />
        </>
    )
}

export default PrivacyPolicy