import React, { useState, useRef, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import UserDropdown from "./../Icons/UserDropdown/UserDropdown";
import style from "./UserLogin.module.scss"
import useDetectOutsideClick from "hooks/useDetectOutsideClick";
import { toState } from "./../../../../../features/user/userMachineSlice";
import Sidebar from 'react-sidebar'
import Notifications from './../Notifications/Notifications'
import Badge from 'react-bootstrap/Badge'
import axios from "axios"
import userImg from './../../../../../global/images/user-image.png'

const UserLogin = () => {

    const { t, i18n } = useTranslation('common')
    const { user, clientProfiles, isAuthenticated } = useSelector((state) => state.user)
    const dropdownRef = useRef(null)
    const [dropOpen, setDropOpen] = useDetectOutsideClick(dropdownRef, false)
    const dispatch = useDispatch()
    const mql = window.matchMedia(`(min-width: 800px)`)
    const [notifState, setNotifState] = useState(false)
    const [notif, setNotif] = useState({
        new: false,
        unreaded: false,
        number: ''
    })

    useEffect(() => {
        mql.addEventListener('change', () => mediaQueryChanged)
        if (user?.access_token) {
            CheckWebNotifications()
        }
    }, [])

    useEffect(() => () => mql.removeEventListener('change', () => mediaQueryChanged), [])

    const onSetSidebarOpen = open => setNotifState(open)
    const mediaQueryChanged = () => setNotifState(false)
    const onCloseDropdown = () => setDropOpen(false)

    const CheckWebNotifications = async () => {
        const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/SYSTEM/USERS/NOTIFICATIONS/CHECK_WEB_NOTIFICATIONS`, {},
            {
                headers: {
                    Authorization: `Bearer ${user?.access_token}`,
                },
            }
        )
        let newNotif = res.data.Data.New_Notifications
        let unreadedNotif = res.data.Data.Unread_Notifications

        if (newNotif > 0) {
            setNotif({
                new: true,
                unreaded: false,
                number: newNotif
            })
        } else {
            setNotif({
                new: false,
                unreaded: true,
                number: unreadedNotif
            })
        }
    }

    const handleNotificationsCheck = () => {
        CheckWebNotifications()
    }

    const handleDropdown = e => {
        if (window.innerWidth > 768) {
            e.target.tagName == 'DIV' && !notifState && setDropOpen(!dropOpen)
        }
    }

    const handleClose = () => {
        setNotifState(false)
    }

    return (
        <>
            {!isAuthenticated ? (
                <NavLink exact to="/login">
                    <button
                        className={[style.siginIn, i18n.language === "en" ? style.signEn : style.signAr].join(" ")}
                        onClick={() => dispatch(toState("Login"))}
                    >
                        {t("login_in")}
                    </button>
                </NavLink>
            ) : (
                <>
                    <div
                        // ref={dropdownRef}
                        className={style.menuItem}
                        onClick={(e) => handleDropdown(e)}
                    >
                        <a className={dropOpen ? style.activeLink : null}>
                            <div className={style.userWrapper} >
                                <span>
                                    <img src={userImg} className={style.userImg} />
                                </span>
                                <div>
                                    <span>
                                        {i18n.language === "en" ?
                                            user?.User_Name_EN.split(' ').slice(0, 2).join(' ') || t("user")
                                            :
                                            user?.User_Name_AR.split(' ').slice(0, 2).join(' ') || t("user")
                                        }
                                    </span>
                                    <span className={style.profileType}>
                                        {i18n.language === "en" ? clientProfiles?.Dealing_Type_Name_EN : clientProfiles?.Dealing_Type_Name_AR}
                                    </span>
                                </div>
                                <Sidebar
                                    sidebar={
                                        <Notifications notificationsCheck={handleNotificationsCheck} close={handleClose} />
                                    }
                                    open={notifState}
                                    onSetOpen={onSetSidebarOpen}
                                    shadow={true}
                                    transitions={true}
                                    overlayClassName='notifOverlayStyle'
                                    sidebarClassName={[
                                        'sideBarStyle',
                                        i18n.language == 'en' ? 'sideBarEnStyle' : 'sideBarArStyle',
                                        i18n.language == 'ar' && !notifState && 'sideBarArCloseStyle'
                                    ].join(' ')}
                                >
                                    <span
                                        onClick={() => onSetSidebarOpen(!notifState)}
                                        className={[
                                            style.siderbarBtn,
                                            i18n.language == 'en' ? style.siderbarEnBtn : style.siderbarArBtn
                                        ].join(' ')}
                                    >
                                        <Badge
                                            className={[
                                                notif.new ? style.newBadge : style.unreadedBadge,
                                                style.Badge
                                            ].join(' ')}
                                        >
                                            {notif.number}
                                        </Badge>
                                        <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M4.0515 11.8304V11.643C4.07965 11.0884 4.26157 10.5511 4.5785 10.0865C5.10603 9.52834 5.46716 8.84435 5.62398 8.1063C5.62398 7.53587 5.62398 6.95729 5.67498 6.38686C5.93848 3.64066 8.71795 1.74194 11.4634 1.74194H11.5314C14.2769 1.74194 17.0563 3.64066 17.3283 6.38686C17.3793 6.95729 17.3283 7.53587 17.3708 8.1063C17.5298 8.84606 17.8905 9.53218 18.4163 10.0946C18.7356 10.5552 18.9178 11.0905 18.9433 11.643V11.8222C18.9623 12.5672 18.6996 13.2937 18.2038 13.8676C17.5486 14.5387 16.6595 14.9562 15.7049 15.0411C12.9054 15.3345 10.0809 15.3345 7.28146 15.0411C6.32786 14.9525 5.44004 14.5356 4.7825 13.8676C4.29433 13.2933 4.03522 12.5711 4.0515 11.8304Z" stroke="white" strokeWidth="1.31413" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M9.35547 17.8767C9.79288 18.4131 10.4352 18.7602 11.1403 18.8413C11.8454 18.9223 12.5551 18.7306 13.1124 18.3086C13.2838 18.1838 13.438 18.0387 13.5714 17.8767" stroke="white" strokeWidth="1.31413" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </span>
                                </Sidebar>
                            </div>
                        </a>

                        <div
                            className={[
                                style.verif,
                                clientProfiles?.Profile_Active_Status_Id == -1 ? style.profileInactive
                                    : clientProfiles?.Profile_Active_Status_Id == 0 ? style.profilePending
                                        : clientProfiles?.Profile_Active_Status_Id == 1 ? style.profileActive
                                            : clientProfiles?.Profile_Active_Status_Id == 2 ? style.profileInactive
                                                : clientProfiles?.Profile_Active_Status_Id == 5 ? style.profilePending
                                                    : clientProfiles?.Profile_Active_Status_Id == 6 ? style.profileInactive
                                                        : clientProfiles?.Profile_Active_Status_Id == 7 ? style.profileInactive
                                                            : false
                            ].join(' ')}
                        >
                            <div className={style.verifLine}></div>
                            <span className={style.profileStatus}>
                                {i18n.language === "en" ? clientProfiles?.Profile_Active_Status_Name_EN : clientProfiles?.Profile_Active_Status_Name_AR}
                            </span>
                        </div>
                    </div>

                    {dropOpen && (
                        <div
                            onClick={() => setDropOpen(false)}
                            style={{
                                position: 'fixed',
                                top: '0',
                                bottom: '0',
                                right: '0',
                                left: '0',
                            }}
                        ></div>
                    )}

                    <UserDropdown dropOpen={dropOpen} onCloseDropdown={onCloseDropdown} />
                </>
            )}
        </>
    )
}

export default UserLogin
