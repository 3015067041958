import React, { useState, useEffect } from 'react'
import { Row, Col, Tooltip, OverlayTrigger } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import style from './Footer.module.scss'
import axios from 'axios'
import { useLocation, useParams, NavLink } from 'react-router-dom'
import * as icons from "../../../features/service/icons";

const ContactUs = ({ data }) => {

    const { t, i18n } = useTranslation('common')
    const [departmentData, setDepartmentData] = useState([])
    const [loading, setLoading] = useState(false)
    let { municipalId } = useParams()

    return (
        <>
            <Row className='mb-1'>
                <Col md={3} className='d-flex justify-content-center align-items-center'>
                    <span>{t('followUs')}</span>
                </Col>

                <Col md={9}>
                    <ul className={style.socialMedia}>

                        {data?.Department_Twitter &&
                            <li>
                                <a
                                    target="_blank"
                                    href={/(http(s?)):\/\//i.test(data?.Department_Twitter) ? data?.Department_Twitter : `https://${data?.Department_Twitter}`}
                                >
                                    <span>
                                        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                            width="24" height="24" viewBox="0 0 512.000000 512.000000"
                                            preserveAspectRatio="xMidYMid meet">

                                            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                                fill="#000000" stroke="none">
                                                <path d="M75 5093 c10 -16 448 -653 973 -1418 525 -765 955 -1395 955 -1400 0
                                                -6 -431 -512 -959 -1126 -527 -613 -965 -1123 -973 -1132 -13 -16 1 -17 206
                                                -17 l220 0 849 987 c467 542 854 988 859 989 6 2 316 -442 689 -986 l679 -990
                                                744 0 c705 0 743 1 734 18 -5 9 -460 672 -1010 1472 -550 800 -1001 1457
                                                -1001 1460 0 4 418 492 929 1085 l928 1080 -215 3 c-177 2 -218 0 -231 -12 -9
                                                -8 -374 -431 -811 -939 -437 -509 -798 -923 -802 -921 -4 3 -296 425 -649 939
                                                l-642 935 -745 0 -744 0 17 -27z m2815 -2529 c855 -1224 1557 -2229 1558
                                                -2235 3 -6 -118 -8 -334 -7 l-338 3 -1558 2228 c-857 1226 -1558 2231 -1558
                                                2233 0 2 152 4 338 4 l337 -1 1555 -2225z"/>
                                            </g>
                                        </svg>
                                    </span>
                                </a>
                            </li>
                        }

                        {data?.Department_Youtube &&
                            <li>
                                <a
                                    href={/(http(s?)):\/\//i.test(data?.Department_Youtube) ? data?.Department_Youtube : `https://${data?.Department_Youtube}`}
                                    target="_blank"
                                >
                                    <svg width="16" height="12" viewBox="0 0 16 12" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.6657 1.80248C15.4817 1.09298 14.9395 0.534206 14.2511 0.344578C13.0033 0 8 0 8 0C8 0 2.99669 0 1.7489 0.344578C1.06052 0.534236 0.518349 1.09298 0.334336 1.80248C0 3.08848 0 5.7716 0 5.7716C0 5.7716 0 8.45472 0.334336 9.74072C0.518349 10.4502 1.06052 10.9857 1.7489 11.1753C2.99669 11.5199 8 11.5199 8 11.5199C8 11.5199 13.0033 11.5199 14.2511 11.1753C14.9395 10.9857 15.4817 10.4502 15.6657 9.74072C16 8.45472 16 5.7716 16 5.7716C16 5.7716 16 3.08848 15.6657 1.80248ZM6.36363 8.20767V3.33553L10.5454 5.77166L6.36363 8.20767Z" fill="#fff" />
                                    </svg>
                                </a>
                            </li>
                        }

                        {data?.Department_Instagram &&
                            <li>
                                <a
                                    target="_blank"
                                    href={/(http(s?)):\/\//i.test(data?.Department_Instagram) ? data?.Department_Instagram : `https://${data?.Department_Instagram}`}

                                >
                                    <span>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_119_665)">
                                                <g clipPath="url(#clip1_119_665)">
                                                    <path d="M12.0027 9.77589C10.2992 9.77589 8.92506 11.15 8.92506 12.8536C8.92506 14.5571 10.2992 15.9312 12.0027 15.9312C13.7063 15.9312 15.0804 14.5571 15.0804 12.8536C15.0804 11.15 13.7063 9.77589 12.0027 9.77589ZM12.0027 14.8545C10.9018 14.8545 10.0018 13.9571 10.0018 12.8536C10.0018 11.75 10.8992 10.8527 12.0027 10.8527C13.1063 10.8527 14.0036 11.75 14.0036 12.8536C14.0036 13.9571 13.1036 14.8545 12.0027 14.8545ZM15.9242 9.65C15.9242 10.0491 15.6027 10.3679 15.2063 10.3679C14.8072 10.3679 14.4884 10.0464 14.4884 9.65C14.4884 9.25357 14.8099 8.93214 15.2063 8.93214C15.6027 8.93214 15.9242 9.25357 15.9242 9.65ZM17.9626 10.3786C17.917 9.41696 17.6974 8.56518 16.9929 7.86339C16.2911 7.16161 15.4393 6.94196 14.4777 6.89375C13.4867 6.8375 10.5161 6.8375 9.52506 6.89375C8.56613 6.93929 7.71434 7.15893 7.00988 7.86071C6.30541 8.5625 6.08845 9.41429 6.04023 10.3759C5.98398 11.367 5.98398 14.3375 6.04023 15.3286C6.08577 16.2902 6.30541 17.142 7.00988 17.8438C7.71434 18.5455 8.56345 18.7652 9.52506 18.8134C10.5161 18.8696 13.4867 18.8696 14.4777 18.8134C15.4393 18.7679 16.2911 18.5482 16.9929 17.8438C17.6947 17.142 17.9143 16.2902 17.9626 15.3286C18.0188 14.3375 18.0188 11.3696 17.9626 10.3786ZM16.6822 16.392C16.4733 16.917 16.0688 17.3214 15.5411 17.533C14.7509 17.8464 12.8759 17.7741 12.0027 17.7741C11.1295 17.7741 9.25184 17.8438 8.46434 17.533C7.93934 17.3241 7.53488 16.9196 7.32327 16.392C7.00988 15.6018 7.0822 13.7268 7.0822 12.8536C7.0822 11.9804 7.01256 10.1027 7.32327 9.31518C7.5322 8.79018 7.93666 8.38571 8.46434 8.17411C9.25452 7.86071 11.1295 7.93304 12.0027 7.93304C12.8759 7.93304 14.7536 7.86339 15.5411 8.17411C16.0661 8.38304 16.4706 8.7875 16.6822 9.31518C16.9956 10.1054 16.9233 11.9804 16.9233 12.8536C16.9233 13.7268 16.9956 15.6045 16.6822 16.392Z" fill="white" />
                                                </g>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_119_665">
                                                    <rect width="24" height="24" fill="white" />
                                                </clipPath>
                                                <clipPath id="clip1_119_665">
                                                    <rect width="12" height="13.7143" fill="white" transform="translate(6 6)" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </span>
                                </a>
                            </li>
                        }

                        {data?.Department_LinkedIn &&
                            <li>
                                <a
                                    target="_blank"
                                    href={/(http(s?)):\/\//i.test(data?.Department_LinkedIn) ? data?.Department_LinkedIn : `https://${data?.Department_LinkedIn}`}
                                >
                                    <span>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_119_661)">
                                                <g clipPath="url(#clip1_119_661)">
                                                    <path d="M9.35031 16.4998H7.17344V9.48961H9.35031V16.4998ZM8.2607 8.53336C7.56461 8.53336 7 7.9568 7 7.2607C7 6.92634 7.13282 6.60568 7.36925 6.36925C7.60568 6.13282 7.92634 6 8.2607 6C8.59506 6 8.91573 6.13282 9.15215 6.36925C9.38858 6.60568 9.52141 6.92634 9.52141 7.2607C9.52141 7.9568 8.95656 8.53336 8.2607 8.53336ZM17.4977 16.4998H15.3255V13.0873C15.3255 12.274 15.3091 11.231 14.1937 11.231C13.0619 11.231 12.8884 12.1146 12.8884 13.0287V16.4998H10.7139V9.48961H12.8017V10.4459H12.8322C13.1228 9.89508 13.8327 9.31383 14.8919 9.31383C17.095 9.31383 17.5 10.7646 17.5 12.649V16.4998H17.4977Z" fill="white" />
                                                </g>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_119_661">
                                                    <rect width="24" height="24" fill="white" />
                                                </clipPath>
                                                <clipPath id="clip1_119_661">
                                                    <rect width="10.5" height="12" fill="white" transform="translate(7 6)" />
                                                </clipPath>
                                            </defs>
                                        </svg>

                                    </span>
                                </a>
                            </li>
                        }

                        {data?.Department_Facebook &&
                            <li>
                                <a
                                    target="_blank"
                                    href={/(http(s?)):\/\//i.test(data?.Department_Facebook) ? data?.Department_Facebook : `https://${data?.Department_Facebook}`}
                                >
                                    <span>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_119_653)">
                                                <path d="M13.6008 8H15.0008C15.1208 8 15.2008 7.92 15.2008 7.8V6.2C15.2008 6.08 15.1208 6 15.0008 6H13.4008C11.9208 6 10.8008 7.12 10.8008 8.6V10.4H9.00078C8.88078 10.4 8.80078 10.48 8.80078 10.6V12.2C8.80078 12.32 8.88078 12.4 9.00078 12.4H10.8008V17.8C10.8008 17.92 10.8808 18 11.0008 18H12.6008C12.7208 18 12.8008 17.92 12.8008 17.8V12.4H14.6008C14.6808 12.4 14.7608 12.32 14.8008 12.24L15.2008 10.64C15.2008 10.6 15.2008 10.52 15.1608 10.48C15.1208 10.44 15.0808 10.4 15.0008 10.4H12.8008V8.8C12.8008 8.36 13.1608 8 13.6008 8Z" fill="white" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_119_653">
                                                    <rect width="24" height="24" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </span>
                                </a>
                            </li>
                        }
                    </ul>
                </Col>
            </Row>

            <Row className='mb-3'>
                <Col md={3} className='d-flex justify-content-center align-items-center'>
                    <span>{t('contactUs')}</span>
                </Col>

                <Col md={9}>
                    <ul className={style.socialMedia}>
                        {data?.Department_Whatsapp &&
                            <li>
                                <a
                                    target="_blank"
                                    href={`https://api.whatsapp.com/send?phone=${data?.Department_Whatsapp}`}
                                >
                                    <span>
                                        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                            width="24pt" height="24pt" viewBox="0 0 512.000000 512.000000"
                                            preserveAspectRatio="xMidYMid meet">

                                            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                                fill="#000000" stroke="none">
                                                <path d="M2290 4945 c-1101 -136 -1967 -1020 -2081 -2124 -17 -163 -7 -473 21
                                            -630 43 -247 115 -465 227 -688 l56 -112 -167 -611 c-92 -337 -166 -613 -164
                                            -614 2 -2 282 71 623 161 496 131 625 162 645 154 14 -5 79 -34 145 -64 214
                                            -97 439 -161 676 -193 165 -22 454 -22 614 0 526 74 995 306 1365 676 194 193
                                            323 370 445 611 110 217 188 454 232 704 25 146 25 577 0 730 -43 259 -110
                                            466 -223 695 -323 651 -919 1115 -1632 1270 -222 48 -553 63 -782 35z m625
                                            -414 c287 -53 525 -149 760 -306 402 -269 697 -686 814 -1148 86 -340 79 -726
                                            -18 -1053 -158 -528 -533 -973 -1025 -1213 -286 -140 -550 -201 -871 -201
                                            -368 0 -693 89 -1026 281 l-56 32 -368 -97 c-202 -53 -370 -95 -371 -93 -2 2
                                            39 160 92 352 52 192 96 356 96 364 1 9 -25 59 -57 111 -472 768 -348 1780
                                            295 2420 320 318 726 514 1180 570 123 15 429 4 555 -19z"/>
                                                <path d="M1641 3658 c-57 -28 -163 -153 -207 -245 -87 -180 -85 -408 5 -618
                                            110 -259 399 -627 684 -871 200 -171 367 -272 612 -368 251 -98 299 -109 465
                                            -109 131 0 152 2 212 25 100 38 175 85 249 158 58 57 70 75 89 135 23 76 37
                                            197 26 239 -5 22 -34 40 -176 111 -260 130 -365 175 -409 175 -37 0 -43 -4
                                            -87 -62 -101 -133 -194 -236 -218 -242 -29 -7 -86 14 -217 80 -213 106 -386
                                            249 -535 440 -81 104 -154 222 -154 250 0 11 38 70 84 130 90 117 116 161 116
                                            194 0 11 -20 66 -43 123 -24 56 -72 172 -107 257 -44 106 -74 165 -96 188
                                            l-32 32 -108 0 c-91 0 -116 -4 -153 -22z"/>
                                            </g>
                                        </svg>
                                    </span>
                                </a>
                            </li>
                        }

                        {data?.Department_Address_AR &&
                            <li className='d-block d-lg-none'>
                                <OverlayTrigger
                                    overlay={
                                        <Tooltip id="button-tooltip">
                                            {i18n.language == 'ar' ? data?.Department_Address_AR : data?.Department_Address_EN}
                                        </Tooltip>
                                    }
                                >
                                    <span className={[style.homeIcon].join(' ')}>
                                        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                            width="16" height="16" viewBox="0 0 512.000000 512.000000"
                                            preserveAspectRatio="xMidYMid meet">

                                            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                                fill="#fff" stroke="none">
                                                <path d="M2509 4627 c-20 -8 -49 -23 -65 -35 -16 -11 -565 -558 -1221 -1214
                                            -843 -843 -1197 -1204 -1208 -1230 -19 -47 -19 -89 0 -135 21 -50 42 -72 90
                                            -94 49 -23 94 -24 143 -4 26 11 372 351 1175 1153 l1137 1137 1138 -1137 c802
                                            -802 1148 -1142 1174 -1153 49 -20 94 -19 143 4 48 22 69 44 90 94 19 46 19
                                            88 0 135 -11 26 -202 224 -620 642 l-605 605 0 405 c0 450 -1 456 -69 513 -80
                                            67 -217 39 -268 -56 -15 -27 -18 -68 -21 -266 l-4 -234 -421 421 c-232 231
                                            -436 427 -452 435 -47 23 -96 28 -136 14z"/>
                                                <path d="M2500 3919 c-49 -14 -1939 -1902 -1973 -1969 -22 -44 -22 -52 -25
                                            -539 -2 -296 1 -520 7 -559 30 -192 177 -337 371 -363 42 -6 273 -9 558 -7
                                            466 4 489 5 543 25 118 44 199 119 252 231 l32 67 6 420 c3 252 10 432 16 451
                                            42 121 163 217 273 218 85 0 205 -77 247 -158 34 -66 37 -97 42 -511 l6 -420
                                            32 -67 c53 -113 135 -187 259 -234 45 -17 93 -18 536 -22 286 -2 516 1 558 7
                                            191 26 335 164 370 353 7 37 10 239 8 560 -3 458 -5 507 -21 543 -13 28 -291
                                            313 -960 983 -518 518 -958 954 -977 967 -41 29 -110 39 -160 24z"/>
                                            </g>
                                        </svg>
                                    </span>
                                </OverlayTrigger>
                            </li>
                        }

                        {data?.Department_Location &&
                            <li>
                                <a
                                    href={/(http(s?)):\/\//i.test(data?.Department_Location) ? data?.Department_Location : `https://${data?.Department_Location}`}
                                    target="_blank"
                                >
                                    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M20.6191 8.45C19.5691 3.83 15.5391 1.75 11.9991 1.75C11.9991 1.75 11.9991 1.75 11.9891 1.75C8.45912 1.75 4.41912 3.82 3.36912 8.44C2.19912 13.6 5.35912 17.97 8.21912 20.72C9.27912 21.74 10.6391 22.25 11.9991 22.25C13.3591 22.25 14.7191 21.74 15.7691 20.72C18.6291 17.97 21.7891 13.61 20.6191 8.45ZM11.9991 13.46C10.2591 13.46 8.84912 12.05 8.84912 10.31C8.84912 8.57 10.2591 7.16 11.9991 7.16C13.7391 7.16 15.1491 8.57 15.1491 10.31C15.1491 12.05 13.7391 13.46 11.9991 13.46Z" fill="#fff" />
                                    </svg>
                                </a>
                            </li>
                        }

                        {data?.Department_Telephone &&
                            <li>
                                {window.screen.width >= 1280 ?
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip id="button-tooltip">
                                                {data?.Department_Telephone}
                                            </Tooltip>
                                        }
                                    >
                                        <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.05 14.95L9.2 16.8C8.81 17.19 8.19 17.19 7.79 16.81C7.68 16.7 7.57 16.6 7.46 16.49C6.43 15.45 5.5 14.36 4.67 13.22C3.85 12.08 3.19 10.94 2.71 9.81C2.24 8.67 2 7.58 2 6.54C2 5.86 2.12 5.21 2.36 4.61C2.6 4 2.98 3.44 3.51 2.94C4.15 2.31 4.85 2 5.59 2C5.87 2 6.15 2.06 6.4 2.18C6.66 2.3 6.89 2.48 7.07 2.74L9.39 6.01C9.57 6.26 9.7 6.49 9.79 6.71C9.88 6.92 9.93 7.13 9.93 7.32C9.93 7.56 9.86 7.8 9.72 8.03C9.59 8.26 9.4 8.5 9.16 8.74L8.4 9.53C8.29 9.64 8.24 9.77 8.24 9.93C8.24 10.01 8.25 10.08 8.27 10.16C8.3 10.24 8.33 10.3 8.35 10.36C8.53 10.69 8.84 11.12 9.28 11.64C9.73 12.16 10.21 12.69 10.73 13.22C10.83 13.32 10.94 13.42 11.04 13.52C11.44 13.91 11.45 14.55 11.05 14.95Z" fill="#fff" />
                                            <path d="M21.9696 18.33C21.9696 18.61 21.9196 18.9 21.8196 19.18C21.7896 19.26 21.7596 19.34 21.7196 19.42C21.5496 19.78 21.3296 20.12 21.0396 20.44C20.5496 20.98 20.0096 21.37 19.3996 21.62C19.3896 21.62 19.3796 21.63 19.3696 21.63C18.7796 21.87 18.1396 22 17.4496 22C16.4296 22 15.3396 21.76 14.1896 21.27C13.0396 20.78 11.8896 20.12 10.7496 19.29C10.3596 19 9.96961 18.71 9.59961 18.4L12.8696 15.13C13.1496 15.34 13.3996 15.5 13.6096 15.61C13.6596 15.63 13.7196 15.66 13.7896 15.69C13.8696 15.72 13.9496 15.73 14.0396 15.73C14.2096 15.73 14.3396 15.67 14.4496 15.56L15.2096 14.81C15.4596 14.56 15.6996 14.37 15.9296 14.25C16.1596 14.11 16.3896 14.04 16.6396 14.04C16.8296 14.04 17.0296 14.08 17.2496 14.17C17.4696 14.26 17.6996 14.39 17.9496 14.56L21.2596 16.91C21.5196 17.09 21.6996 17.3 21.8096 17.55C21.9096 17.8 21.9696 18.05 21.9696 18.33Z" fill="#fff" />
                                        </svg>
                                    </OverlayTrigger>
                                    :
                                    <a href={`tel: ${data?.Department_Telephone}`}>
                                        <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.05 14.95L9.2 16.8C8.81 17.19 8.19 17.19 7.79 16.81C7.68 16.7 7.57 16.6 7.46 16.49C6.43 15.45 5.5 14.36 4.67 13.22C3.85 12.08 3.19 10.94 2.71 9.81C2.24 8.67 2 7.58 2 6.54C2 5.86 2.12 5.21 2.36 4.61C2.6 4 2.98 3.44 3.51 2.94C4.15 2.31 4.85 2 5.59 2C5.87 2 6.15 2.06 6.4 2.18C6.66 2.3 6.89 2.48 7.07 2.74L9.39 6.01C9.57 6.26 9.7 6.49 9.79 6.71C9.88 6.92 9.93 7.13 9.93 7.32C9.93 7.56 9.86 7.8 9.72 8.03C9.59 8.26 9.4 8.5 9.16 8.74L8.4 9.53C8.29 9.64 8.24 9.77 8.24 9.93C8.24 10.01 8.25 10.08 8.27 10.16C8.3 10.24 8.33 10.3 8.35 10.36C8.53 10.69 8.84 11.12 9.28 11.64C9.73 12.16 10.21 12.69 10.73 13.22C10.83 13.32 10.94 13.42 11.04 13.52C11.44 13.91 11.45 14.55 11.05 14.95Z" fill="#fff" />
                                            <path d="M21.9696 18.33C21.9696 18.61 21.9196 18.9 21.8196 19.18C21.7896 19.26 21.7596 19.34 21.7196 19.42C21.5496 19.78 21.3296 20.12 21.0396 20.44C20.5496 20.98 20.0096 21.37 19.3996 21.62C19.3896 21.62 19.3796 21.63 19.3696 21.63C18.7796 21.87 18.1396 22 17.4496 22C16.4296 22 15.3396 21.76 14.1896 21.27C13.0396 20.78 11.8896 20.12 10.7496 19.29C10.3596 19 9.96961 18.71 9.59961 18.4L12.8696 15.13C13.1496 15.34 13.3996 15.5 13.6096 15.61C13.6596 15.63 13.7196 15.66 13.7896 15.69C13.8696 15.72 13.9496 15.73 14.0396 15.73C14.2096 15.73 14.3396 15.67 14.4496 15.56L15.2096 14.81C15.4596 14.56 15.6996 14.37 15.9296 14.25C16.1596 14.11 16.3896 14.04 16.6396 14.04C16.8296 14.04 17.0296 14.08 17.2496 14.17C17.4696 14.26 17.6996 14.39 17.9496 14.56L21.2596 16.91C21.5196 17.09 21.6996 17.3 21.8096 17.55C21.9096 17.8 21.9696 18.05 21.9696 18.33Z" fill="#fff" />
                                        </svg>
                                    </a>}
                            </li>
                        }
                    </ul>
                </Col>
            </Row>
        </>
    )
}

export default ContactUs
